import {connect} from "react-redux";
import {getFilterStats, setFilterInstance} from "../../actions/filter";
import FilterPageCarline from "../../components/Filter/FilterPageCarline";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {setVehicle} from "../../actions/vehicle";

function mapStateToProps(state: ApplicationState, ownProps) {
	const mapModelsIntoVehicle = (vehicle, models = []) => {
		const modelsForVehicle = models.filter(model => vehicle.key === model.parentKey);
		return ({
			...vehicle,
			categories: modelsForVehicle,
			shouldRenderMenu: modelsForVehicle.length > 1
		});
	};
	const {filter, filterStats} = state.filter;
	const vehicleModels = state.filter.vehicleModels;

	return {
		vehicleSeries: filterStats.vehicleSeries
			? filterStats.vehicleSeries.map(vehicle => {
				return (mapModelsIntoVehicle(vehicle, vehicleModels));
			})
			: null,

		filter: filter,
		isFiltered: filter.vehicle || filter.vehicleModel || filter.vehicleKey,
		isVehicleSelected: value => value !== null && (filter.vehicle === value || filter.vehicleModel === value || filter.vehicleKey === value),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setVehicle: vehicleKey => {
			dispatch(setVehicle(vehicleKey, null));
		},
		getCarlineFilterStats: filter => {
			dispatch(getFilterStats({...filter, ...{vehicle: null, vehicleModel: null}}));
		},
		setFilterInstance: (number, subCat) => {
			dispatch(setFilterInstance(number, subCat));
		}
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPageCarline));
