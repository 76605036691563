// @flow

import {useState, useEffect} from "react";

type usePositionType = {
	latitude: ?number,
	longitude: ?number,
	error: ?string
}

export const usePosition = (): usePositionType => {
	const [position, setPosition] = useState({});
	const [error, setError] = useState(null);

	const onChange = ({coords}) => {
		setPosition({
			latitude: coords.latitude,
			longitude: coords.longitude,
		});
	};

	const onError = (error: any) => {
		setError(error.message);
	};

	useEffect(() => {
		const geo = navigator.geolocation;
		const watcher = geo.watchPosition(onChange, onError);
		return () => geo.clearWatch(watcher);
	}, []);

	return {...position, error};
};
