// @flow
import React from "react";
import "./ShowCase.scss";
import {ShowCaseCategory} from "./ShowCaseCategory";
import type Category from "../../models/Category";
import type {translate} from "../../i18next";

type ShowCaseProps = {
	categories: Category[],
	t: translate
};

export const ShowCase = (props: ShowCaseProps) => {
	if (props.categories && props.categories.length) {
		const allCategoryHaveImages = props.categories.every(category => !!category.imageUrl);
		return <div className={allCategoryHaveImages ? "hpm-showCase-categories" : "hpm-showCase-categories-small"}>
			{props.categories.map((category: Category) => <ShowCaseCategory t={props.t} key={category.key}
				showImage={allCategoryHaveImages}
				category={category}/>)}
		</div>;
	}
	return null;
};
