// @flow

import React from "react";
import "./CarModelDropdown.scss";
import type FilterVehicleModel from "../../models/FilterVehicleModel";
import {translate, withTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";

export type CarModelDropdownItemProps = {
	vehicleModels: FilterVehicleModel[],
	vehicleSeries: string,
	setVehicle: (string, string) => void,
	t: translate,
	isVisible: boolean,
	toggleVehicleSelector: string => void,
	carRef: ?HTMLElement,
}

class CarModelDropdownItem extends React.Component<CarModelDropdownItemProps> {
	constructor() {
		super();
		this.elementRef = React.createRef();
	}

	outsideClickDetected = event => {
		if (this.elementRef && !this.elementRef.current.contains(event.target) &&
			this.props.carRef && !this.props.carRef.current.contains(event.target)) {
			this.props.toggleVehicleSelector("");
		}
	};

	alignCentered = () => {
		const thisDiv = document.getElementById("hpm-carModelDropdownId");
		thisDiv.style.transform = `translateX(${(210 - thisDiv.offsetWidth) / 2}px)`;
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.outsideClickDetected);
		this.alignCentered();
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.outsideClickDetected);
	}

	render() {
		return <div className={"hpm-carModelDropdown"} ref={this.elementRef}
			id={"hpm-carModelDropdownId"}>
			<span className={"hpm-boxStart"}/>

			<div className={"hpm-carModelItem"}
				 onClick={() => this.props.setVehicle(this.props.vehicleSeries, null)}>
				{this.props.t("navigation.all")}
			</div>

			{this.props.vehicleModels.map((model: FilterVehicleModel) =>
				<div className={"hpm-carModelItem"} key={uuidv4()}
					 onClick={() => this.props.setVehicle(null, model.key)}>
					{model.title}
				</div>)}

			<span className={"hpm-boxEnd"}/>
		</div>;
	}
}

export default withTranslation()(CarModelDropdownItem);
