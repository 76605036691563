// @flow
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {contrastColorCalculated} from "../reducers/configReducer";
import React from "react";

export const CONTRAST_COLOR_CALCULATED = "CONTRAST_COLOR_CALCULATED";
const ContrastColorUpdater = ({selector = ".colorPicker"}): string => {
    const lightColor = "#ffffff";
    const darkColor = "#000000";
    const [backgroundColor, setBackgroundColor] = useState();
    const calcContrastColor = (backgroundColor: string): string => {
        if (!backgroundColor) {
            return lightColor;
        }
        const rgb = backgroundColor.replace(/[^\d,.]/g, "").split(",");
        const r = rgb[0];
        const g = rgb[1];
        const b = rgb[2];
        const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
        const colorFlippingPoint = 126;

        return brightness > colorFlippingPoint
            ? darkColor
            : lightColor;
    };

    const dispatch = useDispatch();
    useEffect(() => {
        const publishNewContrastColor = () => {
            const node = document.querySelector(selector);
            if (node) {
                const computedStyles = window.getComputedStyle(node);
                if (computedStyles.backgroundColor !== backgroundColor) {
                    setBackgroundColor(computedStyles.backgroundColor);
                    dispatch(contrastColorCalculated(calcContrastColor(computedStyles.backgroundColor))
                    );
                }

            }
        };
        setTimeout(publishNewContrastColor, 100);
        setTimeout(publishNewContrastColor, 500);
        setTimeout(publishNewContrastColor, 1500);
    }, [dispatch, selector, backgroundColor, setBackgroundColor]);

    return <div/>;
};

export default ContrastColorUpdater;