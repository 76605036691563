// @flow

import type {Price} from "../models/Price";
import store from "../store";

export type HpmPrice = {
	value: number,
	cost: number,
	currency: string,
	currencyCode: string,
}
export const hpmPrice = (anyPrice: any = {}): HpmPrice => {
	if (anyPrice.cost === undefined && anyPrice.value === undefined) {
		return null;
	}
	return {
		value: anyPrice.value || anyPrice.cost || 0,
		cost: anyPrice.cost || anyPrice.value || 0,
		currency: (anyPrice.currency && anyPrice.currency.code) ? anyPrice.currency.code : (anyPrice.currency || anyPrice.unit || anyPrice.currencyCode || ""),
		currencyCode: (anyPrice.currency && anyPrice.currency.code) ? anyPrice.currency.code : (anyPrice.currency || anyPrice.unit || anyPrice.currencyCode || ""),
	};
};

const getLang = () => {
	return store.getState().config.dealerLanguage || "en";
};
const getTheme = () => {
	return store.getState().config.theme || "default";
};

const getShopEnable = () => {
	return store.getState().config.isShopEnabled;
};

const getFormat = (priceValue, theme) => {
	switch (theme) {
		case "noGrouping":
			return {
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
				useGrouping: false,
			};
		case "volvo":
		case "bentley":
			if (getShopEnable()) {
				return {
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				};
			}

			return {
				...{ maximumFractionDigits: 0,
					 minimumFractionDigits: 0, },
				...removeFractionDigitsForEdge(priceValue)
			};
		default:
			return {
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			};
	}
};

const removeFractionDigitsForEdge = priceValue => {
	const significantPriceDigits = Math.round(priceValue).toString().length;
	return {maximumSignificantDigits: significantPriceDigits};
};

const hasValidCurrency = (price: Price) => {
	return price != null && price.currency != null && price.currency.length === 3;
};

const addInvalidCurrencyByHand = (price: Price) => {
	return (hasValidCurrency(price) ? "" : ` ${price.currency}`);
};

export function currencyConverter(price: Price, theme: string = getTheme(), lang: string = getLang()) {
	return (new Intl.NumberFormat(lang, {
		...getFormat(price.value, theme),
		...{
			style: hasValidCurrency(price) ? "currency" : undefined,
			currency: hasValidCurrency(price) ? price.currency : undefined,
		}
	}).format(price.value)) + addInvalidCurrencyByHand(price);
};
