import de from "./de.json";
import cs from "./cs.json";
import da from "./da.json";
import el from "./el.json";
import es from "./es.json";
import fr from "./fr.json";
import hu from "./hu.json";
import it from "./it.json";
import nl from "./nl.json";
import no from "./no.json";
import pl from "./pl.json";
import ru from "./ru.json";
import en from "./en_gb.json";
import pt from "./pt.json";

const languages = {
	en,
	de,
	cs,
	da,
	el,
	es,
	fr,
	hu,
	it,
	nl,
	no,
	pl,
	ru,
	pt,
};

export default languages;
