// @flow

// Converts numeric degrees to radians
import type {GroupMember} from "../models/GroupMember";

function toRad(Value) {
	return Value * Math.PI / 180;
}

// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function calcCrow(lati1, long1, lati2, long2) {
	try {
		const R = 6371; // km
		const dLat = toRad(lati2 - lati1);
		const dLon = toRad(long2 - long1);
		const lat1 = toRad(lati1);
		const lat2 = toRad(lati2);

		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;
	} catch (e) {
		/* eslint-disable-next-line */
		console.log("Error on calculation distance.", e)
		return 0;
	}
}

export const checkLocation = (groupMembers: GroupMember[], location) => {
	if (groupMembers !== null) {
		return groupMembers.length > 0 ? location : null;
	}
	return null;
};
