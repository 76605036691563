import React from "react";
import MultiSelect from "./FilterTypes/MultiSelect";
import { v4 as uuidv4 } from "uuid";

export type VehicleModelFilterPageProps = {
	t: any,
	subCategory: {
		key: string,
		name: string,
		count: number,
		categories: []
	},
	setVehicleModel: string => void,
	setVehicleSeries: string => void,
	isModelSelected: string => boolean,
	isSeriesSelected: string => boolean,
};

const FilterPageVehicleModel = (props: VehicleModelFilterPageProps) => {
	return <React.Fragment>
		<MultiSelect
			infoText={props.subCategory.count} label={props.t("sort.all")}
			value={props.subCategory.key} key={uuidv4()}
			setFilter={props.setVehicleSeries} selected={props.isSeriesSelected(props.subCategory.key)}
		/>
		{props.subCategory && props.subCategory.categories.map(subCat => {
			return (
				<MultiSelect infoText={subCat.count} label={subCat.title}
							 value={subCat.key} key={uuidv4()}
							 setFilter={props.setVehicleModel} selected={props.isModelSelected(subCat.key)}
				/>);
		})}
	</React.Fragment>;
};

export default FilterPageVehicleModel;
