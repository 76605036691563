// @flow

import type {GroupMember} from "../models/GroupMember";
import LocationStorage from "../helper/storage/LocationStorage";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";

export type LocationReducerState = {
	dealerId: string,
	pickupLocation: ?GroupMember,
	locationChoices: ?GroupMember[],
};

const initialState: LocationReducerState = {
	dealerId: null,
	pickupLocation: null,
	locationChoices: []
};

const locationSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		pickupLocationSet(state, action) {
			const locationStorage = new LocationStorage(state.dealerId);
			locationStorage.set(JSON.stringify(action.payload));
			state.pickupLocation = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(configSet, (state, action) => {
				state.dealerId = action.payload.dealerId;
				state.pickupLocation = action.payload.pickupLocation;
				state.locationChoices = action.payload.locationChoices || [];
			});
	}
});

export const {pickupLocationSet} = locationSlice.actions;

export default locationSlice.reducer;
