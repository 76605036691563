// @flow
import React from "react";
import "./SharingIconBox.scss";
import {withTranslation} from "react-i18next";
import type {Product} from "../../../models/Product";

export type SharingIconBoxProps = {
	toggleSharingForm: () => void,
	trackShareButtonClick: Product => void,
	product: Product,
}

const SharingIconBox = (props: SharingIconBoxProps) => {
	const pageUrl = window.location.href.replace("#", "%23");
	const bookmarkUrl = pageUrl.substring(0, pageUrl.lastIndexOf("/"));

	return <span className="hpm-sharingIconBox">
		<span className="hpm-before"/>
		<a href={`https://www.facebook.com/sharer/sharer.php?u=${bookmarkUrl}`}
		   onClick={() => props.trackShareButtonClick(props.product)}
		   title="Facebook" className="first" target="_blank" rel="noopener noreferrer">
			Facebook
		</a>
		{/* TODO: Remove "false" to include sharing */
			false && <a title="E-Mail" onClick={() => props.toggleSharingForm()}>E-Mail</a>}
		<a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(props.product.name + ":\n")}${bookmarkUrl}`}
		   onClick={() => props.trackShareButtonClick(props.product)}
		   title="Twitter" target="_blank" rel="noopener noreferrer">
			Twitter
		</a>
		<a href={`https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(props.product.name)}&url=${bookmarkUrl}`}
		   onClick={() => props.trackShareButtonClick(props.product)}
		   title="LinkedIn" target="_blank" rel="noopener noreferrer" className="last">
			LinkedIn
		</a>
		<span className="hpm-after"/>
	</span>;
};

export default withTranslation()(SharingIconBox);
