// @flow
import type {FilterReducerState} from "./filterReducer";
import filterReducer from "./filterReducer";
import type {ConfigReducerState} from "./configReducer";
import configReducer from "./configReducer";
import {reducer as formReducer} from "redux-form";
import type {BookmarksReducerState} from "./bookmarksReducer";
import bookmarksReducer from "./bookmarksReducer";
import type {OrderReducerState} from "./orderReducer";
import orderReducer from "./orderReducer";
import type {CartReducerState} from "./cartReducer";
import cartReducer from "./cartReducer";
import type {ContentReducerState} from "./contentReducer";
import contentReducer from "./contentReducer";
import type {MailingReducerState} from "./mailingReducer";
import mailingReducer from "./mailingReducer";
import type {ConsentReducerState} from "./consentReducer";
import consentReducer from "./consentReducer";
import type {LocationReducerState} from "./locationReducer";
import locationReducer from "./locationReducer";
import type {CouponReducerState} from "./couponReducer";
import couponReducer from "./couponReducer";

export type ApplicationState = {
	bookmarks: BookmarksReducerState,
	mailing: MailingReducerState,
	cart: CartReducerState,
	config: ConfigReducerState,
	consent: ConsentReducerState,
	content: ContentReducerState,
	location: LocationReducerState,
	filter: FilterReducerState,
	form: any,
	order: OrderReducerState,
	coupon: CouponReducerState,
}

export const rootReducer: ApplicationState = {
	bookmarks: bookmarksReducer,
	mailing: mailingReducer,
	cart: cartReducer,
	config: configReducer,
	content: contentReducer,
	consent: consentReducer,
	location: locationReducer,
	filter: filterReducer,
	form: formReducer,
	order: orderReducer,
	coupon: couponReducer,
};

