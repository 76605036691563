import OrderSummary from "../../../components/checkout/mainContentBox/OrderSummary";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import {SHIPPING_TYPES} from "../../../models/ShippingTypes";
import {acceptOrder} from "../../../actions/order";
import Configuration from "../../../Configuration";
import {PAYMENT_METHODS} from "../../../models/PaymentRequests";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	const items = state.cart.items;
	const {SHIPPING, MOUNT, PICKUP} = SHIPPING_TYPES;
	const withShipping = items.some(item => item.shippingMethod === SHIPPING);
	const withMounting = items.some(item => item.shippingMethod === MOUNT);
	const withPickup = items.some(item => item.shippingMethod === PICKUP);
	let shippingText;

	if (withShipping) {
		shippingText = ownProps.t("cart.expectedDeliveryDate.expectedDeliveryDateDefault",
			{
				min: Math.max(...state.cart.items.map(item => item.shippingMethod === "SHIPPING" ? item.product.distributionChannels.shipping.minSupplyDays : 0)),
				max: Math.max(...state.cart.items.map(item => item.shippingMethod === "SHIPPING" ? item.product.distributionChannels.shipping.maxSupplyDays : 0))
			}
		);
	} else {
		shippingText = ownProps.t("cart.shipping.pickup");
	}

	return {
		items: state.cart.items,
		adress: state.order.adress,
		shippingText,
		withPickup,
		shipping: Math.max(...state.cart.items.map(item => item.shippingCost)) || 0,
		t: ownProps.t,
		showPickupLocation: state.location.pickupLocation && (withMounting || withPickup),
		legalTerms: state.config.legalTerms,
		dealerId: state.config.dealerId,
		notificationUrl: Configuration.value("apiNotificationUrl"),
		paymentFacility: state.cart.paymentFacility || {},
		renderP24Button: state.cart.paymentFacility && state.cart.paymentFacility.paymentMethod === PAYMENT_METHODS.PRZELEWY24,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitOrder: () => {
			dispatch(acceptOrder());
		}
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderSummary));
