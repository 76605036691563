import {compose} from "redux";
import {connect} from "react-redux";
import {setBrandInstance} from "../../actions/brand";
import BrandInstancePage from "../../components/gridPages/BrandInstancePage";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	const brandInstances = state.config.brandInstances;
	const autoselectedInstance = brandInstances.length === 1
		? brandInstances[0]
		: ownProps.match.params.manufacturerName
			? brandInstances.find(instance => instance.manufacturerName === ownProps.match.params.manufacturerName)
			: brandInstances.find(instance => instance.manufacturerName === state.config.defaultBrand);

	return {
		autoselectedInstance: autoselectedInstance,
		brandInstances: state.config.brandInstances,
		shouldRender: (state.config.garageId || state.config.garageGroupId) && state.config.brandInstances.length > 0 && !autoselectedInstance,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		disableConfig: () => {
		},
		selectBrand: brand => {
			dispatch(setBrandInstance(brand));
			const {history} = ownProps;
			if (!ownProps.location.pathname.includes(`/brand/${brand.manufacturerName}`)) {
				history.push(`/brand/${brand.manufacturerName}/shop`);
			}
		}
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(BrandInstancePage);
