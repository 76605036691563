import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getProducts} from "../../actions/products";
import {resetCategory, setCategory} from "../../actions/category";
import {resetSearch} from "../../actions/search";
import Products from "../../components/gridPages/Products";
import {resetVehicle, setVehicle} from "../../actions/vehicle";
import type {ApplicationState} from "../../reducers";
import {resetMarker, setMarker} from "../../actions/marker";
import {productsReset} from "../../reducers/contentReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	const hasSalesCategory = (ownProps.match.params.category1 || ownProps.match.params.category2) === "SALE" ||
		(ownProps.match.params.category1 || ownProps.match.params.category2) === "_SALE";

	let category = decodeURIComponent(ownProps.match.params.category1 || ownProps.match.params.category2);
	let categoryLabel = state.filter.filter.selectedCategory ? state.filter.filter.selectedCategory : "";

	if (hasSalesCategory) {
		category = ownProps.t("category.sale");
		categoryLabel = ownProps.t("sort.sale");
	}

	return {
		config: state.config,
		products: state.content.products,
		totalItems: state.content.totalItems,
		currentPage: state.content.currentPage,
		totalPages: state.content.totalPages,
		categoryLabel: categoryLabel,
		category: category,
		isLoading: state.content.isLoading,
		hasMore: state.content.products.length < state.content.totalItems || (!state.content.products.length && state.content.totalItems),
		selectedVehicle: state.filter.filter.vehicle,
		markers: hasSalesCategory ? ["SALE"] : []
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		dispatchGetNextProducts: loading => {
			if (!loading) {
				dispatch(getProducts());
			}
		},
		toStartPage: () => {
			dispatch(resetCategory());
			dispatch(resetSearch());
			dispatch(productsReset());
			const {history} = ownProps;
			history.pushHome();
		},
		setCategory: category => {
			dispatch(setCategory(category));
			dispatch(resetSearch());
			dispatch(productsReset());
		},
		setVehicle: (vehicleSeries: string, vehicleModel: string) => {
			dispatch(setVehicle(vehicleSeries, vehicleModel));
			dispatch(productsReset());
			dispatch(getProducts());
		},
		resetVehicle: () => {
			dispatch(resetVehicle());
			dispatch(productsReset());
			dispatch(getProducts());
		},
		setMarker: marker => {
			dispatch(setMarker(marker));
		},
		resetMarker: () => {
			dispatch(resetMarker());
		}
	};
}

function mergeProps(propsFromState, propsFromDispatch, ownProps) {
	return {
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		getNextProducts: () => propsFromDispatch.dispatchGetNextProducts(propsFromState.isLoading)
	};
};

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Products);
