import {toast} from "react-toastify";
import i18n from "../i18next";
import {bookmarkAdded, bookmarkConfigReset, bookmarkRemoved, welcomeMessageReset} from "../reducers/bookmarksReducer";

export const addBookmark = product => {
	toast(i18n.t("productInteractions.addBookmark"));
	return bookmarkAdded(product);
};

export const removeBookmark = product => {
	toast(i18n.t("productInteractions.removeBookmark"));
	return bookmarkRemoved(product);
};

export const resetBookmarkConfig = () => {
	return bookmarkConfigReset();
};

export const resetWelcomeMessage = () => {
	return welcomeMessageReset();
};
