import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CarSelectionBar from "../../components/carSelector/CarSelectionBar";
import type {ApplicationState} from "../../reducers";
import {createRef} from "react";

function mapStateToProps(state: ApplicationState, ownProps) {
	const brand = state.content.brand;
	const allVehicleSeries = state.filter.filterStats.vehicleSeries;
	const filterVehicleSeries = allVehicleSeries.filter(vehicleSeries => vehicleSeries.imageUrl !== null) || [];
	const filterObject = state.filter.filter;
	const getSelectedVehicle = () => {
		let selectedVehicle;
		for (let series of allVehicleSeries) {
			if (series.key === filterObject.vehicle || series.key === filterObject.vehicleModel || series.key === filterObject.vehicleKey) {
				selectedVehicle = series;
				return selectedVehicle;
			}
			for (let category of series.categories) {
				if (category.key === filterObject.vehicle || category.key === filterObject.vehicleModel || category.key === filterObject.vehicleKey) {
					selectedVehicle = category;
					return selectedVehicle;
				}
			}
		}
		return undefined;
	};
	const selectedVehicle = getSelectedVehicle();

	const filteredVehicleSeries = filterVehicleSeries.map(vehicle => {
		return {
			...vehicle,
			carRef: createRef(),
		};
	});

	return {
		filteredVehicleSeries,
		brand,
		selectedVehicle,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CarSelectionBar));
