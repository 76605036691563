import {connect} from "react-redux";
import Range from "../../../components/Filter/FilterTypes/Range";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {

	};
}

export default withTranslation()(connect(mapStateToProps, null)(Range));
