import {connect} from "react-redux";
import type {ApplicationState} from "../../reducers";
import {NscDealerSearch} from "../../../components/productDetail/NSC/NscDealerSearch";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		availableOnOtherDealersCount: state.content.availableOnOtherDealersCount,
		showDealerList: ownProps.showDealerList,
		setDealerList: ownProps.setDealerList,
		product: state.content.product,
		showPrices: !state.config.productPricesDisabled,
		disclaimer: state.config.disclaimer,
		brand: state.content.brand.name,
	};
}

export default withTranslation()(connect(mapStateToProps, null)(NscDealerSearch));
