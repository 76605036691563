// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const ChryslerTheme = props => {
	import("./chrysler.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default ChryslerTheme;
