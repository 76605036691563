import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import OrderSummaryCartItem from "../../../components/checkout/mainContentBox/OrderSummaryCartItem";
import type {ApplicationState} from "../../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		t: ownProps.t
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderSummaryCartItem)));
