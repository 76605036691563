// @flow

import RootNodeProvider from "./RootNodeProvider";
import {loadConfig} from "../actions/config";
import {trackers} from "./tracker";
import {consentGoogleAnalyticsSet, consentGoogleMapsSet} from "../reducers/consentReducer";

export const CONSENT = {
	GOOGLE_ANALYTICS: {
		SET: "CONSENT_GOOGLE_ANALYTICS_SET",
		REVOKE: "CONSENT_GOOGLE_ANALYTICS_REVOKE",
		REQUEST: "CONSENT_GOOGLE_ANALYTICS_REQUEST",
	},
	GOOGLE_MAPS: {
		SET: "CONSENT_GOOGLE_MAPS_SET",
		REVOKE: "CONSENT_GOOGLE_MAPS_REVOKE",
		REQUEST: "CONSENT_GOOGLE_MAPS_REQUEST",
	}
};

class EventHandler {
	store;
	targetNode;

	constructor(store) {
		this.store = store;
		this.targetNode = RootNodeProvider.getRootNode();
		if (!this.targetNode) return;
		this.targetNode.addEventListener(CONSENT.GOOGLE_ANALYTICS.SET, () => {
			if (this.store.getState().consent.googleAnalytics === false) {
				this.store.dispatch(consentGoogleAnalyticsSet(true));
				trackers.length = 0;
				this.store.dispatch(loadConfig(this.store.getState().config.dealerId, this.store.getState().config.role));
			}
		});
		this.targetNode.addEventListener(CONSENT.GOOGLE_ANALYTICS.REVOKE, () => {
			if (this.store.getState().consent.googleAnalytics === true) {
				this.store.dispatch(consentGoogleAnalyticsSet(false));
				trackers.length = 0;
				this.store.dispatch(loadConfig(this.store.getState().config.dealerId, this.store.getState().config.role));
			}
		});
		this.targetNode.addEventListener(CONSENT.GOOGLE_MAPS.SET, () => store.dispatch(consentGoogleMapsSet(true)));
		this.targetNode.addEventListener(CONSENT.GOOGLE_MAPS.REVOKE, () => store.dispatch(consentGoogleMapsSet(false)));
	}

	requestGoogleAnalyticsConsent = () => {
		const event = new CustomEvent(CONSENT.GOOGLE_ANALYTICS.REQUEST);
		const rootNode = RootNodeProvider.getRootNode();
		if (rootNode) rootNode.dispatchEvent(event);
	};

	requestGoogleMapsConsent = () => {
		const event = new CustomEvent(CONSENT.GOOGLE_MAPS.REQUEST);
		const rootNode = RootNodeProvider.getRootNode();
		if (rootNode) rootNode.dispatchEvent(event);
	};
}

export default EventHandler;
