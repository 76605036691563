// @flow

import Configuration from "../Configuration";

class RedirectService {
	baseUrl = Configuration.value("redirectServiceUrl");

	enableDealer = (dealerId: string) => {
		const url = new URL(window.location.href);
		url.searchParams.append("dealer", dealerId);

		window.location.href = url;
	};

	enableUrl = (baseUrl: string = "", dealerId: ?string = null, brand: ?string = "") => {
		const url = new URL(window.location.href);
		let target = "";
		baseUrl = baseUrl + url.search + url.hash;
		if (!baseUrl.includes("http")) {
			baseUrl = "https://" + baseUrl;
		}
		if (!url.hash && brand) {
			baseUrl = baseUrl + `#/brand/${brand}/shop`;
		}
		try {
			target = new URL(baseUrl);
			window.location.href = target;
		} catch (e) {
			// baseUrl not valid
			this.enableDealer(dealerId);
		}
	};
}

export default RedirectService;
