// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {
	CartValidationRequest,
	Order,
	OrderItemsResponse
} from "../models/Order";

export type ConfirmOrderRequest = {
		orderNo: {
			index: string,
		},
};

export type PlaceOrderResponse = {
	orderNo: {
		index: string,
	},
};

export default class OrderProcessingApi {
	api: Axios;

	constructor() {
		this.api = axios.create({
			baseURL: Configuration.value("apiOrderUrl"),
			headers: {
				"ContentType": "application/json",
				"X-ModuleType": "HPM"
			},
		});
	}

	createOrder = async (order: Order): Promise<OrderItemsResponse> => {
		return this.api.post("/order", order)
			.then((response: {data: PlaceOrderResponse}) => {
				return this.getOrder(response.data.orderNo.index);
			});
	};

	getOrder = async (orderNo: string): Promise<OrderItemsResponse> => {
		return this.api.get(`order/items/${orderNo}`)
			.then(response => response.data);
	};

	calcOrder = async (body: CartValidationRequest) => {
		return this.api.post("order/calcOrder", body)
			.then(response => response.data);
	};

	getOrderState = async (orderNo: string) => {
		return this.api.get(`order/poll/authorization/${orderNo}`)
			.then(response => response.data);
	};

	acceptOrder = async (orderNo: string): Promise<PlaceOrderResponse> => {
		const body: ConfirmOrderRequest = {
			orderNo: orderNo,
		};
		return this.api
			.patch("order/accept", body)
			.then(rep => rep.data);
	};
}
