// @flow

import * as React from "react";
import "./DealerList.scss";
import {RoleTypes} from "../../models/Role";
import {GarageDetail} from "./GarageDetail";
import {withTranslation} from "react-i18next";

type Props = {
	t: string => string,
	theme: string,
	dealerNo: string,
	articleNumbers: string[],
};

const DealerList = (props: Props) => {
	return <div className={"hpm-dealerList"}>
		<div>
			<GarageDetail
				theme={props.theme} role={RoleTypes.DISTRIBUTOR}
				openDealerShopsInNewTab={true} articleNumbers={props.articleNumbers}
				t={props.t} dealerId={props.dealerNo}
			/>
		</div>
	</div>;
};

export default withTranslation()(DealerList);
