/* eslint-disable no-throw-literal,no-mixed-spaces-and-tabs */
// @flow

import type {Tracking} from "../../models/Tracking";
import type {Product} from "../../models/Product";
import type {OrderReducerState} from "../../reducers/orderReducer";
import type {CouponReducerState} from "../../reducers/couponReducer";

export default class BaseTracker implements Tracking {
    useEcommerce: boolean;
    trackingId: string;
    eventPrefix: string;

    constructor(trackingId: string, withEcommerce: boolean, eventPrefix = "SL_") {
    	this.useEcommerce = withEcommerce;
    	this.trackingId = trackingId;
    	this.eventPrefix = eventPrefix;
    }

    setEcommerce = value => {
    	this.useEcommerce = value;
    };

    handleGenericEvent(cat: string, action: string, label: any): void {
    	throw ("not implemented");
    };

    handleOrderEvent(order: OrderReducerState, coupon: ?CouponReducerState): void {
    	throw ("not implemented");
    };

    handleAddItemEvent(cat: string, action: string, product: Product): void {
    	throw ("not implemented");
    };

    handleRemoveItemEvent(cat: string, action: string, product: Product): void {
    	throw ("not implemented");
    };

    handleClearCartEvent(): void {
    	throw ("not implemented");
    };

    handleViewProductDetailsEvent(event: {}, product: Product): void {
    	throw ("not implemented");
    };

    handleInsertToWatchlistEvent(event: {}, product: Product): void{
    	throw ("not implemented");
    };

    handleRemoveFromWatchlistEvent(event: {}, product: Product): void{
    	throw ("not implemented");
    };

    handleViewItemEvent(event: {}, product: Product): void {
        throw ("not implemented");
    }

    logEvent(cat: string, action: string, label: any): void {
    	this.handleGenericEvent(this.eventPrefix + cat, action, label);
    };

    logOrder(order: OrderReducerState, coupon: ?CouponReducerState): void {
    	this.handleOrderEvent(order, coupon);
    };

    addItem(cat: string, action: string, product: Product): void {
    	this.handleAddItemEvent(this.eventPrefix + cat, action, product);
    };

    removeItem(cat: string, action: string, product: Product): void {
    	this.handleRemoveItemEvent(this.eventPrefix + cat, action, product);
    }

    clearCart(): void {
    	this.handleClearCartEvent();
    }

    showProductDetails(event, product: Product) {
    	this.handleViewProductDetailsEvent(event, product);
        this.handleViewItemEvent(event, product);
    };

    insertToWatchlist(event, product: Product) {
    	this.handleInsertToWatchlistEvent(event, product);
    };

    removeFromWatchlist(event, product: Product) {
    	this.handleRemoveFromWatchlistEvent(event, product);
    };
}
