// @flow

export type Country = {
	countryName: string,
	countryCode: string
}

export const defaultCountry = {
	countryName: "",
	countryCode: ""
};
