// @flow

import BaseStorage from "./BaseStorage";
import type Memorizing from "../../models/Memorizing";

const HPM_BRANDINSTANCE: string = "-hpmbrandinstance";

export default class BrandInstanceStorage extends BaseStorage implements Memorizing<string, ?string> {
	constructor(dealerId: string) {
		super(dealerId, HPM_BRANDINSTANCE, window.sessionStorage);
	}
}
