// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const ToyotaTheme = props => {
	import("./toyota.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default ToyotaTheme;
