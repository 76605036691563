// @flow

import BaseStorage from "./BaseStorage";
import type Memorizing from "../../models/Memorizing";

const HPM_COUPON: string = "-hpmcoupon";

export default class CouponStorage extends BaseStorage implements Memorizing<string, ?string> {
	constructor(dealerId: string) {
		super(dealerId, HPM_COUPON);
	}
}
