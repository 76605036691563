// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const AudiTheme = props => {
	import("./audi.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default AudiTheme;
