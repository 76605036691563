// @flow

import EventEmitter from "./eventEmitter";
import {LANGUAGE_NOT_SUPPORTED_BY_CATALOG} from "../preview/event/EventHandler";

export const selectLanguage = (preferredLanguage: string = navigator.language || "en", allowedLanguages: string[] = []) => {
	const allowed: string[] = (allowedLanguages).map(lang => lang.trim().substring(0, 2).toLowerCase());
	const preferred: string = (preferredLanguage).trim().substr(0, 2).toLowerCase();
	const browser: string = (navigator.language || "en").trim().substr(0, 2).toLowerCase();

	if (allowed.includes(preferred)) {
		return preferred;
	}

	const catalogLanguage = allowed.includes(browser)
		? browser
		: allowed.length > 0 ? allowed[0] : null;
	if (catalogLanguage) {
		new EventEmitter().emitEvent(LANGUAGE_NOT_SUPPORTED_BY_CATALOG, {allowedLanguage: catalogLanguage});
		return catalogLanguage;
	}

	return "en";
};


export const convertToLocale = (lang: string = "en", country: string = null) => {
	const isoLang = lang ? lang.trim().substring(0, 2).toLocaleLowerCase() : (navigator.language || "en");
	const isoCountry = country ? country.trim().substring(0, 2).toUpperCase() : (languageToCountryMapping[isoLang] || "US");

	return `${isoLang}_${isoCountry}`;
};

export const languageToCountryMapping = {
	en: "US", // English with the US as the default country.
	es: "ES", // Spanish with Spain as the default country.
	zh: "CN", // Chinese with China as the default country.
	ar: "SA", // Arabic with Saudi Arabia as the default country.
	pt: "BR", // Portuguese with Brazil as the default country.
	fr: "FR", // French with France as the default country.
	de: "DE", // German with Germany as the default country.
	ja: "JP", // Japanese with Japan as the default country.
	ru: "RU", // Russian with Russia as the default country.
	it: "IT", // Italian with Italy as the default country.
	tr: "TR", // Turkish with Turkey as the default country.
	pl: "PL", // Polish with Poland as the default country.
	nl: "NL", // Dutch with Netherlands as the default country.
	vi: "VN", // Vietnamese with Vietnam as the default country.
	ko: "KR", // Korean with South Korea as the default country.
	sv: "SE", // Swedish with Sweden as the default country.
	da: "DK", // Danish with Denmark as the default country.
	fi: "FI", // Finnish with Finland as the default country.
	no: "NO", // Norwegian with Norway as the default country.
	el: "GR", // Greek with Greece as the default country.
	th: "TH", // Thai with Thailand as the default country.
	id: "ID", // Indonesian with Indonesia as the default country.
	ms: "MY", // Malay with Malaysia as the default country.
	hu: "HU", // Hungarian with Hungary as the default country.
	cs: "CZ", // Czech with Czech Republic as the default country.
	ro: "RO", // Romanian with Romania as the default country.
	he: "IL", // Hebrew with Israel as the default country.
	nb: "NO", // Norwegian Bokmål with Norway as the default country.
	sl: "SI", // Slovenian with Slovenia as the default country.
	bg: "BG", // Bulgarian with Bulgaria as the default country.
	sk: "SK", // Slovak with Slovakia as the default country.
	hr: "HR", // Croatian with Croatia as the default country.
	uk: "UA", // Ukrainian with Ukraine as the default country.
	ca: "ES", // Catalan with Spain as the default country.
	hi: "IN", // Hindi with India as the default country.
	fil: "PH", // Filipino with the Philippines as the default country.
	lv: "LV", // Latvian with Latvia as the default country.
	lt: "LT", // Lithuanian with Lithuania as the default country.
	et: "EE", // Estonian with Estonia as the default country.
	sr: "RS", // Serbian with Serbia as the default country.
	is: "IS", // Icelandic with Iceland as the default country.
	fa: "IR", // Persian with Iran as the default country.
};