// @flow

import BaseStorage from "./BaseStorage";
import type Memorizing from "../../models/Memorizing";

const HPM_LOCATION_STORAGE: string = "-hpmlocation";

export default class LocationStorage extends BaseStorage implements Memorizing<string, ?string> {
	constructor(dealerId: string) {
		super(dealerId, HPM_LOCATION_STORAGE);
	}
}
