// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const VolkswagenTheme = props => {
	import("./volkswagen.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default VolkswagenTheme;
