import {connect} from "react-redux";
import {getFilterStats, setFilter, setFilterInstance} from "../../actions/filter";
import FilterPageMainCategory from "../../components/Filter/FilterPageMainCategory";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	const addRenderInfoToCategories = (categories = []) => categories.map(category => {
		return ({...category, ...{shouldRenderMenu: category.categories.length > 1}});
	});
	return {
		categories: addRenderInfoToCategories(state.filter.filterStats.categories),
		filter: state.filter.filter,

		isFiltered: !!state.filter.filter.categories.length,
		isCategorySelected: category => state.filter.filter.categories.indexOf(category) !== -1,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		resetCategory: () => {
			dispatch(setFilter("categories", []));
		},
		setFilterInstance: (instance, subCat) => {
			dispatch(setFilterInstance(instance, subCat));
		},
		getCategoryFilterStats: filter => {
			dispatch(getFilterStats({...filter, ...{categories: []}}));
		},
		setCategory: category => {
			dispatch(setFilter("categories", [category]));
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPageMainCategory));
