// @flow
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {PickupLocation} from "../../components/pickupLocation/PickupLocation";
import {setPickupLocation} from "../../actions/location";
import type {GroupMember} from "../../models/GroupMember";
import type {ApplicationState} from "../../reducers";
import {getProduct} from "../../actions/products";
import {makeCartEmpty} from "../../actions/cart";
import history from "../../history";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		isVisible: state.config.garageGroupId && state.location.locationChoices.length > 0,
		location: state.location.pickupLocation,
		locationChoices: state.location.locationChoices,
		product: state.content.product || {},
		cartHasProducts: state.cart.items.length > 0,
		fallbackPictureURL: state.content.brand.brandImageUri,
		defaultLocation: state.config.defaultLocation,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setLocationAndReload: (location: GroupMember, productId: ?string) => {
			dispatch(setPickupLocation(location));
			dispatch(makeCartEmpty());
			if (productId) dispatch(getProduct(productId));
			else history.go(0);
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PickupLocation));
