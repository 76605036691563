// @flow
import * as React from "react";
import {Trans} from "react-i18next";
import {addQueryParam} from "../../../helper/urlUtils";

export type Address = {
	street: string,
	postalCode: string,
	streetNumber: string,
	town: string,
}

export type DealerConfiguration = {
	leadModuleEnabled: boolean,
	productPricesDisabled: boolean,
	shopEnabled: boolean,
}

export type DealerType = {
	name: string,
	identifier: {
		dealerNo: string,
		marketplace: any,
	},
	dealerConfiguration: DealerConfiguration,
	distance: string,
	address: Address,
	productUrl: string,
	shopBaseUrl: string,
}

type Props = {
	dealer: DealerType,
	openDealerShopsInNewTab: boolean
};

export const Dealer = (props: Props) => {
	const renderDealerAddress = () => {
		const {address} = props.dealer;
		return address
			? <React.Fragment>
				{address.street + " " + address.streetNumber} <br/>
				{address.postalCode + " " + address.town} <br/>
				{<small>{props.dealer.distance}</small>}
			</React.Fragment>
			: <React.Fragment>&nbsp;</React.Fragment>;
	};

	const dealerIcon = props.dealer.dealerConfiguration.shopEnabled
		? "dealerIcon shop"
		: props.dealer.dealerConfiguration.productPricesDisabled
			? "dealerIcon location"
			: "dealerIcon euro";

	return <div className={"dealer"} key={props.dealer.identifier.dealerNo}>
		<span>{props.dealer.name}</span>
		<span>{renderDealerAddress()}</span>
		<span className={dealerIcon}/>
		<a href={addQueryParam(props.dealer.productUrl, "utm_medium", "central")}
			target={props.openDealerShopsInNewTab ? "_blank" : "_self"} rel="noopener noreferrer"
		>
			<Trans i18nKey="nsc.visitGarage"/>
		</a>
	</div>;
};
