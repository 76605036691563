// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const MercedesTheme = props => {
	import("./mercedes.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default MercedesTheme;
