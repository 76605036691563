// @flow
import React, {useEffect} from "react";
import type {Captcha} from "../models/Captcha";
import "./SlCaptcha.scss";
import NotificationApi from "../api/NotificationApi";

export type SlCaptchaProps = {
	setCaptcha: Captcha => void,
	captcha: Captcha,
	error: boolean,
};

const SlCaptcha = ({captcha, error, setCaptcha}: SlCaptchaProps) => {
	useEffect(() => {
		const api = new NotificationApi();
		api.getCaptcha().then(it => setCaptcha(it));
	}, [error, setCaptcha]);

	return captcha
		? <div className={"slCaptcha"}>
			<div className={"captchaInput"}>
				<input type={"text"} placeholder={"Captcha"} required={true}
					   value={captcha.captchaSolution.replace(" ", "").toLowerCase()}
					   onChange={e => setCaptcha({...(captcha), captchaSolution: e.target.value})}/>
			</div>
			<div className={"captchaImage"}>
				<img src={captcha.base64} alt={"Captcha"}/>
			</div>
		</div>
		: <div/>;
};

export default SlCaptcha;
