import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import type { ApplicationState } from "../../reducers";
import GridElement from "../../components/gridPages/GridElement";

function mapStateToProps(state: ApplicationState, ownProps) {
	const needsPickupLocation = state.config.garageGroupId && !state.location.pickupLocation && !state.config.productPricesDisabled;
	return {
		showPrice: !state.config.productPricesDisabled,
		needsPickupLocation,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {

	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(GridElement);
