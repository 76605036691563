// @flow

import React from "react";
import "./CarElement.scss";
import type FilterVehicleModel from "../../models/FilterVehicleModel";

export type CarElementProps = {
	imgSrc: string,
	name: string,
	title: string,
	id: string,
	setVehicle: (string, string) => void,
	selected: boolean,
	vehicleModels: FilterVehicleModel[],
	toggleVehicleSelector: string => void,
	visibleVehicleModel: string,
	carRef: ?HTMLElement,
	allowsClick: boolean,
}

const CarElement = (props: CarElementProps) => {
	const handleCarClick = () => {
		if (!props.allowsClick) return;
		props.vehicleModels.length > 1
			? props.toggleVehicleSelector(props.visibleVehicleModel !== props.name ? props.name : "")
			: props.setVehicle(null, props.vehicleModels[0].name);
	};
	return (
		<div className={"hpm-carElement"} onClick={handleCarClick} ref={props.carRef}>
			<img alt="" src={props.imgSrc}/>
			<div className={"hpm-carSeries"}>{props.title}</div>
		</div>
	);
};

export default CarElement;
