// @flow
import React, {Component} from "react";
import FilterContainer from "../../container/Filter/FilterContainer";
import Header from "./Header";
import FilterModalContainer from "../../container/Filter/FilterModalContainer";
import type {translate} from "../../i18next";
import {Route} from "react-router-dom";
import CategoryContainer from "../../container/Filter/CategoryContainer";
import CategoryModalContainer from "../../container/Filter/CategoryModalContainer";

export type NavigationProps = {
	activeModal: number,
	changeModal: ?number => void,
	searchProducts: () => void,
	openCart: () => void,
	handleBack: () => void,
	handleHome: () => void,
	openBookmarks: () => void,
	t: translate,
	showBack: () => void,
	items: number,
	bookmarksCount: number,
	isShopEnabled: boolean,
	watchlistEnabled: boolean,
	hideNavigation: boolean,
	shouldRenderHomeButton: boolean,
	shouldRenderHistoryBack: boolean,
	showResetBrandButton: boolean,
	resetBrand: () => void,
}

export const MODAL_TYPES = {
	FILTER: 1,
};

class Navigation extends Component<NavigationProps> {
	constructor(props: NavigationProps) {
		super(props);
		this.toggleFilterModal = this.toggleFilterModal.bind(this);
	}

	toggleFilterModal() {
		const {FILTER} = MODAL_TYPES;
		if (this.props.activeModal === FILTER) {
			return this.props.changeModal(null);
		}
		return this.props.changeModal(FILTER);
	}

	renderFilterModal = () => {
		const {FILTER} = MODAL_TYPES;
		return <FilterModalContainer
			id={FILTER}
			closeText={this.props.t("navigation.close")}>
			<FilterContainer/>
		</FilterModalContainer>;
	};

	renderCategoryModal = () => {
		const {FILTER} = MODAL_TYPES;
		return <CategoryModalContainer
			id={FILTER}
			closeText={this.props.t("navigation.close")}>
			<CategoryContainer/>
		</CategoryModalContainer>;
	};

	render() {
		if (this.props.hideNavigation) return null;
		const renderFilter = () => {
			return <React.Fragment>
				<Route
					path={["/products/:category?", "/brand/:manufacturerName/products/:category?"]}
					component={this.renderFilterModal}/>
				<Route
					path={["/brand/:manufacturerName/search/:fuzzy1?", "/search/:fuzzy2?"]}
					component={this.renderFilterModal}/>
				<Route
					path={["/brand/:manufacturerName/shop", "/shop"]}
					component={this.renderCategoryModal}/>
				<Route
					exact path={["/", "/brand/:manufacturerName"]}
					component={this.renderCategoryModal}/>
			</React.Fragment>;
		};
		return (
			<div className="hpm-navigation hpm-noPrint">
				<Header
					handleBack={this.props.handleBack}
					handleHome={this.props.handleHome}
					isShopEnabled={this.props.isShopEnabled}
					watchlistEnabled={this.props.watchlistEnabled}
					bookmarksCount={this.props.bookmarksCount || 0}
					cartElements={this.props.items || 0}
					showBack={this.props.showBack}
					searchPlaceholder={this.props.t("navigation.search")}
					searchProducts={this.props.searchProducts}
					openFilterModal={this.toggleFilterModal}
					openCart={this.props.openCart}
					openBookmarks={this.props.openBookmarks}
					shouldRenderHomeButton={this.props.shouldRenderHomeButton}
					shouldRenderHistoryBack={this.props.shouldRenderHistoryBack}
					showResetBrandButton={this.props.showResetBrandButton}
					resetBrand={this.props.resetBrand}
				/>
				{renderFilter()}
			</div>
		);
	}
}

export default Navigation;
