// @flow
import {markerReset, markerSet} from "../reducers/filterReducer";

export const setMarker = (marker = []) => {
	return dispatch => {
		dispatch(markerSet(marker));
	};
};

export const resetMarker = () => {
	return dispatch => {
		dispatch(markerReset());
	};
};
