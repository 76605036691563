import RootNodeProvider from "./RootNodeProvider";

export const isScrollToTopAllowed = rootNode => {
	const position = rootNode.getBoundingClientRect();
	return position.y < 0;
};

export const scrollToTop = () => {
	const fallbackOffset = 30;
	const rootNode = RootNodeProvider.getRootNode();
	const position = rootNode.getBoundingClientRect();
	const offsetY = rootNode.dataset.scrollMargin !== undefined ? rootNode.dataset.scrollMargin : fallbackOffset;

	if (position.y < offsetY) {
		window.scrollTo(window.scrollX, window.scrollY + position.top - offsetY);
	}
};
