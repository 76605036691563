// @flow
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import "./PickupLocation.scss";
import type {GroupMember} from "../../models/GroupMember";
import type {translate} from "../../i18next";
import LocationIcon from "../../assets/svg/location_on.svg";
import type {Product} from "../../models/Product";
import {calcCrow} from "../../helper/LocationUtils";

type PickupLocationProps = {
	isVisible: boolean,
	locationChoices: GroupMember[],
	location: GroupMember,
	t: translate,
	setLocationAndReload: (GroupMember, string) => void,
	linkLabel: ?string,
	linkStyle: ?{},
	product: Product,
	cartHasProducts: boolean,
	fallbackPictureURL: string,
	classNames: ?string,
};

const modalStyles = {
	overlay: {
		zIndex: "100000"
	},
	content: {
		top: "48px",
		transform: "translate(-50%)",
		marginBottom: "64px",
		left: "50%",
		display: "block",
		padding: "32px",
		borderRadius: "4px",
		height: "fit-content",
		maxHeight: "90vh",
		minHeight: "250px",
		maxWidth: "856px",
		position: "absolute",
		width: "100%",
		overflow: "auto",
		boxSizing: "border-box",
		background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
		flexFlow: "column nowrap",
	}
};

export const PickupLocation = ({
								   cartHasProducts,
								   fallbackPictureURL,
								   isVisible,
								   linkLabel,
								   linkStyle,
								   location,
								   locationChoices,
								   product,
								   setLocationAndReload,
									 classNames,
								   t,
                   defaultLocation
							   }: PickupLocationProps) => {
	const [showLocationSelect, setLocationSelect] = useState(false);
	const [locations, setLocations] = useState(locationChoices);
	const [isDistanceCalculatedYet, setIsDistanceCalculatedYet] = useState(false);
	const [singleLocation, setSingleLocation] = useState(locations.length === 1 && locations[0]);

  useEffect(() => {

  }, []);

	useEffect(() => {
		if (navigator && navigator.geolocation && (!window.slDataLayer || !window.slDataLayer.longitude || !window.slDataLayer.latitude)) {
			navigator.geolocation.getCurrentPosition(function (position, error) {
				window.slDataLayer = {
					...(window.slDataLayer || {}),
					...{
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
						error: error,
					}
				};
				if (position.coords.latitude && position.coords.longitude && locations && locations.length > 0) {
					setLocations(locations
						.map(loc => ({
							...loc,
							...{distance: calcCrow(loc.location.latitude, loc.location.longitude, position.coords.latitude, position.coords.longitude)}
						}))
						.sort((a, b) => a.distance - b.distance)
					);
				}
			});
		} else {
			if (window.slDataLayer && window.slDataLayer.longitude && window.slDataLayer.latitude && isDistanceCalculatedYet) {
				setIsDistanceCalculatedYet();
				setLocations(locations
					.map(loc => ({
						...loc,
						...{distance: calcCrow(loc.location.latitude, loc.location.longitude, window.slDataLayer.latitude, window.slDataLayer.longitude)}
					}))
					.sort((a, b) => a.distance - b.distance)
				);
			}
		}
	}, [locations, isDistanceCalculatedYet]);

	useEffect(() => {
		const nearbyLocation = locations.find(it => parseFloat(it.distance) < 2);
		const setupLocation = locations.find(it => it.cfgKey === defaultLocation);
		locations.length === 1 && setSingleLocation(locations[0]);
		if (singleLocation && !location) {
			setLocation(null, singleLocation);
		} else if ((setupLocation || nearbyLocation) && !location) {
			setLocation(null, setupLocation ? setupLocation : nearbyLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, locations]);

	const toggleLocationSelect = e => {
		e.preventDefault();
		e.stopPropagation();
		setLocationSelect(!showLocationSelect);
	};

	const setLocation = (e, dealership: GroupMember) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (!cartHasProducts || window.confirm(t("location.clearCartWarning"))) {
			setLocationAndReload(dealership, product.id);
			setLocationSelect(false);
		}
	};

	linkLabel = linkLabel || (location ? <u>{`(${t("location.change")})`}</u> : t("location.select"));

	return isVisible &&
		<React.Fragment>
			{!singleLocation && (
				<span onClick={toggleLocationSelect} className={"hpm-locationModalLink " + (classNames || "")} role="button" style={linkStyle}>
					{linkLabel}
				</span>
			)}
			<Modal isOpen={showLocationSelect}
				   style={modalStyles}
				   shouldCloseOnOverlayClick={true}
				   onRequestClose={toggleLocationSelect}
				   id={"hpm-locationSelector"}
				   appElement={this}
				   ariaHideApp={false}
			>
				<div className="hpm-locationsHead">
					<button onClick={e => toggleLocationSelect(e)} className={"hpm-modalClose"}/>
					<div className="hpm-locationsHeadline">{t("location.headline")}</div>
				</div>

				<div className="hpm-locationsBody">
					<div className="hpm-locations">{locations.map((dealership: GroupMember) =>
						<div className={"hpm-location"} key={dealership.cfgKey}>
							<div className="hpm-locationHeadline">
								<div className="hpm-locationName"><span>{dealership.name}</span></div>
							</div>
							<div className="hpm-locationDetails">
								<div className="hpm-locationImage">
									{dealership.garagePictureUrl
										? <div style={{backgroundImage: `url('${dealership.garagePictureUrl}')`}}
											   title={t("location.pictureAlt")} className={"hpm-garageImageContainer"}/>
										: <img src={fallbackPictureURL} alt={t("location.pictureAlt")}/>}
								</div>
								<div className="hpm-locationAddress">
									<div className="hpm-locationAddressIcon">
										<img src={LocationIcon} alt={"📍"}/>
									</div>
									<div className="hpm-locationAddressText">
										<div>{`${dealership.address.street}\u00A0${dealership.address.streetNumber}`}</div>
										<div>{`${dealership.address.postalCode}\u00A0${dealership.address.town}`}</div>
										{dealership.distance &&
											<div
												className="hpm-locationDistance">{t("location.distance", {distance: parseFloat(dealership.distance).toFixed(1)}) + " km"}</div>
										}
									</div>
								</div>
								{(location && location.cfgKey === dealership.cfgKey)
									? <button className="hpm-selectButton" disabled>
										{t("location.selectedButton")}
									</button>
									: <button className="hpm-selectButton" onClick={e => setLocation(e, dealership)}>
										{t("location.selectButton")}
									</button>
								}
							</div>
						</div>
					)}
					</div>
				</div>
			</Modal>
		</React.Fragment>;
};