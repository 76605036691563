// @flow

import React from "react";
import "./CarModelDropdown.scss";
import type FilterVehicleModel from "../../models/FilterVehicleModel";
import CarModelDropdownItem from "./CarModelDropdownItem";

export type CarModelDropdownProps = {
	vehicleModels: FilterVehicleModel[],
	vehicleSeries: string,
	setVehicle: (string, string) => void,
	isVisible: boolean,
	toggleVehicleSelector: string => void,
	carRef: ?HTMLElement,
	allowsClick: boolean,
}

const CarModelDropdown = (props: CarModelDropdownProps) => {
	return (props.isVisible
		? <CarModelDropdownItem
			vehicleSeries={props.vehicleSeries}
			setVehicle={props.setVehicle}
			vehicleModels={props.vehicleModels}
			toggleVehicleSelector={props.allowsClick ? props.toggleVehicleSelector : () => {
			}}
			carRef={props.carRef}
		/>
		: <div className={"hpm-carModelDropdownPlaceholder"}/>
	);
};

export default CarModelDropdown;
