import type {Product} from "../models/Product";

export const getEtCommerceProduct = (product: Product) => {
	return {
		id: product.id,
		name: product.name,
		price: product.price.value.toString(),
		currency: product.price.currency,
		variants: {},
		category: []
	};
};

export const getEtCommerceOrder = order => {
	let etCommerceOrder = {
		orderNumber: order.order.orderNo,
		status: "sale",
		orderPrice: order.totalBillingAmount.cost.toString(),
		currency: order.totalBillingAmount.currencyCode,
		basket: {
			id: order.order.referenceNumber,
			products: []
		},
		customerGroup: "HomepageModul",
		deliveryConditions: order.order.orderItems ? order.order.orderItems[0].shippingMethod.code : "Unknown",
		paymentConditions: order.payment.paymentProvider
	};

	order.order.orderItems.map(
		item => etCommerceOrder.basket.products.push(
			{
				product: {
					id: item.product.productNo,
					name: item.product.productName,
					price: item.product.unitPrice.pricePerUnit.cost.toString(),
					currency: item.product.unitPrice.pricePerUnit.currency.code,
					category: []
				},
				quantity: item.amount.amount
			}
		)
	);

	return etCommerceOrder;
};
