// @flow

import type {ApplicationState} from "../reducers";
import type {Vehicle} from "../models/Vehicle";
import {vehicleReset, vehicleSet} from "../reducers/filterReducer";

export const setVehicle = (vehicle: string = null, vehicleModel: string = null) => {
	return (dispatch, getState: () => ApplicationState) => {
		const allSeries: Vehicle[] = getState().filter.filterStats.vehicleSeries;
		const allModels: Vehicle[] = getState().filter.vehicleModels;
		const selectedSeries: Vehicle = allSeries.find(series => series.name === vehicle || series.key === vehicle || series.key === vehicleModel || series.name === vehicleModel) || null;
		const selectedModels: Vehicle = allModels.find(model => model.name === vehicleModel || model.key === vehicleModel || model.name === vehicle || model.key === vehicle) || null;

		dispatch(vehicleSet({
			vehicle: selectedSeries,
			vehicleModel: selectedModels,
			vehicleKey: selectedModels ? selectedModels.key : (selectedSeries ? selectedSeries.key : null),
		}));
	};
};

export function resetVehicle() {
	return vehicleReset();
}
