import React from "react";
import type {translate} from "../../i18next";
import Modal from "react-modal";
import Button from "./Button";
import "./WelcomeMessageModal.scss";

export type WelcomeMessageModalProps = {
    t: translate,
    showWelcomeMessage: boolean,
    resetWelcomeMessage: () => void,
    dealerName: string,
    dealerImageURL: string,
    fallbackImageURL: string
}

class WelcomeMessageModal extends React.Component<WelcomeMessageModalProps> {
	modalStyles = {
		overlay: {
			zIndex: "100000"
		},
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			border: "1px solid grey",
			width: "calc(90%)",
			maxWidth: "1000px",
			minHeight: "275px",
			maxHeight: "90vh",
			overflowY: "auto",
		}
	};

	render() {
		return <Modal
			isOpen={this.props.showWelcomeMessage}
			style={this.modalStyles}
			shouldCloseOnOverlayClick={true}
			onRequestClose={this.props.resetWelcomeMessage}
		>
			<button type={"button"} onClick={this.props.resetWelcomeMessage} className="hpm-modalClose"/>
			<div className="hpm-modalHeader">
				<div className="hpm-modalHeaderImage">
					<img src={!this.props.dealerImageURL ? this.props.fallbackImageURL : this.props.dealerImageURL}
						alt=""/>
				</div>
				<div className="hpm-modalHeaderTitle">
					{this.props.dealerName}
				</div>
			</div>
			<div className="hpm-modalBody">
				<div className="hpm-modalContent">{this.props.t("welcomeMessage.messageOne")}</div>
				<div className="hpm-modalContent">{this.props.t("welcomeMessage.messageTwo")}</div>
			</div>
			<div className="hpm-modalFooter">
				<div className="hpm-modalFooterContent">
					<Button onClick={this.props.resetWelcomeMessage}>{this.props.t("welcomeMessage.button")}</Button>
				</div>
			</div>
		</Modal>;
	};
}

export default WelcomeMessageModal;
