// @flow
import {ToastContainer} from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./ToastifyContainer.scss";
import {useSelector} from "react-redux";

export type ToastProviderProps = { }

const ToastifyContainer = (props: ToastProviderProps) => {
	const manufacturer = useSelector(state => ((state.config.dealerDetails || {}).dealership || {}).manufacturerName);
	return <div id={"hpm-toastContainer"} className={`hpm-noPrint hpm-${manufacturer}`}>
		<ToastContainer
			autoClose={8000}
			closeButton={false}
			toastClassName="toastBody"
			bodyClassName="toastText"
			progressClassName="toastProgress"
		/>
	</div>;
};

export default ToastifyContainer;
