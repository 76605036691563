// @flow

export const RoleTypes = {
	DEALER: "DEALER",
	GARAGE: "GARAGE",
	GARAGE_GROUP: "GARAGE_GROUP",
	GROUP_DEALER: "GROUP_DEALER",
	DISTRIBUTOR: "DISTRIBUTOR",
};

export type Role = RoleTypes.DEALER | RoleTypes.GARAGE | RoleTypes.GARAGE_GROUP | RoleTypes.DISTRIBUTOR | RoleTypes.GROUP_DEALER;
