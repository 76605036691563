// @flow
import ReactGA from "react-ga4";
import type {Tracking} from "../../models/Tracking";
import BaseTracker from "./BaseTracker";
import type {Product} from "../../models/Product";
import type {OrderReducerState} from "../../reducers/orderReducer";
import type {CouponReducerState} from "../../reducers/couponReducer";

export default class GoogleTracker extends BaseTracker implements Tracking {
    constructor(trackingIds: string[], withEcommerce: boolean = false, dealerId: string = "unknown", dealerName: string = "unknown", customerNo: string = "unknown") {
        super(trackingIds[0], withEcommerce);
        if (navigator.doNotTrack === "1") {
            window[`ga-disable-${this.trackingId}`] = true;
        }
        const gtagOptions = {
            "dimension1": dealerId,
            "dimension2": dealerName,
            "dimension3": customerNo,
        };

        ReactGA.initialize(trackingIds
                .map(id => ({
                    trackingId: id,
                    gaOptions: {},
                    gtagOptions
                })),
            {
                testMode: false,
            }
        );
    }


    handleGenericEvent(cat: string, action: string, label: any): void {
        ReactGA.event(action, {
            category: cat,
            label: label,
            event_label: label,
            event_source: "WEB"
        });
    }

    // GA4 has build-in support for ecommerce
    // special Events like "purchase" or "add_to_cart" replace the old ecommerce API
    // https://developers.google.com/tag-platform/gtagjs/reference/events
    handleOrderEvent(order: OrderReducerState, coupon: CouponReducerState = null) {
        const myCouponNo = (coupon && coupon.coupon && coupon.coupon.coupon && coupon.coupon.coupon.couponNo)
            ? coupon.coupon.coupon.couponNo
            : {};
        const gaItems = order.order.orderItems.map(item => ({
            item_id: item.product.productNo,
            item_name: item.product.productName,
            affiliation: order.order.dealerId,
            sku: item.product.productNo,
            quantity: item.amount.amount,
            price: item.product.unitPrice.pricePerUnit.cost,
            currency: item.product.unitPrice.pricePerUnit.currency.code,
            coupon: myCouponNo.index,
            item_category: "Accessories Navigator",
            item_list_name: order.order.referenceNumber,
        }));
        const purchase = {
            id: order.order.orderNo,
            affiliation: order.order.dealerId,
            coupon: myCouponNo.index,
            shipping: order.orderItemsPriceSumWithShipping.cost - order.orderItemsPriceSum.cost,
            currency: order.totalBillingAmount.currency.code,
            value: order.totalBillingAmount.cost,
            payment_type: (order.payment || {}).paymentProvider,
            items: gaItems,
        };

        ReactGA.event("purchase", purchase);

        // Add the add_payment_info event
        const addPaymentInfo = {
            currency: order.totalBillingAmount.currency.code,
            value: order.totalBillingAmount.cost,
            coupon: myCouponNo.index,
            payment_type: (order.payment || {}).paymentProvider,
            items: gaItems,
        };

        ReactGA.event("add_payment_info", addPaymentInfo);

        // Check for shipping info and add the add_shipping_info event
        const addShippingInfo = {
            currency: order.orderItemsPriceSumWithShipping.currency,
            value: order.orderItemsPriceSumWithShipping.cost - order.orderItemsPriceSum.cost,
            items: gaItems,
        };

        ReactGA.event("add_shipping_info", addShippingInfo);}

    handleAddItemEvent(cat: string, action: string, product: Product) {
        // new GA4 event for automatic GA4-Dashboard-ecommerce statistics
        const addToCartBody = {
            currency: product.price.currency,
            value: product.price.value,
            items: [{
                item_id: product.id,
                item_name: product.name,
                affiliation: product.dealerId,
                item_category: "Accessories Navigator",
                price: product.price.value,
                quantity: 1,
            }],
        };
        ReactGA.event("add_to_cart", addToCartBody);

        // old, nicely translated GA event for our own reporting
        this.handleGenericEvent(cat, action, product.name);
    }

    handleRemoveItemEvent(cat: string, action: string, product: Product) {
        // new GA4 event for automatic GA4-Dashboard-ecommerce statistics
        const removeFromCartBody = {
            currency: product.price.currency,
            value: product.price.value,
            items: [{
                item_id: product.id,
                item_name: product.name,
                affiliation: product.dealerId,
                item_category: "Accessories Navigator",
                price: product.price.value,
                quantity: 1,
            }],
        };
        ReactGA.event("remove_from_cart", removeFromCartBody);

        // old, nicely translated GA event for our own reporting
        this.handleGenericEvent(cat, action, product.name);
    };

    handleClearCartEvent() {
    }

    handleViewProductDetailsEvent(event: {}, product: Product) {
        this.handleGenericEvent(event.CATEGORIES.PRODUCT, event.ACTIONS.PRODUCT.PRODUCT_VISITED, product.name);
    }

    handleInsertToWatchlistEvent(event: {}, product: Product) {
        // new GA4 event for automatic GA4-Dashboard-ecommerce statistics
        const addToWishlistBody = {
            currency: product.price.currency,
            value: product.price.value,
            items: [{
                item_id: product.id,
                item_name: product.name,
                affiliation: product.dealerId,
                item_category: "Accessories Navigator",
                price: product.price.value,
                quantity: 1,
            }],
        };
        ReactGA.event("add_to_wishlist", addToWishlistBody);

        // old, nicely translated GA event for our own reporting
        this.handleGenericEvent(event.CATEGORIES.BOOKMARKS, event.ACTIONS.BOOKMARKS.ADD_PRODUCT, product.name);
    }

    handleRemoveFromWatchlistEvent(event: {}, product: Product) {
        this.handleGenericEvent(event.CATEGORIES.BOOKMARKS, event.ACTIONS.BOOKMARKS.REMOVE_PRODUCT, product.name);
    }

    handleSearchEvent(searchPhrase: string) {
        ReactGA.event("search", {
            search_term: searchPhrase
        });
        this.handleGenericEvent(searchPhrase, searchPhrase, searchPhrase);
    }

    handleShareEvent(cat: string, action: string, label: any) {
        const shareEvent = {
            category: cat,
            label: label,
            event_label: label,
            event_source: "WEB"
        };
        ReactGA.event("share", shareEvent);
    }

    handleViewItemEvent(event: {}, product: Product) {
        const viewItemEvent = {
            currency: product.price.currency,
            value: product.price.value,
            items: [{
                item_id: product.id,
                item_name: product.name,
                affiliation: product.dealerId,
                item_category: "Accessories Navigator",
                price: product.price.value,
                quantity: 1,
            }],
        };
        ReactGA.event("view_item", viewItemEvent);
        this.handleGenericEvent(event.CATEGORIES.PRODUCT, event.ACTIONS.PRODUCT.PRODUCT_VISITED, product.name);
    }
}
