import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PayPalButton from "../../../components/checkout/dataSummaryBox/PayPalButton";
import type {ApplicationState} from "../../../reducers";
import {setPaymentError} from "../../../actions/order";
import { convertToLocale } from "../../../helper/languageUtils";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	return {
		clientId: state.cart.paypalClientId,
		payee: state.cart.paymentFacility ? state.cart.paymentFacility.account.payee : null,
		currencyCode: state.order.totalBillingAmount.currency.code,
		totalBillingAmount: state.order.totalBillingAmount.cost,
		orderNo: state.order.order.orderNo,
		dealerNo: state.order.order.dealerId,
		moduleLanguage: convertToLocale(state.config.moduleLanguage),
		buyerAdress: state.order.adress,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setPaymentError: () => {
			dispatch(setPaymentError());
		}
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayPalButton));
