import {connect} from "react-redux";
import type {ApplicationState} from "../../../reducers";
import {resetMailing, submitShare} from "../../../actions/mailing";
import ShareForm from "../../../components/productDetail/Sharing/ShareForm";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		contactData: state.mailing.contactData,
		sendSuccess: state.mailing.sharingSuccess,
		sendError: state.mailing.sharingError,
		captchaInvalid: state.mailing.invalidCaptcha,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		submitShare: productShare => dispatch(submitShare(productShare)),
		resetMailing: () => dispatch(resetMailing())
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShareForm));
