// @flow
import React, {useEffect, useState} from "react";
import type {Product} from "../../../models/Product";
import "./ShareFormular.scss";
import type {translate} from "../../../i18next";
import {useForm} from "react-hook-form";
import type {ProductSharing} from "../../../models/ProductSharing";
import type {ContactData} from "../../../models/ContactData";
import {toast} from "react-toastify";
import SlCaptcha from "../../SlCaptcha";

export type SharePageProps = {
	product: Product,
	closeSharePage: () => void,
	resetMailing: () => void,
	submitShare: (ProductSharing) => void,
	t: translate,
	contactData: ContactData,
	sendSuccess: boolean,
	sendError: boolean,
	captchaInvalid: boolean,
}

const ShareForm = (props: SharePageProps) => {
	const [captcha, setCaptcha] = useState(null);

	const {register, handleSubmit, errors} = useForm({defaultValues: props.contactData});
	const onSubmit = (data: ProductSharing) => {
		props.submitShare({
			...data,
			product: props.product,
			bookmarkUrl: window.location.href,
			captchaToken: captcha
		});
	};

	useEffect(() => {
		if (props.sendSuccess) {
			toast(props.t("feedback.sharing.sendSuccess"));
			props.closeSharePage();
			props.resetMailing();
		}
		if (props.sendError || props.captchaInvalid) {
			toast(props.sendError
				? props.t("feedback.sharing.sendError")
				: props.t("feedback.captchaInvalid")
			);
			props.resetMailing();
		}
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={"hpm-close-sharePage"} onClick={() => props.closeSharePage()}/>
			<div className={"hpm-share-image"}/>
			<div>
				<table cellPadding="0" cellSpacing="0">
					<tbody>
						<tr>
							<td colSpan="2">
								<h3>{props.t("feedback.sharing.sharingHeadline")}</h3>
							</td>
						</tr>
						<tr>
							<td colSpan="2">
								<p>{props.t("feedback.sharing.sharingText")}</p>
							</td>
						</tr>
						<tr>
							<td colSpan="2">
								<span className="label">{props.t("feedback.subject")}</span>
								<span className="subject">{` "${props.product.name}"`}</span>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className={errors.message ? "hpm-error" : ""}>
								<span className="label">{props.t("feedback.message")}</span>
								<textarea name="message"
									  ref={register({required: true})}>
								</textarea>
							</td>
						</tr>
						<tr>
							<td>{props.t("feedback.senderMail")}</td>
							<td className={errors.email ? "hpm-error" : ""}>
								<input type="text" name="email" placeholder={props.t("feedback.email")}
									   ref={register({
										   required: true,
										   pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
									   })}/>
							</td>
						</tr>
						<tr>
							<td>{props.t("feedback.receiverMail")}</td>
							<td className={errors.receiverEmail ? "hpm-error" : ""}>
								<input type="text" name="receiverEmail" placeholder={props.t("feedback.email")}
									   ref={register({
										   required: true,
										   pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
									   })}/>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className={"captchaText"}>{props.t("feedback.enterCaptcha")}:</td>
						</tr>
						<tr>
							<td colSpan={"2"}>
								<div>
									<SlCaptcha
										setCaptcha={setCaptcha}
										captcha={captcha}
										error={props.captchaInvalid}
									/>
								</div>
							</td>
						</tr>
					</tbody>
				</table>

				<button type={"submit"} className="hpm-contact-send">
					{props.t("feedback.send")}
				</button>
			</div>
		</form>
	);
};

export default ShareForm;
