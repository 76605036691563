// @flow
import React, { Component } from "react";
import InfoBox from "./InfoBox";
import PriceBox from "./PriceBox";
import Loading from "../layout/Loading";
import type { Product } from "../../models/Product";
import type { Attachments } from "../../models/Attachments";
import Gallery from "./Gallery";
import type { translate } from "../../i18next";
import SwimLane from "../SwimLane";
import { CSSTransition } from "react-transition-group";
import Share from "./Sharing/Share";
import SharingIconBox from "./Sharing/SharingIconBox";
import "./Detailpage.scss";
import QuestionFormContainer from "../../container/productDetail/QuestionForm/QuestionFormContainer";
import NscDealerSearchContainer from "../../container/productDetail/NSC/NscDealerSearchContainer";
import { DealerList } from "./NSC/DealerList";
import type { DealerDetails } from "../../models/DealerDetails";
import { makeSpacesNonbreaking } from "../../helper/stringUtils";
import EventEmitter from "../../helper/eventEmitter";
import { scrollToTop } from "../../helper/anchor";
import type { GroupMember } from "../../models/GroupMember";
import PickupLocationContainer from "../../container/pickupLocation/PickupLocationContainer";
import WelcomeMessageModal from "../layout/WelcomeMessageModal";
import GridElementContainer from "../../container/gridPages/GridElementContainer";

export type DetailpageState = {
	currentProduct: boolean,
	showQuestion: boolean,
	showShareForm: boolean,
	showDealerList: boolean,
	showShareItemBox: boolean
}

export type DetailpageProps = {
	location: any,
	articleNumber: string,
	product: Product,
	attachments: Attachments,
	properties: any,
	productLoaded: ?boolean,
	config: any,
	getProduct: string => Product,
	isBookmarked: ?boolean,
	addToCart: (Product, string) => void,
	addBookmark: Product => void,
	removeBookmark: Product => void,
	t: translate,
	isShopEnabled: boolean,
	isLeadModuleEnabled: boolean,
	watchlistEnabled: boolean,
	relatedProducts: Product[],
	isNSCDealer: boolean,
	dealerDetails: DealerDetails,
	showDealerList: boolean,
	setShowDealerList: boolean => void,
	trackShareButtonClick: Product => void,
	pickupLocation: ?GroupMember,
	locationChoices: ?GroupMember[],
	needsPickupLocations: boolean,
	showWelcomeMessage: boolean,
	resetWelcomeMessage: () => void,
	brandName: string,
}

class Detailpage extends Component<DetailpageProps, DetailpageState> {
	constructor(props) {
		super(props);
		props.getProduct(props.articleNumber);
		this.state = {
			currentProduct: props.articleNumber,
			showQuestion: false,
			showShareForm: false,
			showShareIconBox: false,
		};
	}

	componentDidMount() {
		new EventEmitter().emitEvent("HPM_PAGE_LOADED");
		scrollToTop();
	}

	componentDidUpdate() {
		if (this.state.currentProduct !== this.props.articleNumber) {
			new EventEmitter().emitEvent("HPM_PAGE_LOADED");
			this.props.getProduct(this.props.articleNumber);
			this.setState({currentProduct: this.props.articleNumber});
			scrollToTop();
		}
	}

	componentWillUnmount() {
		if (this.props.setShowDealerList) return this.props.setShowDealerList(false);
	}

	renderCrossPromotion() {
		if (!this.props.relatedProducts.length) {
			return null;
		}

		return <React.Fragment>
			<div className="hpm-categoryProduct">
				<div className="hpm-ProductTitle">{this.props.t("productDetail.relatedArticle")}</div>
			</div>
			<SwimLane>
				{
					this.props.relatedProducts.map(product => {
						return <GridElementContainer key={product.id} product={product}/>;
					})
				}
			</SwimLane>
		</React.Fragment>;
	}

	toggleQuestion = () => {
		this.setState({
			showQuestion: !this.state.showQuestion,
			showShareForm: false,
		});
		this.props.setShowDealerList(false);
	};

	setDealerList = (value: boolean) => {
		this.setState({
			showQuestion: false,
			showShareForm: false
		});
		this.props.setShowDealerList(value);
	};

	toggleSharingForm = () => {
		if (navigator.share) return;
		this.setState({
			showQuestion: false,
			showShareForm: !this.state.showShareForm
		});
		this.props.setShowDealerList(false);
	};

	toggleSharingIconBox = () => {
		this.setState({showShareIconBox: !this.state.showShareIconBox});
	};

	handleShare = () => {
		const shareData = {
			title: window.document.title,
			text: this.props.product.name,
			url: window.location
		};
		navigator.share(shareData)
			.then(() => this.props.trackShareButtonClick(this.props.product))
			/* eslint-disable-next-line */
			.catch(error => console.log(error));
	};

	renderShareBtn() {
		return navigator.share
			? <div className="hpm-shareWrapper">
				<div onClick={this.handleShare}>{this.props.t("feedback.share")}</div>
			</div>
			: <div className="hpm-shareWrapper">
				<span onClick={this.toggleSharingIconBox} className="hpm-sharingLink">
					{this.props.t("feedback.share")}
					{this.state.showShareIconBox && <SharingIconBox
						toggleSharingForm={this.toggleSharingForm}
						trackShareButtonClick={this.props.trackShareButtonClick}
						product={this.props.product}
					/>}
				</span>
			</div>;
	}

	renderContactButton() {
		return <div className={"contactWrapper"}>
			<div className={this.props.dealerDetails.phone ? "hpm-phone" : ""}>
				<a href={this.props.dealerDetails.phone
					? `tel:${this.props.dealerDetails.phone.replace(/ /g, "")}`
					: ""}
				>
					{this.props.dealerDetails.phone
						? makeSpacesNonbreaking(this.props.dealerDetails.phone)
						: ""
					}
				</a>
			</div>
		</div>;
	};

	render() {
		const addToCart = shippingMethod => {
			this.props.addToCart(this.props.product, shippingMethod);
		};
		if (this.props.productLoaded) {
			return (
				<div className="hpm-productPage" data-testid="productPage">
					<WelcomeMessageModal
						dealerName={this.props.dealerDetails.name}
						dealerImageURL={this.props.dealerDetails.garagePictureUrl}
						fallbackImageURL={this.props.fallbackImageURL}
						showWelcomeMessage={this.props.showWelcomeMessage}
						resetWelcomeMessage={this.props.resetWelcomeMessage}
						t={this.props.t}
					/>
					<CSSTransition in={!!this.state.showShareForm} unmountOnExit={true} timeout={0}>
						<React.Fragment>
							<Share product={this.props.product} closeSharePage={this.toggleSharingForm}/>
						</React.Fragment>
					</CSSTransition>
					<CSSTransition in={!!this.state.showQuestion} unmountOnExit={true} timeout={0}>
						<React.Fragment>
							<QuestionFormContainer product={this.props.product} closeQuestion={this.toggleQuestion}/>
						</React.Fragment>
					</CSSTransition>
					<CSSTransition in={!!this.props.showDealerList} unmountOnExit={true} timeout={0}>
						<React.Fragment>
							<DealerList
								t={string => string} product={this.props.product}
								theme={this.props.config.theme}
								onClose={() => this.setDealerList(false)}
							/>
						</React.Fragment>
					</CSSTransition>
					<CSSTransition
						in={!this.state.showQuestion && !this.state.showShareForm && !this.props.showDealerList}
						unmountOnExit={true}
						timeout={0}>
						<React.Fragment>
							<Gallery
								galleryImage={this.props.product.galleryImage}
								images={this.props.attachments.images}
								videos={this.props.attachments.videos}
							/>
							<div className="hpm-sideBar">
								<div className="hpm-sideBarTitle">{this.props.product.name}</div>
								<div className="hpm-sideBarArticleNumber">
									<span className="hpm-ArticleNumberLabel">
										{this.props.t("productDetail.articleNumber")}:
									</span>
									<span className="hpm-ArticleNumberValue">{this.props.product.articleNumber || "-"}</span>
								</div>
								{this.props.pickupLocation && (
									<div className="hpm-selectedLocation">
										<div className="hpm-locationIcon"/>
										<div>
											{`${this.props.pickupLocation.address.street}\u00A0${this.props.pickupLocation.address.streetNumber}, ${this.props.pickupLocation.address.postalCode}\u00A0${this.props.pickupLocation.address.town} `}
											<PickupLocationContainer/>
										</div>
									</div>
								)}
								{this.props.isNSCDealer
									? <NscDealerSearchContainer
										setDealerList={this.setDealerList}
										showDealerList={this.props.showDealerList}/>
									: <>
										<PriceBox
											t={this.props.t}
											isShopEnabled={this.props.isShopEnabled}
											isLeadModuleEnabled={this.props.isLeadModuleEnabled}
											watchlistEnabled={this.props.watchlistEnabled}
											infobox={this.props.config.disclaimer}
											addToCart={addToCart}
											product={this.props.product}
											dealerDetails={this.props.dealerDetails}
											addBookmark={this.props.addBookmark}
											isBookmarked={this.props.isBookmarked}
											removeBookmark={this.props.removeBookmark}
											needsPickupLocation={this.props.needsPickupLocations}
										/>
										<div className="hpm-shareAndContact">
											{this.props.isLeadModuleEnabled && this.renderShareBtn()}
											{(this.props.watchlistEnabled || this.props.isShopEnabled) && this.renderContactButton()}
											{this.props.isLeadModuleEnabled &&
												<div onClick={this.toggleQuestion} className="hpm-questionWrapper">
													<div>{this.props.t("feedback.questions")}</div>
												</div>
											}
										</div>
									</>
								}
							</div>
							<InfoBox
								t={this.props.t}
								datas={this.props.properties}
								files={this.props.attachments.documents}
								product={this.props.product}
								brandName={this.props.brandName}
							/>
							{this.renderCrossPromotion()}
						</React.Fragment>
					</CSSTransition>
				</div>
			);
		} else {
			return (
				<Loading/>
			);
		}
	}
}

export default Detailpage;
