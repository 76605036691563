// @flow
import type {ApplicationState} from "../reducers";
import ProductApi, {INTENT} from "../api/ProductApi";
import {filterInstanceSet, filterReset, filterSet, filterStatsSet, searchPhraseSet} from "../reducers/filterReducer";

export const setFilter = (key, value) => {
	return filterSet({
		key,
		value,
	});
};

export const setSearchPhrase = (value: string) => {
	return searchPhraseSet(value);
};

export const resetFilter = () => {
	return filterReset();
};

export const setFilterInstance = (instance, subCat) => {
	return filterInstanceSet({
		filterInstance: instance,
		subCategory: subCat || {},
	});
};

export const getFilterStats = filter => {
	return (dispatch, getState: () => ApplicationState) => {
		const {dealerId, moduleLanguage, role} = getState().config;
		const {currentPage} = getState().content;
		const environment = getState().config.environment;
		const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;
		const productApi = new ProductApi(dealerId, {configIdType: role, intent: intent, language: moduleLanguage});
		const filterOptions = {
			fuzzy: filter.searchPhrase === "undefined" ? "" : filter.searchPhrase,
			sortOrders: [{
				fieldname: filter.sort.fieldname,
				direction: filter.sort.direction,
			}],
			filterParameters: {
				categoryKey: filter.categoryKey,
				categories: filter.categoryKey
					? null
					: filter.categories,
				priceRange: filter.priceRange,
				vehicleKey: filter.vehicleKey,
				vehicle: filter.vehicleKey
					? null
					: {series: filter.vehicle, model: filter.vehicleModel,},
				markers: filter.markers,
			},
		};

		return productApi.getProductsWithFilter(currentPage, filterOptions)
			.then(response => dispatch(filterStatsSet(response)));
	};
};
