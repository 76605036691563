// @flow

import React from "react";
import FilterPageCarlineContainer from "../../container/Filter/FilterPageCarlineContainer";
import FilterPageSortContainer from "../../container/Filter/FilterPageSortContainer";
import FilterPageSubCategoryContainer from "../../container/Filter/FilterPageSubCategoryContainer";
import FilterPageMainCategoryContainer from "../../container/Filter/FilterPageMainCategoryContainer";
import FilterStartPageContainer from "../../container/Filter/FilterStartPageContainer";
import FilterPagePriceRangeContainer from "../../container/Filter/FilterPagePriceRangeContainer";
import {FILTER} from "../../reducers/filterReducer";
import FilterPageVehicleModelContainer from "../../container/Filter/FilterPageVehicleModelContainer";
import type {translate} from "../../i18next";

export type FilterProps = {
	t: translate,
	filterInstance: number,
};

const Filter = (props: FilterProps) => {
	switch (props.filterInstance) {
		case FILTER.CATEGORIES:
			return <FilterPageMainCategoryContainer/>;
		case FILTER.SUB_CATEGORY:
			return <FilterPageSubCategoryContainer/>;
		case FILTER.CARLINE:
			return <FilterPageCarlineContainer/>;
		case FILTER.SORT:
			return <FilterPageSortContainer/>;
		case FILTER.PRICE_RANGE:
			return <FilterPagePriceRangeContainer/>;
		case FILTER.VEHICLE_MODEL:
			return <FilterPageVehicleModelContainer/>;
		default:
			return <FilterStartPageContainer/>;
	}
};

export default Filter;
