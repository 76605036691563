// @flow

export const addQueryParam = (url: string = "", queryKey: string = "key", queryValue: string = "unset") => {
	let shopUrl = url;
	const array = url.split("#");

	if (array.length > 1) {
		array[0] += "#";
		shopUrl = array.join("");
	}

	try {
		let urlObj = new URL(shopUrl);
		let params = new URLSearchParams(urlObj.search);
		params.append(queryKey, queryValue);
		urlObj.search = params;
		return urlObj.toString();
	} catch (e) {
		let urlParts = url.split("#");
		if (urlParts.length === 1) {
			let urlParts2 = urlParts[0].split("?");
			return urlParts2[0] + "?" + queryKey + "=" + queryValue + "&" + urlParts2[1];
		} else {
			let urlParts2 = urlParts[0].split("?");
			return urlParts2[0] + "?" + queryKey + "=" + queryValue + "&" + urlParts2[1] + urlParts[1];
		}
	}
};
