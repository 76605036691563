import {connect} from "react-redux";
import {setFilter} from "../../actions/filter";
import FilterPageSubCategory from "../../components/Filter/FilterPageSubCategory";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import _ from "lodash";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		subCategory: _.isEmpty(state.filter.subCategory)
			? state.filter.filterStats.categories[0]
			: state.filter.subCategory,
		isValueSelected: value => state.filter.filter.categories.indexOf(value) !== -1
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setFilter: category => {
			dispatch(setFilter("categories", [category]));
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPageSubCategory));
