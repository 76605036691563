// @flow
import React, {useEffect, useState} from "react";
import "./WishlistShareForm.scss";
import type {translate} from "../../i18next";
import {WAY_TO_CONTACT} from "../../models/ProductEnquiry";
import {useForm} from "react-hook-form";
import type {ContactData} from "../../models/ContactData";
import type {WishlistSharing} from "../../models/WishlistSharing";
import {toast} from "react-toastify";
import SlCaptcha from "../SlCaptcha";
import type {Product} from "../../models/Product";

export type WishlistShareFormProps = {
	closeForm: () => void,
	resetMailing: () => void,
	t: translate,
	products: Product[],
	contactData: ContactData,
	submitWishlist: WishlistSharing => void,
	sendSuccess: boolean,
	sendError: boolean,
	captchaInvalid: boolean,
}

const WishlistShareForm = (props: WishlistShareFormProps) => {
	const [captcha, setCaptcha] = useState(null);
	const [isFinished, setIsFinished] = useState(false);

	const {register, handleSubmit, formState: {errors}, watch, clearErrors} = useForm({defaultValues: props.contactData});
	const onSubmit = (data: WishlistSharing) => {
		props.submitWishlist({
			...data,
			products: props.products,
			captcha
		});
		setIsFinished(true);
	};

	useEffect(() => {
		if (props.sendSuccess) {
			toast(props.t("feedback.wishlist.sendSuccess"));
			props.closeForm();
		}
		if (props.sendError || props.captchaInvalid) {
			toast(props.sendError
				? props.t("feedback.wishlist.sendError")
				: props.t("feedback.captchaInvalid")
			);
		}
		props.resetMailing();
	});

	const renderForm = () => {
		return <table cellPadding="0" cellSpacing="0">
			<tbody>
				<tr>
					<td colSpan="2">
						<h3>{props.t("feedback.wishlist.headline")}</h3>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<p>{props.t("feedback.wishlist.wishlistText")}</p>
					</td>
				</tr>
				<tr>
					<td className="hpm-label">
						<span>{props.t("feedback.wishlist.products")}:</span>
					</td>
					<td>
						<span>{props.products && props.products.map((product, index) => {
							return `${index > 0 ? ", " : " "}"${product.name}"`;
						})}</span>
					</td>
				</tr>
				<tr>
					<td colSpan="2" className={(errors || {}).message ? "hpm-error" : ""}>
						<span className="hpm-label">{props.t("feedback.message")}</span>
						<textarea name="message"
								  {...register("message", {
									  required: true,
									  pattern: {value: /^.*\S.{8}\S.*$/}
								  })}>
						</textarea>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<p>{props.t("feedback.mayWeContact")}</p>
						<div className="wayOfContact">
							<input id="contact-02" name="wayToContact" type="radio"
							   value={WAY_TO_CONTACT.viaMail}
							   {...register("wayToContact")} onClick={() => clearErrors("phone")}/>
							<label htmlFor="contact-02">{props.t("feedback.email")}</label>
							<input id="contact-01" name="wayToContact" type="radio"
							   value={WAY_TO_CONTACT.viaPhone}
							   {...register("wayToContact")} onClick={() => clearErrors("email")}/>
							<label htmlFor="contact-01">{props.t("feedback.phone")}</label>
						</div>
						<div className={(errors || {}).name ? "hpm-error" : ""}>
							<input type="text" placeholder={props.t("feedback.name")}
							   name="name" id="name"
								{...register("name", {
									required: true,
									pattern: {value: /^([a-zA-Z0-9._+-]{2,}'?-?[a-zA-Z0-9._+-]{1,}?)/i}
								})}/>
						</div>
						<div className={(errors || {}).phone ? "hpm-error" : ""}>
							<input type="text" placeholder={props.t("feedback.phone")} name="phone"
							   {...register("phone", {
								   required: watch("wayToContact") === WAY_TO_CONTACT.viaPhone,
								   pattern: {value: /^[0-9._%+-/]*$/i}
							   })}/>
						</div>
						<div className={(errors || {}).email ? "hpm-error" : ""}>
							<input type="email" placeholder={props.t("feedback.email")}
							   name="email"
							   {...register("email", {
								   required: watch("wayToContact") === WAY_TO_CONTACT.viaMail || watch("mailMyself"),
								   pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
							   })}/>
						</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<table className="hpm-conditions">
							<tbody>
								<tr>
									<td>
										<input id="data-copy" name="mailMyself" type="checkbox" {...register("mailMyself")}/>
										<label htmlFor="data-copy">
											{props.t("feedback.copyCheckbox")}
										</label>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td colSpan="2">{props.t("feedback.consent")}</td>
				</tr>
				<tr>
					<td colSpan="2" className="hpm-conditions">
						<div className={(errors || {}).consent ? "hpm-error" : ""}>
							<input id="data-protection" name="consent" type="checkbox"
							   {...register("consent", {required: true})}/>
							<label htmlFor="data-protection">
								{props.t("feedback.consentText")}
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td colSpan="2" className={"captchaText"}>{props.t("feedback.enterCaptcha")}:</td>
				</tr>
				<tr>
					<td colSpan={"2"}>
						<div>
							<SlCaptcha
								setCaptcha={setCaptcha}
								captcha={captcha}
								error={props.captchaInvalid}
							/>
						</div>
					</td>
				</tr>
			</tbody>
		</table>;
	};

	const renderFinished = () => {
		return <table cellPadding="0" cellSpacing="0">
			<tbody>
				<tr>
					<td>
						{props.t("feedback.wishlist.confirm")}
					</td>
				</tr>
			</tbody>
		</table>;
	};

	return (
		<div className="hpm-wishlistShare">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={"hpm-close-sharePage"} onClick={() => props.closeForm()}/>
				<div className={"hpm-share-image"}/>
				<div>
					{isFinished
						? renderFinished()
						: renderForm()
					}
					<button type={"submit"} className="hpm-contact-send">
						{props.t("feedback.wishlist.send")}
					</button>
				</div>
			</form>
		</div>
	);
};

export default WishlistShareForm;
