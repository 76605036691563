// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const HondaTheme = props => {
	import("./honda.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default HondaTheme;
