// @flow

import RootNodeProvider from "../helper/RootNodeProvider";
import {createSlice} from "@reduxjs/toolkit";

export type ConsentReducerState = {
	googleAnalytics: boolean,
	googleMaps: boolean,
};

let useConsentManager;
if (RootNodeProvider.getRootNode() && RootNodeProvider.getRootNode().dataset) {
	useConsentManager = RootNodeProvider.getRootNode().dataset.useConsentManager !== undefined;
}

const getInitialState = () => {
	if (useConsentManager) {
		return {
			googleAnalytics: false,
			googleMaps: false,
		};
	}

	const isTrackingAllowed = () => {
		if (!document.cookie) return false;
		const consentCookie = document.cookie.split("; ").find(row => row.startsWith("sl-app-accept-tracking"));
		const allowedByConsent = consentCookie ? (consentCookie.split("=")[1] !== "false") : false;
		const gaCookie = document.cookie.split("; ").find(row => row.startsWith("_ga"));
		const piwikCookie = document.cookie.split("; ").find(row => row.startsWith("_pk_id"));
		const cookieAlreadyPresent = !!gaCookie || !!piwikCookie;
		return (allowedByConsent || cookieAlreadyPresent);
	};

	return {
		googleAnalytics: isTrackingAllowed(),
		googleMaps: true,
	};
};

const initialState = getInitialState();

const consentSlice = createSlice({
	name: "consent",
	initialState,
	reducers: {
		consentGoogleAnalyticsSet(state, action) {
			state.googleAnalytics = action.payload;
		},
		consentGoogleMapsSet(state, action) {
			state.googleMaps = action.payload;
		}
	}
});

export const {consentGoogleMapsSet, consentGoogleAnalyticsSet} = consentSlice.actions;

export default consentSlice.reducer;
