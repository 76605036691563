// @flow
import React from "react";
import Button from "./Button";
import {Route} from "react-router-dom";
import SearchBarContainer from "../../container/Filter/SearchBarContainer";
import {withTranslation} from "react-i18next";
import type {translate} from "../../i18next";
import "./Header.scss";
import {scrollToTop} from "../../helper/anchor";
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

type HeaderProps = {
	t: translate,
	scroll: any,
	cartElements: number,
	bookmarksCount: number,
	openCategoryModal: () => void,
	openFilterModal: () => void,
	openCart: () => void,
	openBookmarks: () => void,
	searchProducts: () => void,
	handleBack: () => void,
	handleHome: () => void,
	backHome: () => void,
	searchPlaceholder: string,
	categoryText: string,
	showBack: boolean,
	watchlistEnabled: boolean,
	isShopEnabled: boolean,
	garageId: string,
	shouldRenderHomeButton: boolean,
	shouldRenderHistoryBack: boolean,
	shouldRenderHistoryBackButton: boolean,
	showResetBrandButton: boolean,
	resetBrand: () => void,
}

type HeaderState = any;

class Header extends React.Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props);
		this.headerRef = React.createRef();
	}

	render() {
		const headerClass = `${!this.props.showBack ? "hpm-showBack" : ""}`;
		const renderFilterButton = (label: string) => {
			return <Button
				name="category"
				style={{borderLeftWidth: this.props.showBack ? 0 : 1}}
				onClick={this.props.openFilterModal} disabled={false}>
				<label className="button-text">{label}</label>
			</Button>;
		};

		return <>
			<ContrastColorUpdater/>
			<div
				id={"hpm-header"}
				className={`hpm-subHeader ${headerClass}`}>
				{(
						(this.props.shouldRenderHistoryBack) ||
						(!!this.props.handleHome && this.props.shouldRenderHistoryBack)
					) &&
					<Button name="historyback" onClick={() => {
						this.props.handleBack();
						scrollToTop();
					}}/>
				}
				{!!this.props.handleHome && this.props.shouldRenderHomeButton &&
					<Button
						onClick={() => {
							this.props.handleHome();
							scrollToTop();
						}}
						disabled={false} name="homeButton"
						style={{borderLeftWidth: this.props.showBack ? 0 : 1}}>
					</Button>
				}
				{this.props.showResetBrandButton &&
					<Button name="historyback" onClick={() => {
						this.props.handleBack();
					}}/>
				}
				<Route
					path={["/products/:category?", "/brand/:manufacturerName/products/:category?"]}
					component={() => renderFilterButton(this.props.t("navigation.filter"))}
				/>
				<Route
					path={["/search/:searchPhrase?", "/brand/:manufacturerName/search/:searchPhrase?"]}
					component={() => renderFilterButton(this.props.t("navigation.filter"))}
				/>
				<SearchBarContainer
					searchProducts={this.props.searchProducts}
					searchPlaceholder={this.props.searchPlaceholder}/>
				{this.props.isShopEnabled &&
					<Button name="cart" onClick={() => {
						this.props.openCart();
						scrollToTop();
					}} disabled={false}>
						{(this.props.cartElements > 0) ? <span>{this.props.cartElements}</span> : ""}
					</Button>}
				{this.props.watchlistEnabled &&
					<Button name="bookmarks" onClick={() => {
						this.props.openBookmarks();
						scrollToTop();
					}} disabled={false}>
						{(this.props.bookmarksCount > 0) ? <span>{this.props.bookmarksCount}</span> : ""}
					</Button>}
			</div>
		</>;
	}
}

export default withTranslation()(Header);
