/* eslint-disable no-undef */
// @flow
import type TrackingProperties from "../models/TrackingProperties";
import type {Country} from "../models/Country";
import {defaultCountry} from "../models/Country";
import type {DealerDetails} from "../models/DealerDetails";
import RootNodeProvider from "../helper/RootNodeProvider";
import type {GarageDealership} from "../models/GarageDealership";
import {selectLanguage} from "../helper/languageUtils";
import type {Role} from "../models/Role";
import {RoleTypes} from "../models/Role";
import {createSlice} from "@reduxjs/toolkit";

export type ConfigReducerState = {
	brandInstances: GarageDealership[],
	theme: ?string,
	dealerId: ?string,
	dealerDetails: DealerDetails,
	disclaimer: ?string,
	environment: ?string,
	garageId: ?string,
	garageGroupId: ?string,
	dealerLanguage: ?string,
	moduleLanguage: string,
	name: ?string,
	trackingProperties: TrackingProperties,
	trackerLoaded: boolean,
	consentlessTrackerLoaded: boolean,
	configuredCountries: Country[],
	configLoaded: boolean,
	isShopEnabled: boolean,
	isLeadModuleEnabled: boolean,
	watchlistEnabled: boolean,
	productPricesDisabled: boolean,
	openDealerShopsInNewTab: boolean,
	isLoading: boolean,
	legalTerms: string,
	revocationTerms: string,
	role: ?Role,
	privacyPolicy: string,
	imprintText: string,
	contrastColor: string,
	defaultBrand: ?string, 		// optional auto config from root element
	defaultLocation: ?string,	// optional auto config from root element
};

let garageGroupId = "";
let dealerId = "";
let garageId = "";
let environment = "";
let role = "";
let preferredLanguage = "";
let openDealerShopsInNewTab = false;
let defaultBrand = null;
let defaultLocation = null;

const getInitialState = () => {
	if (RootNodeProvider.getRootNode() && RootNodeProvider.getRootNode().dataset) {
		dealerId = RootNodeProvider.getRootNode().dataset.dealerId;
		garageId = RootNodeProvider.getRootNode().dataset.garageId;
		garageGroupId = RootNodeProvider.getRootNode().dataset.garageGroupId;
		preferredLanguage = RootNodeProvider.getRootNode().dataset.language;
		role = RootNodeProvider.getRootNode().dataset.role || (garageId && RoleTypes.GARAGE) || (garageGroupId && RoleTypes.GARAGE_GROUP) || RoleTypes.DEALER;
		openDealerShopsInNewTab = "openDealerInNewTab" in RootNodeProvider.getRootNode().dataset;
		environment = RootNodeProvider.getRootNode().dataset.environment ? RootNodeProvider.getRootNode().dataset.environment : "default";
		defaultBrand = RootNodeProvider.getRootNode().dataset.defaultBrand ? RootNodeProvider.getRootNode().dataset.defaultBrand : null;
		defaultLocation = RootNodeProvider.getRootNode().dataset.defaultLocation ? RootNodeProvider.getRootNode().dataset.defaultLocation : null;
	}

	return {
		brandInstances: [],
		dealerId,
		dealerDetails: null,
		disclaimer: null,
		environment,
		garageId,
		garageGroupId,
		dealerLanguage: null,
		moduleLanguage: preferredLanguage || navigator.language || "en",
		name: null,
		role,
		theme: "default",
		trackingProperties: {
			trackerLanguage: null,
			analyticsTrackers: [],
			consentlessTrackers: []
		},
		trackerLoaded: false,
		consentlessTrackerLoaded: false,
		configLoaded: false,
		isShopEnabled: false,
		isLeadModuleEnabled: false,
		productPricesDisabled: true,
		watchlistEnabled: false,
		openDealerShopsInNewTab,
		configuredCountries: [],
		isLoading: false,
		legalTerms: "",
		revocationTerms: "",
		privacyPolicy: "",
		imprintText: "",
		contrastColor: "#ffffff",
		defaultBrand,
		defaultLocation,
	};

};

const configSlice = createSlice({
	name: "config",
	initialState: getInitialState(),
	reducers: {
		moduleInit() {
			return getInitialState();
		},
		configSet(state, action) {
			state.isShopEnabled = action.payload.isShopEnabled;
			state.configuredCountries = action.payload.configuredCountries && action.payload.configuredCountries.length > 0
				? action.payload.configuredCountries
				: [defaultCountry];
			state.isLeadModuleEnabled = action.payload.isLeadModuleEnabled;
			state.watchlistEnabled = state.role !== RoleTypes.DISTRIBUTOR && action.payload.watchlistEnabled;
			state.role = state.role === RoleTypes.DISTRIBUTOR ? RoleTypes.DISTRIBUTOR : (state.garageGroupId ? RoleTypes.GROUP_DEALER : RoleTypes.DEALER);
			state.productPricesDisabled = action.payload.productPricesDisabled;
			state.dealerId = action.payload.dealerId;
			state.dealerDetails = action.payload.dealerDetails;
			state.disclaimer = action.payload.disclaimer;
			state.garageId = action.payload.garageId || garageId;
			state.dealerLanguage = action.payload.dealerLanguage;
			state.moduleLanguage = selectLanguage(preferredLanguage, action.payload.availableLanguages);
			state.name = action.payload.name;
			state.theme = action.payload.theme;
			state.configLoaded = true;
			state.trackingProperties = action.payload.trackingProperties;
			state.legalTerms = action.payload.legalTerms;
			state.revocationTerms = action.payload.revocationTerms;
			state.privacyPolicy = action.payload.privacyPolicy;
			state.imprintText = action.payload.imprintText;
			state.imprintText = action.payload.imprintText;
			state.defaultBrand = null;
		},
		consentGoogleAnalyticsRevoked(state) {
			state.trackerLoaded = false;
		},
		configDisabled(state) {
			state.configLoaded = false;
		},
		brandInstanceGotten(state, action) {
			state.brandInstances = action.payload;
		},
		brandInstanceErrorGotten(state) {
		},
		trackerSuccessLoaded(state) {
			state.trackerLoaded = true;
		},
		trackerWithoutConsentSuccessLoaded(state) {
			state.consentlessTrackerLoaded = true;
		},
		contrastColorCalculated(state, action) {
			state.contrastColor = action.payload;
		},
		configErrorFetched(state, action) {}
	}
});

export const {
	configSet,
	contrastColorCalculated,
	trackerWithoutConsentSuccessLoaded,
	trackerSuccessLoaded,
	brandInstanceGotten,
	configDisabled,
	consentGoogleAnalyticsRevoked,
	moduleInit,
	brandInstanceErrorGotten,
	configErrorFetched
} = configSlice.actions;

export default configSlice.reducer;
