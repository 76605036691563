import Payment from "../../../components/checkout/mainContentBox/Payment";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import {SHIPPING_TYPES} from "../../../models/ShippingTypes";
import {getPaymentProvider, initiatePayment} from "../../../actions/order";
import type {PaymentMethod} from "../../../api/PaymentApi";
import {CouponState} from "../../../reducers/couponReducer";
import type {OrderItemsResponse} from "../../../models/Order";
import {PAYMENT_PROVIDER} from "../../../models/PaymentRequests";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	const items = state.cart.items;
	const isAllShipping = !(items.some(item => item.shippingMethod !== SHIPPING_TYPES.SHIPPING));
	const myOrder: OrderItemsResponse = state.order.order;

	return {
		isAllShipping,
		paymentMethods: state.cart.paymentMethods,
		paymentFacility: state.cart.paymentFacility,
		paymentErrors: state.cart.assignPaymentErrors,
		orderIsCreated: myOrder && myOrder.orderNo,
		orderPayedFullyByCoupon: state.coupon.coupon && state.coupon.coupon.coupon &&
			state.coupon.coupon.couponState && CouponState.isValid(state.coupon.coupon.couponState) &&
			myOrder && myOrder.orderItems && state.order.totalBillingAmount &&
			state.coupon.coupon.coupon.eligibleAmount && state.order.totalBillingAmount.cost <= 0,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPaymentProvider: (method: PaymentMethod) => {
			dispatch(getPaymentProvider(method));
		},
		initPayment: () => {
			dispatch(initiatePayment(PAYMENT_PROVIDER.FREE));
		},
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Payment));
