// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const MgTheme = props => {
    import("./mg.lazy.scss");
    return <>
	    <ContrastColorUpdater/>
	    {props.children}
    </>;
};

export default MgTheme;