import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getProducts} from "../../actions/products";
import Search from "../../components/gridPages/Search";
import {setSearch} from "../../actions/search";
import {resetVehicle, setVehicle} from "../../actions/vehicle";
import type {ApplicationState} from "../../reducers";
import {productsReset} from "../../reducers/contentReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	const URIcomponent = decodeURIComponent(ownProps.match.params.fuzzy1 || ownProps.match.params.fuzzy2) === "undefined" ?
		"" : decodeURIComponent(ownProps.match.params.fuzzy1 || ownProps.match.params.fuzzy2);
	return {
		config: state.config,
		products: state.content.products || [],
		newProducts: state.content.newProducts,
		lastSearch: URIcomponent,
		totalItems: state.content.totalItems,
		currentPage: state.content.currentPage,
		totalPages: state.content.totalPages,
		isLoading: state.content.isLoading,
		hasMore: state.content.products.length < state.content.totalItems || (!state.content.products.length && state.content.totalItems),
		selectedVehicle: state.filter.filter.vehicle,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		dispatchGetNextProducts: loading => {
			if (!loading) {
				dispatch(getProducts());
			}
		},
		setSearch: searchPhrase => {
			dispatch(productsReset());
			dispatch(setSearch(searchPhrase));
			dispatch(getProducts());
		},
		setVehicle: (vehicleSeries: string, vehicleModel: string) => {
			dispatch(setVehicle(vehicleSeries, vehicleModel));
			dispatch(productsReset());
			dispatch(getProducts());
		},
		resetVehicle: () => {
			dispatch(resetVehicle());
			dispatch(productsReset());
			dispatch(getProducts());
		}
	};
}

function mergeProps(propsFromState, propsFromDispatch, ownProps) {
	return {
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		getNextProducts: () => propsFromDispatch.dispatchGetNextProducts(propsFromState.isLoading)
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withTranslation()(Search));
