// @flow
import React, {Component} from "react";
import InfiniteScroll from "react-infinite-scroller";
import CarSelectorContainer from "../../container/carSelector/CarSelectionBarContainer";
import type {translate} from "../../i18next";
import type Category from "../../models/Category";
import type {Product} from "../../models/Product";
import type {ConfigReducerState} from "../../reducers/configReducer";
import "./Products.scss";
import ShowCaseContainer from "../../container/gridPages/ShowCaseContainer";
import EventEmitter from "../../helper/eventEmitter";
import GridElementContainer from "../../container/gridPages/GridElementContainer";

type ProductsProps = {
	location: any,
	products: Product[],
	getNextProducts: () => void,
	toStartPage: () => void,
	setCategory: Category => void,
	config: ConfigReducerState,
	t: translate,
	currentPage: number,
	totalPages: number,
	category: string,
	categoryLabel: string,
	totalItems: number,
	isLoading: boolean,
	hasMore: boolean,
	resetVehicle: () => void,
	selectedVehicle: string,
	setVehicle: (string, string) => void,
	markers: [],
	setMarker: () => void
}

class Products extends Component<ProductsProps> {
	constructor(props: ProductsProps) {
		super(props);
		props.setCategory(props.category);
		this.state = {
			category: props.category
		};
	}

	componentDidMount() {
		this.props.setMarker(this.props.markers);
		this.props.getNextProducts();
		new EventEmitter().emitEvent("HPM_PAGE_LOADED");
	}

	componentDidUpdate() {
		if (this.state.category !== this.props.category) {
			this.props.setMarker(this.props.markers);
			this.props.setCategory(this.props.category);
			this.props.getNextProducts();
			this.setState({...this.state, category: this.props.category});
			new EventEmitter().emitEvent("HPM_PAGE_LOADED");
		}
	}

	loadMoreProducts = () => {
		if (this.props.hasMore) {
			this.props.getNextProducts();
		}
	};

	render() {
		const allProductsLoaded = this.props.products.length >= this.props.totalItems;
		return <div className="hpm-products">
			<div className="hpm-carContainer">
				<CarSelectorContainer resetVehicle={this.props.resetVehicle} setVehicle={this.props.setVehicle}/>
			</div>
			<ShowCaseContainer showTileFallback={true} selectedCategoryKey={this.props.category}/>
			<div className="hpm-categoryContainer">
				<div className="hpm-categoryTitle" onClick={this.props.toStartPage}>{this.props.categoryLabel}</div>
				<div className="hpm-gridList">
					<InfiniteScroll
						pageStart={0}
						initialLoad={false}
						dataLength={this.props.totalItems}
						loadMore={this.props.getNextProducts}
						hasMore={false}
						loader={<React.Fragment/>}
						threshold={250}
					>
						{
							this.props.products.map(product => {
								return <GridElementContainer	key={product.id} product={product}/>;
							})
						}
					</InfiniteScroll>
					{allProductsLoaded && (
						<div className="total-items">
							{`${this.props.totalItems} ${this.props.t("category.allProductsLoaded")}`}
						</div>
					)}
					{!allProductsLoaded && (
						<button className={"show-more-products"} onClick={this.loadMoreProducts}>
							{this.props.t("category.showAll")}
						</button>
					)}
				</div>
			</div>
		</div>;
	};
}

export default Products;
