// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {ProductEnquiryRequest, ProductEnquiryResponse} from "../models/ProductEnquiry";
import type {WishlistSharingRequest} from "../models/WishlistSharing";
import type {ProductShareRequest} from "../models/ProductSharing";
import type {Captcha} from "../models/Captcha";

export type NotificationProps = {
	captchaId: string,
	captchaSolution: string,
}

export default class NotificationApi {
	api: Axios;

	constructor({ captchaId = null, captchaSolution = null }: NotificationProps = {}) {
		this.api = axios.create({
			baseURL: Configuration.value("apiNotificationUrl"),
			headers: {
				"Content-Type": "application/json",
				"X-ModuleType": "HPM",
				"X-Challenge": captchaId,
				"X-Captcha": captchaSolution,
			},
		});
	}

	getCaptcha = (): Promise<Captcha> => {
		return this.api.get("/captcha/generate", {responseType: "arraybuffer"})
			.then(response => {
				return {
					captchaId: response.headers["x-challenge"],
					base64: "data:" + response.headers["content-type"] + ";base64," + Buffer.from(response.data).toString("base64"),
					captchaSolution: "",
				};
			})
			.catch(e => {
				/* eslint-disable */
				console.log(e);
			});
	};

	createEnquiry = (enquiry: ProductEnquiryRequest): ProductEnquiryResponse => {
		return this.api.post("/enquiry/send", enquiry)
			.then(response => response.data);
	};

	shareProduct = (sharing: ProductShareRequest): ProductEnquiryRequest => {
		return this.api.post("/sharing/send", sharing)
			.then(response => response.data);
	};

	shareWishlist = (wishlist: WishlistSharingRequest): WishlistSharingRequest => {
		return this.api.post("/wishlist/send", wishlist)
			.then(response => response.data);
	}
}
