// @flow
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import "./styles/style.scss";
import LangSwitchProvider from "./container/LangSwitchProvider";
import "./App.scss";
import App from "./App";
import BrowserNotSupported from "./components/BrowserNotSupported/BrowserNotSupported";
import ToastifyContainer from "./container/ToastifyContainer";
import RootNodeProvider from "./helper/RootNodeProvider";
import PreviewApp from "./preview/App";
import DealerFinderApp from "./dealerfinder/App";


const shouldRenderInstantly = RootNodeProvider.getRootNode() && !(RootNodeProvider.getRootNode().dataset.delayed !== undefined);
const isInternetExplorer = !!document.documentMode;

const render = () => {
	const root = createRoot(RootNodeProvider.getRootNode());

	root.render(
		<Provider store={store}>
			<LangSwitchProvider>
				<ToastifyContainer/>
				{isInternetExplorer
					? <BrowserNotSupported/>
					: <App/>
				}
			</LangSwitchProvider>
		</Provider>
	);
};

if (shouldRenderInstantly) {
	render();
} else window["renderHpm"] = render;

if (RootNodeProvider.getPreviewRootNode()) {
	const previewRootNode = createRoot(RootNodeProvider.getPreviewRootNode());
	previewRootNode.render(
		<Provider store={store}>
			{isInternetExplorer
				? <BrowserNotSupported/>
				: <PreviewApp/>
			}
		</Provider>
	);
}

if (RootNodeProvider.getDealerFinderRootNode()) {
	const previewRootNode = createRoot(RootNodeProvider.getDealerFinderRootNode());
	previewRootNode.render(
		<Provider store={store}>
			<LangSwitchProvider>
				<ToastifyContainer/>
				{isInternetExplorer
					? <BrowserNotSupported/>
					: <DealerFinderApp/>
				}
			</LangSwitchProvider>
		</Provider>
	);
}
