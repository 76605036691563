// @flow

import React, {useEffect} from "react";
import Loading from "../layout/Loading";
import type BrandInstance from "../../models/Brand";
import {withTranslation} from "react-i18next";
import type {translate} from "../../i18next";
import "./BrandInstance.scss";
import Placeholder2x1 from "../../assets/ph2x1.png";
import type {GarageDealership} from "../../models/GarageDealership";

export type BrandInstanceProps = {
	shouldRender: boolean,
	brandInstances: GarageDealership[],
	autoselectedInstance: GarageDealership,
	disableConfig: () => void,
	selectBrand: number => void,
	t: translate,
}

const BrandInstancePage = ({autoselectedInstance, brandInstances, selectBrand, shouldRender, t}: BrandInstanceProps) => {

	useEffect(() => {
		if (autoselectedInstance) {
			selectBrand(autoselectedInstance);
		}
	}, [autoselectedInstance, selectBrand]);

	const renderBrands = (brands: BrandInstance[]) => {
		return brands.map((brand,index) => {
			const brandLogo = {
				backgroundImage: `url(${brand.manufacturerLogoUrl})`
			};
			const brandSize = {
				flex: brands.length <= 4
					? "0 0 45%"
					: brands.length <= 6
						? "0 0 30%"
						: "unset"
			};
			return (
				<div key={index} className="hpm-brand" role={"button"}
					 onClick={() => selectBrand(brand)} style={brandSize}>
					<img src={Placeholder2x1} alt=""/>
					<div className="hpm-brandLogo" style={brandLogo}/>
				</div>
			);
		});
	};

	return shouldRender
		? <div className="hpm-brand-instance">
			<div className="hpm-brand-title">{t("brandSelector.title")}</div>
			<div className="hpm-brands">
				{renderBrands(brandInstances)}
			</div>
		</div>
		: <Loading/>;
};

export default withTranslation()(BrandInstancePage);
