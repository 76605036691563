// @flow
import {Handles, Rail, Slider, Ticks, Tracks} from "react-compound-slider";
import {Handle, Tick, Track} from "./SliderComponents";
import React from "react";
import type {PriceRange} from "../../../../models/PriceRange";

export const SLIDER_MODE = {
	HANDLE_CAN_CROSS: 1,
	HANDLE_CANT_CROSS: 2,
	HANDLE_PUSH_EACHOTHER: 3
};
export const SLIDER_STEP = {
	ONE: 1,
	TEN: 10,
};
export const APPROXIMATED_TICK_AMOUNT = {
	SIX: 6,
};

export const BORDER_RANGE = {
	MIN: 0,
};

export type SliderMainProps = {
	border: PriceRange,
	values: PriceRange,
	onUpdate: number[] => void,
	onChange: number[] => void,
	mode: number,
	step: number,
	count: number,
}

export const SliderMain = (props: SliderMainProps) => {
	return (
		<Slider domain={[BORDER_RANGE.MIN, props.border.to]}
			values={[props.values.from, props.values.to]}
			mode={props.mode} step={props.step}
			onChange={values => props.onChange(values)}
			id="hpmt-priceSlider" className="hpm-sliderStyle"
		>
			<Rail>
				{({getRailProps}) => (
					<div className="hpm-railStyle" {...getRailProps()}/>
				)}
			</Rail>
			<Handles>
				{({handles, getHandleProps}) => (
					<div className="slider-handles">
						{handles.map(handle => (
							<Handle key={handle.id} handle={handle} getHandleProps={getHandleProps}/>
						))}
					</div>
				)}
			</Handles>
			<Tracks right={false} left={false}>
				{({tracks, getTrackProps}) => (
					<div className="slider-tracks">
						{tracks.map(({id, source, target}) => (
							<Track
								key={id}
								source={source}
								target={target}
								getTrackProps={getTrackProps}
							/>
						))}
					</div>
				)}
			</Tracks>
			<Ticks count={props.count}>
				{({ticks}) => (
					<div className="hpm-ticksStyle">
						{ticks.map(tick => (
							<Tick key={tick.id} tick={tick} count={ticks.length}/>
						))}
					</div>
				)}
			</Ticks>
		</Slider>
	);
};

export default SliderMain;
