import {loadConfig} from "./config";
import {resetVehicle} from "./vehicle";
import BrandInstanceStorage from "../helper/storage/BrandInstanceStorage";
import type {ApplicationState} from "../reducers";
import {RoleTypes} from "../models/Role";
import ProductApi from "../api/ProductApi";
import {brandInstanceErrorGotten, brandInstanceGotten} from "../reducers/configReducer";

export const GET_BRAND_INSTANCE_ERROR = "GET_BRAND_INSTANCE_ERROR";

export const getBrandInstances = configId => {
	return (dispatch, getState: () => ApplicationState) => {
		const {role} = getState().config;
		const api = new ProductApi(configId, {configIdType: role});
		return api.getBrands().then(
			response => dispatch(brandInstanceGotten(response)),
			error => dispatch(brandInstanceErrorGotten())
		);
	};
};

export const setBrandInstance = brand => {
	const Storage = new BrandInstanceStorage("garageID");
	Storage.set(brand.dealerId, brand.dealerId);

	return (dispatch, getState: () => ApplicationState) => {
		const role = getState().config.garageGroupId ? RoleTypes.GROUP_DEALER : RoleTypes.DEALER;
		dispatch(resetVehicle());
		dispatch(loadConfig(brand.dealerId, role, getState().config.garageGroupId));
	};
};
