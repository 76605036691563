import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import WishlistShareForm from "../../components/wishlist/WishlistShareForm";
import type {WishlistSharing} from "../../models/WishlistSharing";
import {resetMailing, submitWishlist} from "../../actions/mailing";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		products: state.bookmarks.products,
		contactData: state.mailing.contactData,
		sendSuccess: state.mailing.wishlistSuccess,
		sendError: state.mailing.wishlistError,
		captchaInvalid: state.mailing.invalidCaptcha,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		submitWishlist: (wishlist: WishlistSharing) => dispatch(submitWishlist(wishlist)),
		resetMailing: () => dispatch(resetMailing())
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WishlistShareForm));
