// @flow
import React from "react";
import type {PriceRange} from "../../../models/PriceRange";
import SliderMain, {APPROXIMATED_TICK_AMOUNT, SLIDER_MODE, SLIDER_STEP} from "./Slider/SliderMain";
import type {translate} from "../../../i18next";
import "./Range.scss";

export type RangeProps = {
	t: translate,
	border: PriceRange,
	defaultValue: PriceRange,
	setFilter: (PriceRange) => void,
	setFilterInstance: (?number) => void
};

class Range extends React.Component <RangeProps, any> {
	handleSliderChange = (values: number[]) => {
		const [from: number, to: number] = values;
		if (from <= to) {
			const newValues = {from, to};
			this.props.setFilter(newValues);
		}
	};

	updateValue = (field: string, input: number) => {
		const newInputs = {...this.props.defaultValue, [field]: input};
		this.props.setFilter(newInputs);
	};

	render() {
		return <div>
			<table className="hpm-priceRange">
				<tbody>
					<tr>
						<td colSpan="5" className="hpm-placeholderTd"/>
					</tr>
					<tr>
						<td className="hpm-center">{this.props.t("sort.from")}:</td>
						<td>
							<div className="hpm-center hpm-currencyInput">
								<input className="hpm-center" type="number" id="hpmt-minInput"
									disabled={true}
									value={this.props.defaultValue.from}
								/>
								<div className={"hpm-currencyField"}>{this.props.border.unit}</div>
							</div>
						</td>
						<td className="hpm-center">{this.props.t("sort.to")}:</td>
						<td>
							<div className="hpm-center hpm-currencyInput">
								<input className="hpm-center" type="number" id="hpmt-maxInput"
									disabled={true}
									value={this.props.defaultValue.to}
								   onChange={e => this.updateValue("to", e.target.value)}
								/>
								<div className={"hpm-currencyField"}>{this.props.border.unit}</div>
							</div>
						</td>
						<td>
						</td>
					</tr>
					<tr>
						<td colSpan="5">
							<SliderMain border={this.props.border} values={this.props.defaultValue}
								count={APPROXIMATED_TICK_AMOUNT.SIX}
								mode={SLIDER_MODE.HANDLE_CAN_CROSS} step={SLIDER_STEP.ONE}
								onChange={this.handleSliderChange}
								className="hpm-sliderStyle"
							/>
						</td>
					</tr>
					<tr>
						<td colSpan="5" className="hpm-placeholderTd"/>
					</tr>
				</tbody>
			</table>
		</div>;
	}
}

export default Range;
