import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getProductOnLoad} from "../../actions/products";
import DetailpageLoad from "../../components/productDetail/Detailpage";

function mapStateToProps(state, ownProps) {
	return {
		articleNumber: ownProps.match.params.articleNumber1 || ownProps.match.params.articleNumber2,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getProduct: articleNumber => {
			dispatch(getProductOnLoad(articleNumber));
		}
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(DetailpageLoad);
