// @flow

import React, {Component} from "react";
import type {Item} from "../../../models/Item";
import {getPrice, getRRP} from "../../../models/Item";
import {currencyConverter} from "../../../helper/currency";
import {Link} from "react-router-dom";
import type {translate} from "../../../i18next";
import type {Product} from "../../../models/Product";
import "./CartItem.scss";

export type CartItemProps = {
	t: translate,
	removeFromCart: (Product) => void,
	setQuantity: (string, any) => void,
	cartItem: Item
}

class CartItem extends Component<CartItemProps> {
	constructor(props: CartItemProps) {
		super(props);
		this.setQuantity = this.setQuantity.bind(this);
		this.renderPrice = this.renderPrice.bind(this);
	}

	setQuantity(event) {
		this.props.setQuantity(this.props.cartItem.product.id, event.target.value);
	}

	renderPrice() {
		const price = getPrice(this.props.cartItem);
		const recommendedRetailPrice = getRRP(this.props.cartItem);
		const unitPrice = this.props.cartItem.product.unitPrice;


		if (recommendedRetailPrice) {
			return <span>
				<span>{this.props.t("cart.productInformation.productPriceLabel")}: </span>
				<span className={"hpm-price"}>{currencyConverter(recommendedRetailPrice)}</span>
				<span className={"cart-price"}>{currencyConverter(price)}</span>
				{unitPrice && (
					<div className="unit-price">
						<small>({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
						</small>
					</div>
				)}
			</span>;
		} else {
			return <div className={"cartItem-price"}>
				<div>{this.props.t("cart.productInformation.productPriceLabel")}:
					<span className={"hpm-discount cart-price price-without-retailPrice"}>
						{price ? currencyConverter(price) : this.props.t("productDetail.priceOnRequest")}
					</span>
				</div>
				<div>
					{unitPrice && (
						<span className="unit-price">
							<small>({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
							</small>
						</span>
					)}
				</div>
			</div>;
		}
	}

	render() {
		const detailPageURI = `/product/${this.props.cartItem.product.id}/${encodeURIComponent(this.props.cartItem.product.name)}`;
		return (
			<div className="hpm-cartItem">
				{(this.props.cartItem.product.galleryImage && this.props.cartItem.product.galleryImage.previewImage) &&
					<div className="hpm-cartImage">
						<Link to={detailPageURI}>
							<img alt="" src={this.props.cartItem.product.galleryImage.previewImage.attachmentUrl}/>
						</Link>
					</div>
				}
				<div className="hpm-tables">
					<table className={"hpm-productName"}>
						<tbody>
							<tr>
								<td colSpan={2}>
									<Link to={detailPageURI}>
										{this.props.cartItem.product.name}
									</Link></td>
							</tr>
							<tr>
								<td>
									<span>{this.props.t("cart.productInformation.articleNumberLabel")}:</span> {this.props.cartItem.product.articleNumber}
								</td>
								<td>
									<span>{this.props.t("cart.productInformation.amountLabel")}:</span>
									<input
										type="number"
										value={this.props.cartItem.amount}
										onChange={this.setQuantity}/>
								</td>
							</tr>
							<tr>
								<td>
									<span>{this.props.t("cart.productInformation.shippingTypeLabel")}:</span> {this.props.t("cart.productInformation." + this.props.cartItem.shippingMethod)}
								</td>
								<td>
									{this.renderPrice()}
								</td>
								<td rowSpan={3}><div className="hpm-delete" onClick={() => this.props.removeFromCart(this.props.cartItem.product)}/></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default CartItem;
