// @flow
import React from "react";
import type ProductGroupModel from "../models/ProductGroup";
import SwimLane from "./SwimLane";
import "./ProductGroup.scss";
import type {translate} from "../i18next";
import {withTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import {scrollToTop} from "../helper/anchor";
import history from "../history";
import {Link} from "react-router-dom";
import GridElementContainer from "../container/gridPages/GridElementContainer";

type ProductGroupProps = {
	setCategory: string => void,
	t: translate,
	productGroup: ProductGroupModel,
};

const ProductGroup = (props: ProductGroupProps) => {
	const {name = String.fromCharCode(160)} = props.productGroup.category;
	const redirectUrl = history.getCategoryUrl(name);

	const className = props.productGroup.category.subTitle ? "hpm-productGroup" : "hpm-productGroup noSubtitle";
	const categoryTitle = props.productGroup.category.title === "SALE"
		? props.t("sort.sale")
		: props.productGroup.category.title;
	return (
		<React.Fragment>
			<Link to={redirectUrl} onClick={() => scrollToTop()} className={className} >
				<span>
					{categoryTitle}
					<span>{props.productGroup.category.subTitle}</span>
				</span>
				<span>
					{props.t("category.all", {count: props.productGroup.category.count})}
				</span>
			</Link>
			<SwimLane
				childrenCount={props.productGroup.products.length}
				childrenWidth={200}
				childrenPadding={24} id={uuidv4()}>
				{props.productGroup.products.map(product => <GridElementContainer key={uuidv4()} product={product}/>)}
				{props.productGroup.products.length < props.productGroup.category.count
					? <a onClick={() => props.setCategory(props.productGroup.category.key)} className={"hpm-gridElement hpm-gridCategory-btn"}>
						<div className={"hpm-center"}>
							{props.t("category.showAll")}
						</div>
					</a>
					: null
				}
			</SwimLane>
		</React.Fragment>
	);
};

export default withTranslation()(ProductGroup);
