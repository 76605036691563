// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const CitroenTheme = props => {
	import("./citroen.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default CitroenTheme;
