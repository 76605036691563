// @flow

import type {Product} from "./Product";
import type {Captcha} from "./Captcha";

export const WAY_TO_CONTACT = {
	viaMail: "mail",
	viaPhone: "phone"
};

export type WayToContact = WAY_TO_CONTACT.viaMail | WAY_TO_CONTACT.viaPhone;

export type ProductEnquiry = {
	consent: boolean,
	mailMyself: boolean,
	name: string,
	email: ?string,
	phone: ?string,
	wayToContact: WayToContact,
	message: string,
	product: Product,
	captcha: Captcha
};

export type ProductEnquiryRequest = {
	mailMyself: boolean,
	customer: {
		name: string,
		email: string,
		phone: string,
		wayToContact: WayToContact,
		language: string,
	},
	message: string,
	productId: string,
	captcha: Captcha
}

export type ProductEnquiryResponse = {}
