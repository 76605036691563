// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const BmwTheme = props => {
	import("./bmw.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default BmwTheme;
