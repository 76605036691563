// @flow

import type Product from "../models/Product";
import BookmarkStorage from "../helper/storage/BookmarkStorage";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";

export type BookmarksReducerState = {
	dealerId: ?string,
	products: Product[],
	isLoading: boolean,
	hasQueryParamProducts: boolean,
	quickCallProduct: ?Product,
	showWelcomeMessage: boolean
};

const initialState = {
	dealerId: null,
	products: [],
	isLoading: false,
	hasQueryParamProducts: false,
	quickCallProduct: null,
	showWelcomeMessage: false
};
const setBookmarks = (bookmarks, state) => {
	const bookmarkStorage = new BookmarkStorage(state.dealerId);
	bookmarkStorage.set(JSON.stringify(bookmarks));
};

const bookmarksSlice = createSlice({
	name: "bookmarks",
	initialState,
	reducers: {
		bookmarkAdded(state, action) {
			const newProducts = [...state.products, action.payload];
			setBookmarks(newProducts, state);

			state.products = newProducts;
		},
		bookmarkRemoved(state, action) {
			const products = state.products.filter(product => action.payload.id !== product.id);
			setBookmarks(products, state);

			state.products = products;
		},
		bookmarkConfigReset(state) {
			state.hasQueryParamProducts = false;
			state.quickCallProduct = null;
		},
		welcomeMessageReset(state) {
			state.showWelcomeMessage = false;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(configSet, (state, action) => {
				state.products = action.payload.bookmarks.products;
				state.dealerId = action.payload.dealerId;
				state.hasQueryParamProducts = action.payload.bookmarks.hasQueryParamProducts;
				state.quickCallProduct = action.payload.bookmarks.quickCallProduct;
				state.showWelcomeMessage = action.payload.bookmarks.hasQueryParamProducts || !!action.payload.bookmarks.quickCallProduct;
			});
	}
});

export const {welcomeMessageReset, bookmarkConfigReset, bookmarkRemoved, bookmarkAdded} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
