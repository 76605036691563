import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {resetCategory, setCategory} from "../../actions/category";
import {resetVehicle, setVehicle} from "../../actions/vehicle";
import {getPopularProducts} from "../../actions/products";
import StartPage from "../../components/gridPages/StartPage";
import type {ApplicationState} from "../../reducers";
import {setFilter} from "../../actions/filter";

function mapStateToProps(state: ApplicationState, ownProps) {
	const qs = new URLSearchParams(ownProps.location.search);
	const category = qs.get("category");
	return {
		isLoading: state.content.isLoading,
		popularProducts: state.content.popularProducts,
		config: state.config,
		selectedVehicle: state.filter.filter.vehicle,
		category: category ? category.replace("/", "") : null,
		salesProducts: state.content.salesProducts
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	const qs = new URLSearchParams(ownProps.location.search);
	const category = qs.get("category");
	return {
		getPopularProducts: () => {
			if (category) dispatch(setCategory([category.replace("/", "")]));
			else dispatch(resetCategory());
			dispatch(getPopularProducts());
		},
		showCategoryProducts: category => {
			const {history} = ownProps;
			history.pushCategory(category);
		},
		setVehicle: (vehicleSeries: string, vehicleModel: string) => {
			dispatch(setVehicle(vehicleSeries, vehicleModel));
			dispatch(getPopularProducts());
		},
		resetVehicle: () => {
			dispatch(resetVehicle());
			dispatch(getPopularProducts());
		},
		setCategory: category => {
			dispatch(setFilter("categories", [category]));
		},
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(StartPage);
