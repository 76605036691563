import BaseTracker from "./BaseTracker";
import type {Tracking} from "../../models/Tracking";
import type {Product} from "../../models/Product";
import {getEtCommerceOrder, getEtCommerceProduct} from "../eTrackerUtil";
import type {OrderReducerState} from "../../reducers/orderReducer";
import type {CouponReducerState} from "../../reducers/couponReducer";

export const trackerName = "slTracker";
const quantity = 1;

export default class ETracker extends BaseTracker implements Tracking {
	constructor(trackingId: string, withEcommerce: boolean = false) {
		super(trackingId, withEcommerce);
	}

	handleAddItemEvent(cat: string, action: string, product: Product) {
		const etCommerceProduct = getEtCommerceProduct(product);
		window.etCommerce.sendEvent("insertToBasket", etCommerceProduct, quantity);
	}

	handleRemoveItemEvent(cat: string, action: string, product: Product) {
		const etCommerceProduct = getEtCommerceProduct(product);
		window.etCommerce.sendEvent("removeFromBasket", etCommerceProduct, quantity);
	}

	handleOrderEvent(order: OrderReducerState, coupon: ?CouponReducerState) {
		const etCommerceOrder = getEtCommerceOrder(order, coupon);
		window.etCommerce.sendEvent("order", etCommerceOrder);
	}

	handleInsertToWatchlistEvent(event, product: Product) {
		const etCommerceProduct = getEtCommerceProduct(product);
		window.etCommerce.sendEvent("insertToWatchlist", etCommerceProduct, quantity);
	}

	handleRemoveFromWatchlistEvent(event, product: Product) {
		const etCommerceProduct = getEtCommerceProduct(product);
		window.etCommerce.sendEvent("removeFromWatchlist", etCommerceProduct, quantity);
	}

	handleViewProductDetailsEvent(event, product: Product) {
		const etCommerceProduct = getEtCommerceProduct(product);
		window.etCommerce.sendEvent("viewProduct", etCommerceProduct, "1");
	};

	handleGenericEvent(cat: string, action: string, label: any): void {
	};

	handleClearCartEvent() {
	}
}
