// @flow

import type {DistributionChannels} from "./DistributionChannels";
import type Price from "./Price";
import type {UnitPrice} from "./UnitPrice";
import {SHIPPING_TYPES} from "./ShippingTypes";
import type {TireLabel} from "./TireLabel";

export type SlImage = {
	url: string,
	name: string,
	attachmentUrl: string,
	previewImage: ?SlImage,
};

export type Product = {
	id: string,
	dealerId: string,
	articleNumber: string,
	name: string,
	description: string,
	distributionChannels: DistributionChannels,
	fitments: string[],
	carlines: string[],
	galleryImage: SlImage,
	vatRate: ?number,
	price: ?Price,
	recommendedRetailPrice: Price,
	unitPrice: ?UnitPrice,
	isAvailable: boolean,
	tireLabel: TireLabel
}

const NO_PRICE_AVAILABLE = "";

export const getGalleryPrice = (product: Product, shippingMethod: ?string) => {
	const {pickup, shipping, mounting} = product.distributionChannels;

	switch (true) {
		case (shippingMethod === SHIPPING_TYPES.SHIPPING && shipping != null):
			return product.distributionChannels.shipping.price;
		case (shippingMethod === SHIPPING_TYPES.MOUNTING && mounting != null):
			return product.distributionChannels.mounting.price;
		case (shippingMethod === SHIPPING_TYPES.PICKUP && pickup != null):
			return product.distributionChannels.pickup.price;
		case (mounting != null):
			return product.distributionChannels.mounting.price;
		case (shipping != null):
			return product.distributionChannels.shipping.price;
		case (pickup != null):
			return product.distributionChannels.pickup.price;
		case (product.price != null):
			return product.price;
		default:
			return NO_PRICE_AVAILABLE;
	}
};
