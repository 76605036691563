// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {Item} from "../models/Item";
import type {Product} from "../models/Product";
import type {Vehicle} from "../models/Vehicle";
import type {SortOrder} from "../models/SortOrder";
import type {LoadConfigResponseType} from "../actions/config";
import type PriceRange from "../models/PriceRange";
import type {GarageDealership} from "../models/GarageDealership";
import type {GroupMember} from "../models/GroupMember";

export type LocationType = {
	latitude: number,
	longitude: number
}

export type FilterParameters = {
	intent: string,
	categories?: string[],
	categoryKey: string,
	markers?: string[],
	priceRange?: PriceRange,
	vehicle?: Vehicle,
	vehicleKey: string,
	productIds?: string[],
}

export type ProductFilterQuery = {
	fuzzy?: string,
	sortOrders?: SortOrder,
	filterParameters: ?FilterParameters,
}

export const emptyFilter = {
	fuzzy: "",
	sortOrder: [{direction: "DESC", fieldname: "score"}],
	filterParameters: null,
};

export type DealerForLocationResponse = {
	dealerAtLocation: {
		identifier: {
			marketplaceNo: { index: string },
			dealerNo: string,
		},
		name: string,
		address: {
			street: string,
			streetNumber: string,
			town: string,
			postalCode: string,
			countryCode: string,
		},
		location: {
			latitude: number,
			longitude: number,
		},
	},
};

export type DealerForBrandResponse = {
	dealersForBrand: {
		identifier: {
			marketplaceNo: { index: string },
			dealerNo: string,
		},
		distance: string,
		name: string,
		address: {
			street: string,
			streetNumber: string,
			town: string,
			postalCode: string,
			countryCode: string,
		},
		location: {
			latitude: number,
			longitude: number,
		},
		garagePictureUrl: string,
		email: string,
		phone: string,
		fax: string,
		shopBaseUrl: string,
	}[],
};

export type LocationOptions = {
	latitude: number,
	longitude: number,
	searchRadius: number,
}

export type ApiOptions = {
	configIdType: string,
	intent: string,
	language: string,
	locationOptions?: LocationOptions,
	pickupLocation?: GroupMember,
}

export const INTENT = {
	DEFAULT: "DEFAULT",
	OWN: "own",
	CATALOG_ONLY: "catalog-only",
};

export default class ProductApi {
	api: Axios;

	constructor(configId: string, {
		configIdType = "DEALER",
		intent = INTENT.DEFAULT,
		language = navigator.language || "en-GB",
		locationOptions,
		pickupLocation
	}: ApiOptions = {}) {
		const headers = {
			"Content-Type": "application/json",
			"X-ConfigId": configId,
			"X-ConfigIdType": configIdType,
			"X-Intent": intent,
			"X-ModuleType": "HPM",
			"Accept-Language": language + ", *;q0.5",
		};
		if (pickupLocation) {
			headers["X-HourlyRateProvider"] = pickupLocation.cfgKey;
		}
		if (locationOptions) {
			if (locationOptions.latitude) headers["X-Latitude"] = locationOptions.latitude;
			if (locationOptions.longitude) headers["X-Longitude"] = locationOptions.longitude;
			if (locationOptions.searchRadius) headers["X-SearchRadius"] = locationOptions.searchRadius;
		}
		this.api = axios.create({
			baseURL: Configuration.value("apiBaseUrl"),
			headers
		});
	}

	getBrands = (): GarageDealership => {
		return this.api.get("/v2/configure")
			.then(response => response.data.garageDealerships);
	};

	getConfig = (): LoadConfigResponseType => {
		return this.api.get("/v2/configure")
			.then(response => response.data);
	};

	getDealerForLocation = (brandName: string, countryCode: string = "DE"): DealerForLocationResponse => {
		return this.api.get("/v2/dealer/forLocation", {
			params: {
				brandName,
				countryCode,
			}
		}).then(response => response.data);
	};

	getDealerForBrand = (brandName: string, countryCode: string = "DE"): Promise<DealerForBrandResponse> => {
		return this.api.get("/v2/dealers/forBrand", {
			params: {
				brandName,
				countryCode,
			}
		}).then(res => res.data);
	};

	getDealerForArticleNo = (articleNos: string[] = []) => {
		return this.api.post("/v2/dealers/forArticleNos", articleNos)
			.then(res => res.data);
	};

	getOverview = (filterOptions: ProductFilterQuery) => {
		const body = {
			fuzzy: filterOptions.fuzzy,
			filterParameters: filterOptions.filterParameters
		};

		return this.api.post("/v2/products/overview", body)
			.then(response => response.data);
	};

	getDealersForProduct = (productId: string) => {
		return this.api.get(`/v2/dealers/forProduct?productId=${productId}`).then(response => response.data);
	};

	validateCart = (items: Item[] = []): Item[] => {
		const defaultPage = 0;
		const defaultPageSize = 1000;
		const productIds = items.map(item => item.product.id);

		const body = {
			productIds
		};

		return this.api.post(`/v2/products/search?page=${defaultPage}&size=${defaultPageSize}`, body)
			.then(response => response.data.pagedProducts.productItems)
			.catch(() => []);
	};

	getProductsWithFilter = (currentPage: number, filterOptions: ProductFilterQuery = emptyFilter) => {
		const body = {
			fuzzy: filterOptions.fuzzy,
			sortOrders: filterOptions.sortOrders,
			filterParameters: filterOptions.filterParameters
		};
		return this.api.post(`/v2/products/search?page=${currentPage}&size=25`, body)
			.then(response => response.data);
	};

	getProductDetailWithFilter = (productId: string, filterParameters: FilterParameters = {}) => {
		const body = {
			filterParameters
		};
		return this.api.post(`/v2/productDetail/${productId}`, body)
			.then(response => response.data);
	};

	validateBookmarks = (bookmarks: Product[] = []): Product[] => {
		const defaultPage = 0;
		const defaultPageSize = 1000;

		const productIds = bookmarks.map(bookmark => bookmark.id);

		const body = {
			productIds
		};

		return this.api.post(`/v2/products/search?page=${defaultPage}&size=${defaultPageSize}`, body)
			.then(response => response.data.pagedProducts.productItems)
			.catch(() => []);
	};

	getProductByArticleNo = (articleNos: string[] = []) => {
		const defaultPage = 0;
		const defaultPageSize = 1000;
		const body = {
			articleNos
		};
		return this.api.post(`/v2/products/search?page=${defaultPage}&size=${defaultPageSize}`, body)
			.then(response => response.data.pagedProducts.productItems)
			.catch(() => []);
	};
}
