// @flow

import type {Brand} from "../models/Brand";
import type ProductGroup from "../models/ProductGroup";
import type Category from "../models/Category";
import type {Product} from "../models/Product";
import type {Attachments} from "../models/Attachments";
import type Property from "../models/Property";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";

export type ContentReducerState = {
	brand: Brand,
	isLoading: boolean,
	categories: Category[],
	popularProducts: ProductGroup[],
	products: Product[],
	productLoaded: boolean,
	product: ?Product,
	attachments: ?Attachments,
	properties: ?Property[],
	relatedProducts: ?Product[],
	currentPage: number,
	totalPages: number,
	totalItems: number,
	availableOnOtherDealersCount: number,
	showDealerList: boolean,
	salesProducts: ProductGroup
}

const initialState: ContentReducerState = {
	brand: null,
	isLoading: false,
	categories: [],
	popularProducts: [],
	products: [],
	productLoaded: false,
	product: null,
	attachments: null,
	properties: null,
	relatedProducts: null,
	currentPage: 0,
	totalPages: 0,
	totalItems: 0,
	availableOnOtherDealersCount: 0,
	showDealerList: false,
	salesProducts: null
};

const contentSlice = createSlice({
	name: "content",
	initialState,
	reducers: {
		pageReset(state) {
			state.currentPage = 0;
		},
		productsReset(state, action) {
			state.products = [];
			state.currentPage = 0;
			state.totalPages = 0;
		},
		productsRequested(state) {
			state.isLoading = true;
		},
		productsSet(state, action) {
			const newProducts = action.payload.products.filter(product => !state.products.some(stateProduct => stateProduct.id === product.id));
			state.products = [...state.products, ...newProducts];
			state.totalItems = action.payload.totalItems;
			state.totalPages = action.payload.totalPages;
			state.isLoading = false;
			state.currentPage = state.currentPage + 1;
		},
		productsErrorGotten(state) {
			state.isLoading = false;
		},
		productSet(state, action) {
			action.payload.product.tireLabel = action.payload.tireLabel;
			state.availableOnOtherDealersCount = action.payload.availableOnOtherDealersCount;
			state.properties = action.payload.properties;
			state.attachments = action.payload.attachments;
			state.relatedProducts = action.payload.relatedProducts;
			state.product = action.payload.product;
			state.productLoaded = true;
		},
		productReset(state, action) {
			state.product = [];
			state.productLoaded = false;
			state.showDealerList = action.payload;
		},
		popularProductsRequested(state) {
			state.isLoading = true;
		},
		popularProductsErrorGotten(state) {
			state.isLoading = false;
		},
		popularProductsSuccessGotten(state, action) {
			state.popularProducts = action.payload.popularProducts;
			state.salesProducts = action.payload.salesProducts;
			state.totalItems = action.payload.popularProducts.map(pack => pack.category.count).reduce((a, b) => a + b, 0);
			state.isLoading = false;
		},
		showDealerListSet(state, action) {
			state.showDealerList = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(configSet, (state, action) => {
				state.brand = action.payload.brand;
				state.categories = action.payload.categories;
			});
	}
});

export const {
	productSet,
	pageReset,
	popularProductsErrorGotten,
	popularProductsRequested,
	popularProductsSuccessGotten,
	productReset,
	productsErrorGotten,
	productsReset,
	showDealerListSet,
	productsRequested,
	productsSet
} = contentSlice.actions;

export default contentSlice.reducer;
