// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";
import React from "react";

const SkodaTheme = props => {
	import("./skoda.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default SkodaTheme;
