// @flow

import BaseStorage from "./BaseStorage";
import type Memorizing from "../../models/Memorizing";

const HPM_CART: string = "-hpmcart";

export default class CartStorage extends BaseStorage implements Memorizing<string, ?string> {
	constructor(dealerId: string) {
		super(dealerId, HPM_CART);
	}
}
