// @flow
import React from "react";

export type HandleProps = {
	handle: {
		id: number,
		percent: number
	},
	getHandleProps: number => any
}

export const Handle = (props: HandleProps) => {
	const { id, percent } = props.handle;
	return (
		<div className="hpm-handleStyle"
			 style={{left: `${percent}%`}}
			 {...props.getHandleProps(id)}
		/>
	);
};

export type TrackProps = {
	source: { percent: number },
	target: { percent: number },
	getTrackProps: () => any,
}

export const Track = (props: TrackProps) => {
	return (
		<div className="hpm-trackStyle"
			 style={{ left: `${props.source.percent}%`, width: `${props.target.percent - props.source.percent}%`, }}
			 {...props.getTrackProps()}
		/>
	);
};

export type TickProps = {
	tick: {
		percent: number,
		value: number
	},
	count: number
}

export const Tick = (props: TickProps) => {
	const { tick, count } = props;
	return (
		<div>
			<div className="hpm-ticksLine"
				 style={{
					 left: `${tick.percent}%`,
				 }}
			/>
			<div className="hpm-ticksValue"
				 style={{
					 marginLeft: `${-(100 / count) / 2}%`,
					 width: `${100 / count}%`,
					 left: `${tick.percent}%`,
				 }}
			>
				{tick.value}
			</div>
		</div>
	);
};
