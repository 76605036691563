import {connect} from "react-redux";
import type {ApplicationState} from "../../../reducers";
import {resetMailing, submitProductEnquiry} from "../../../actions/mailing";
import {withTranslation} from "react-i18next";
import QuestionForm from "../../../components/productDetail/QuestionForm/QuestionForm";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		contactData: state.mailing.contactData,
		sendSuccess: state.mailing.enquirySuccess,
		sendError: state.mailing.enquiryError,
		captchaInvalid: state.mailing.invalidCaptcha,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		submitEnquiry: enquiry => {
			dispatch(submitProductEnquiry(enquiry));
		},
		resetMailing: () => dispatch(resetMailing())
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QuestionForm));
