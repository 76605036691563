// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const SmartTheme = props => {
	import("./smart.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default SmartTheme;
