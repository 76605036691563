// @flow

import * as React from "react";
import "./DealerList.scss";
import GarageDetailContainer from "../../../container/productDetail/NSC/GarageDetailContainer";
import type {Product} from "../../../models/Product";
import NscProductView from "./NscProductView";

type Props = {
	onClose: () => void,
	product: Product,
	t: string => string,
	theme: string,
};

export const DealerList = (props: Props) => {
	return <div className={"hpm-dealerList"}>
		<div>
			<NscProductView product={props.product} close={props.onClose}/>
			<GarageDetailContainer/>
		</div>
	</div>;
};
