import React from "react";
import Spinner from "react-spinkit";

const Loading = props => {
	const style = {
		height: window.innerHeight
	};

	return (
		<div className="hpm-loadingSpinner" style={style}><Spinner name="ball-beat"/></div>
	);
};

export default Loading;
