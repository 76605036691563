// @flow

// const x = [{a: 1, b: 2}];
// const y = [{a: 1, b: 2}, {a: 2, b: 3}];
//
// [...x, ...y] = [{a: 1, b: 2}, {a: 1, b: 2}, {a: 2, b: 3}]
// mergeToUniqueObjArray(x, y, "a") = [{a: 1, b: 2}, {a: 2, b: 3}]
export const mergeToUniqueObjArray = (array1: [], array2: [] = [], key: string = "id") => {
	return [
		...new Map([...array1, ...array2]
			.map(item => [item[key], item]))
			.values(),
	];
};
