import React from "react";
import {withRouter} from "react-router";
import type {ContextRouter} from "react-router";

export const LANGUAGE_NOT_SUPPORTED_BY_CATALOG = "LANGUAGE_NOT_SUPPORTED_BY_CATALOG";

type Props = {
	location: { search: string, pathname: string },
	history: any,
};
const removeOldLanguage = path => {
	return path.substring(1).substring(path.substring(1).indexOf("/"));
};

const EventHandler = ({location, history}: Props & ContextRouter) => {
	if (window && !window.LANGUAGE_NOT_SUPPORTED_BY_CATALOG) {
		window.LANGUAGE_NOT_SUPPORTED_BY_CATALOG = true;
		window.addEventListener(LANGUAGE_NOT_SUPPORTED_BY_CATALOG, event => {
			history.push(
				"/" + event.detail.allowedLanguage + removeOldLanguage(location.pathname) + location.search + location.hash
			);
		});
	}
	return <div/>;
};

export default withRouter(EventHandler);
