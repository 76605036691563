import {connect} from "react-redux";
import {getFilterStats, setFilter, setFilterInstance} from "../../actions/filter";
import FilterPagePriceRange from "../../components/Filter/FilterPagePriceRange";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	const isPriceRangeInvalid = value => {
		return (value === "Infinity" || value === "-Infinity");
	};
	const defaultPriceRange = {
		from: 0,
		to: 1000,
		unit: "EUR"
	};
	return {
		filter: state.filter.filter,
		priceBorders: {
			from: isPriceRangeInvalid(state.filter.filterStats.priceRange.from)
				? defaultPriceRange.min
				: Math.floor(state.filter.filterStats.priceRange.from),
			to: isPriceRangeInvalid(state.filter.filterStats.priceRange.to)
				? defaultPriceRange.max
				: Math.ceil(state.filter.filterStats.priceRange.to),
			unit: state.filter.filterStats.priceRange.unit || defaultPriceRange.unit
		}
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setPriceRange: priceRange => {
			dispatch(setFilter("priceRange", priceRange));
		},
		getPriceFilterStats: filter => {
			dispatch(getFilterStats({...filter, ...{priceRange: null}}));
		},
		setFilterInstance: (instance, subCat) => {
			dispatch(setFilterInstance(instance, subCat));
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPagePriceRange));
