// @flow

import React from "react";
import "./Button.scss";
import SearchIcon from "../icons/SearchIcon";

export type ButtonProps = {
	onClick: () => void,
	disabled: ?boolean,
	name: string,
	children: any,
	style: ?any,
}

const Button = (props: ButtonProps) => {
	return (
		<button
			type={"button"}
			id={`hpm-${props.name}`}
			style={props.style}
			className={"hpm-button hpm-" + props.name} onClick={props.onClick}
			disabled={props.disabled || false}
		>
			{props.name === "search" && <SearchIcon/>}
			{props.children}
		</button>
	);
};

export default Button;
