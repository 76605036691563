// @flow
import React, {useEffect, useState} from "react";
import type {Product} from "../../../models/Product";
import "./QuestionProductView.scss";
import {translate} from "react-i18next";
import {currencyConverter} from "../../../helper/currency";

export type QuestionProductViewProps = {
	product: Product,
	t: translate,
}

const QuestionProductView = (props: QuestionProductViewProps) => {
	const [isVisible, setIsVisible] = useState(true);

	const hideOnSmallScreen = () => {
		const questionContainer = document.querySelector(".hpm-question");
		setIsVisible(questionContainer && questionContainer.offsetWidth > 700);
	};

	useEffect(() => {
		hideOnSmallScreen();
		window.addEventListener("resize", hideOnSmallScreen);
		return () => {
			window.removeEventListener("resize", hideOnSmallScreen);
		};
	}, []);

	return isVisible
		? (props.product && props.product.galleryImage && props.product.galleryImage.attachmentUrl && props.product.name)
			? <div className="hpm-questionProduct">
				<div className={"hpm-productImage"}>
					<img src={props.product.galleryImage.attachmentUrl} alt={props.product.description}/>
				</div>
				<div className={"hpm-productDescription"}>
					<h3>{props.product.name}</h3>
					<div>
						<span dangerouslySetInnerHTML={{__html: props.product.description}}/>
					</div>
				</div>
				<div className={"hpm-productPrice"}>
					<span className={"hpm-priceLabel"}>{props.t("feedback.relatedQuestions.price")}: </span>
					<span className="hpm-priceText">
						{props.product.price ? (<>
							{currencyConverter(props.product.price)}
							{props.product.unitPrice && (
							<div className="unit-price">
								<small>
									({currencyConverter(props.product.unitPrice.pricePerUnit)} / {props.product.unitPrice.measuringUnit === "sqm" ? "m²"
									: props.product.unitPrice.measuringUnit === "cbm" ? "m³" : props.product.unitPrice.measuringUnit})
								</small>
							</div>
								)}
						</>
						) : props.product.recommendedRetailPrice ? (
							currencyConverter(props.product.recommendedRetailPrice)
						) : (
							props.t("productDetail.priceOnRequest")
						)}
					</span>
				</div>
			</div>
			: <div className={"hpm-questionProduct hpm-chat-image"}/>
		: null;
};

export default QuestionProductView;
