import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import {CouponCode} from "../../../components/checkout/dataSummaryBox/CouponCode";
import {resetCoupon, resetCouponToast, setCoupon} from "../../../actions/coupon";
import {getOrderItemPriceSumWithShipping} from "./PriceOverviewContainer";
import type Price from "../../../models/Price";
import {hpmPrice} from "../../../helper/currency";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	return {
		coupon: state.coupon.coupon,
		couponErrorCode: state.coupon.couponErrorCode,
		couponSuccessCode: state.coupon.couponSuccessCode,
		orderItemsPriceSumWithShipping: hpmPrice(
			state.order.orderItemsPriceSumWithShipping ? state.order.orderItemsPriceSumWithShipping : getOrderItemPriceSumWithShipping(state)
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetCoupon: () => dispatch(resetCoupon()),
		setCoupon: (code: string, orderItemsPriceSumWithShipping: Price) => dispatch(setCoupon(code, orderItemsPriceSumWithShipping)),
		resetCouponToast: () => dispatch(resetCouponToast())
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CouponCode));
