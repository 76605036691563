import {createBrowserHistory, createHashHistory} from "history";
import ReactGA from "react-ga4";
import store from "./store/index";

export const canHandleDeeplinks = () => {
	return !!document.querySelector("#app-root[data-seo-deeplinks]");
};

const basePath = () => {
	try {
		const manufacturerName = store.getState().config.dealerDetails.dealership.manufacturerName;
		const isGroupShop = !!store.getState().config.garageId || !!store.getState().config.garageGroupId;
		return (isGroupShop && manufacturerName) ? `/brand/${manufacturerName}` : "";
	} catch {
		return "";
	}
};

const history = canHandleDeeplinks()
	? createBrowserHistory()
	: createHashHistory();

history.listen(function (location) {
	const urlPrefix = canHandleDeeplinks() ? "/#" : "/";
	const path = window.location.href.split(urlPrefix)[1];

	if (store.getState().consent.googleAnalytics && store.getState().config.trackerLoaded) {
		ReactGA.send({
			hitType: "pageview",
			page: path,
			title: document.title
		});
	}
});

history["entry"] = history.length; // history.location.key was removed
history["pushHome"] = () => history.push(basePath() + "/shop");
history["pushSearch"] = (fuzzy = "") => history.push(basePath() + `/search/${encodeURIComponent(fuzzy)}`);
history["pushCheckout"] = () => history.push(basePath() + "/checkout");
history["pushBookmarks"] = () => history.push(basePath() + "/bookmarks");
history["pushCategory"] = (category = "") => history.push(basePath() + `/products/${category}`);
history["pushProduct"] = (productId, name = "") => history.push(basePath() + `/product/${productId}/${encodeURIComponent(name)}`);
history["getProductUrl"] = (productId, name = "") => { return basePath() + `/product/${productId}/${encodeURIComponent(name)}`; };
history["getCategoryUrl"] = (category = "") => basePath() + `/products/${encodeURIComponent(category)}/`;

export default history;
