// @flow
import ReactPiwik from "react-piwik";
import BaseTracker from "./BaseTracker";
import type {Product} from "../../models/Product";
import type {OrderReducerState} from "../../reducers/orderReducer";
import type {CouponReducerState} from "../../reducers/couponReducer";

export default class PiwikTracker extends BaseTracker {
	constructor(trackingId: string, trackingHostUrl: string, withEcommerce: boolean = false) {
		super(trackingId, withEcommerce);
		this.tracker = new ReactPiwik({
			siteId: this.trackingId,
			jsFilename: this.trackingId + ".js",
			url: trackingHostUrl
		});
	}

	handleGenericEvent(cat: string, action: string, label: any) {
		ReactPiwik.push(["trackEvent", `${cat} ${action}`, label]);
	}

	handleOrderEvent(order: OrderReducerState, coupon: ?CouponReducerState = null) {
		ReactPiwik.push(["trackEcommerceOrder", order.order.orderNo, order.totalBillingAmount.cost]);
	}

	handleAddItemEvent(cat: string, label: string, product: Product) {
		ReactPiwik.push(["addEcommerceItem", product.articleNumber, product.name, null, product.price.value, 1]);
	}

	handleRemoveItemEvent(cat: string, label: string, product: Product) {
		ReactPiwik.push(["removeEcommerceItem", product.articleNumber]);
	};

	handleClearCartEvent() {
		ReactPiwik.push(["clearEcommerceCart"]);
	}

	handleViewProductDetailsEvent(event: {}, product: Product) {
		this.handleGenericEvent(event.CATEGORIES.PRODUCT, event.ACTIONS.PRODUCT.PRODUCT_VISITED, product.name);
	}

	handleInsertToWatchlistEvent(event: {}, product: Product) {
		this.handleGenericEvent(event.CATEGORIES.BOOKMARKS, event.ACTIONS.BOOKMARKS.ADD_PRODUCT, product.name);
	}

	handleRemoveFromWatchlistEvent(event: {}, product: Product) {
		this.handleGenericEvent(event.CATEGORIES.BOOKMARKS, event.ACTIONS.BOOKMARKS.REMOVE_PRODUCT, product.name);
	}
}
