import type Category from "../models/Category";
import type {Vehicle} from "../models/Vehicle";
import type PriceRange from "../models/PriceRange";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";
import {popularProductsRequested, popularProductsSuccessGotten, productsSet} from "./contentReducer";

type FilterStats = {
	priceRange: PriceRange,
	vehicleSeries: Vehicle[],
	categories: Category[],
};

type MainOption = {
	type: string,
	name: string,
	target: number,
	subCategory: {}
};

type SortOptions = {
	fieldname: string,
	direction: "ASC" | "DESC"
};

type FilterOption = {
	searchPhrase: string,
	categories: Category[],
	categoryKey: ?string,
	vehicle: ?Vehicle,
	vehicleKey: ?string,
	priceRange: PriceRange,
	sort: SortOptions,
	selectedCategory: string,
	markers: []
};

export type FilterReducerState = {
	searchPhrase: string,
	isDirty: boolean,
	filterInstance: number,
	subCategory: any,
	filter: FilterOption,
	vehicleSeries: Vehicle[],
	filterStats: FilterStats,
	vehicleModels: [],
	mainOptions: MainOption[],
	sortOptions: SortOptions,
	isLoading: boolean,
	activeModal: ?number,
}

const defaultPriceRange = {
	from: 0,
	to: 1000,
};

export const FILTER = {
	MAIN: 1,
	SORT: 2,
	CATEGORIES: 3,
	SUB_CATEGORY: 4,
	CARLINE: 5,
	PRICE_RANGE: 6,
	VEHICLE_MODEL: 7,
};

const initialState: FilterReducerState = {
	searchPhrase: "",
	isDirty: false,
	filterInstance: FILTER.MAIN,
	subCategory: {},
	filter: {
		searchPhrase: "",
		categories: [],
		vehicle: null,
		vehicleKey: null,
		categoryKey: null,
		priceRange: null,
		sort: {
			fieldname: "score",
			direction: "DESC"
		},
		selectedCategory: "",
		markers: []
	},
	filterStats: {
		priceRange: defaultPriceRange,
		vehicleSeries: [],
		categories: [],
	},
	vehicleModels: [],
	mainOptions: [
		{
			type: "menu",
			name: "sort",
			target: FILTER.SORT,
			subCategory: {},
		},
		{
			type: "menu",
			name: "cat",
			target: FILTER.CATEGORIES,
			subCategory: {},
		},
		{
			type: "menu",
			name: "carline",
			target: FILTER.CARLINE,
			subCategory: {},
		},
		{
			type: "menu",
			name: "priceRange",
			target: FILTER.PRICE_RANGE,
		},
	],
	sortOptions: [
		{
			type: "select",
			name: "popular-asc",
			value: {
				fieldname: "score",
				direction: "ASC"
			}
		},
		{
			type: "select",
			name: "price-asc",
			value: {
				fieldname: "price",
				direction: "ASC"
			}
		},
		{
			type: "select",
			name: "price-desc",
			value: {
				fieldname: "price",
				direction: "DESC"
			}
		},
		{
			type: "select",
			name: "name.keyword-asc",
			value: {
				fieldname: "name.keyword",
				direction: "ASC"
			},
		},
		{
			type: "select",
			name: "name.keyword-desc",
			value: {
				fieldname: "name.keyword",
				direction: "DESC"
			}
		}
	],
	isLoading: false,
	activeModal: null,
};

export const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		searchPhraseSet(state, action) {
			state.searchPhrase = action.payload;
			state.filter.searchPhrase = action.payload;
		},
		campaignSet(state, action) {
			state.filter.campaing = JSON.parse(action.payload.campaing);
		},
		filterReset(state) {
			state.filter = initialState.filter;
		},
		categorySet(state, action) {
			const categories: Category[] = [...action.payload.categories];
			state.filter.categories = action.payload.categoryKey ? [] : categories.map(cat => cat.key);
			state.filter.selectedCategory = action.payload.selectedCategory;
			state.filter.categoryKey = action.payload.categoryKey;
		},
		categoryReset(state) {
			state.categories = [];
			state.selectedCategory = "";
			state.categoryKey = null;
		},
		searchSet(state, action) {
			state.filter.searchPhrase = action.payload.searchPhrase;
			state.searchPhrase = action.payload.searchPhrase;
		},
		searchReset(state) {
			state.filter.searchPhrase = "";
			state.searchPhrase = "";
		},
		vehicleSet(state, action) {
			state.isDirty = true;

			state.filter.vehicle = action.payload.vehicleKey ? null : (action.payload.vehicle ? action.payload.vehicle.key : null);
			state.filter.vehicleModel = action.payload.vehicleKey ? null : (action.payload.vehicleModel ? action.payload.vehicleModel.key : null);
			state.filter.vehicleKey = action.payload.vehicleKey;
		},
		filterSet(state, action) {
			state.isDirty = true;
			state.filter[action.payload.key] = action.payload.value;
		},
		sortSet(state, action) {
			state.filter.sort.fieldname = action.payload.fieldname;
			state.filter.sort.direction = action.payload.value;
		},
		filterInstanceSet(state, action) {
			state.isDirty = false;
			state.filterInstance = action.payload.filterInstance;
			state.subCategory = action.payload.subCategory || {};
		},
		modalSet(state, action) {
			state.activeModal = action.payload;
		},
		modalClosed(state) {
			state.activeModal = null;
		},
		markerSet(state, action) {
			state.isDirty = true;
			state.filter.markers = action.payload;
		},
		markerReset(state) {
			state.markers = [];
		},
		filterStatsSet(state, action) {
			state.filterStats = action.payload.filterStats;
		},
		vehicleReset(state) {
			state.filter.vehicle = null;
			state.filter.vehicleModel = null;
			state.filter.vehicleKey = null;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(configSet, (state, action) => {
				state.filterStats = action.payload.filterStats;
				state.vehicleModels = action.payload.vehicleModels;
			})
			.addCase(productsSet, (state, action) => {
				state.filterStats = action.payload.filterStats;
			})
			.addCase(popularProductsSuccessGotten(), (state, action) => {
				state.filterStats = action.payload.filterStats;
			})
			.addCase(popularProductsRequested(), (state: FilterReducerState, action) => {
				state.searchPhrase = "";
				state.filter.searchPhrase = "";
			});
	}
});

export const {
	filterSet,
	vehicleSet,
	searchPhraseSet,
	campaignSet,
	categorySet,
	categoryReset,
	filterInstanceSet,
	filterReset,
	filterStatsSet,
	markerSet,
	markerReset,
	modalSet,
	sortSet,
	modalClosed,
	searchSet,
	searchReset,
	vehicleReset,
} = filterSlice.actions;

export default filterSlice.reducer;
