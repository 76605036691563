// @flow
import React, {useEffect, useState} from "react";
import type {Product} from "../../../models/Product";
import type {translate} from "../../../i18next";
import {useForm} from "react-hook-form";
import type {ProductEnquiry} from "../../../models/ProductEnquiry";
import {WAY_TO_CONTACT} from "../../../models/ProductEnquiry";
import type {ContactData} from "../../../models/ContactData";
import {toast} from "react-toastify";
import SlCaptcha from "../../SlCaptcha";
import "./QuestionForm.scss";
import QuestionProductView from "./QuestionProductView";

export type QuestionFormProps = {
	product: Product,
	closeQuestion: () => void,
	resetMailing: () => void,
	t: translate,
	submitEnquiry: (ProductEnquiry) => void,
	contactData: ContactData,
	sendSuccess: boolean,
	sendError: boolean,
	captchaInvalid: boolean,
}

const QuestionForm = (props: QuestionFormProps) => {
	const [captcha, setCaptcha] = useState(null);

	const {register, handleSubmit, formState: {errors}, watch, clearErrors} = useForm({defaultValues: props.contactData});
	const onSubmit = (data: ProductEnquiry) => {
		props.submitEnquiry({
			...data,
			product: props.product,
			captcha: captcha
		});
	};

	useEffect(() => {
		if (props.sendSuccess) {
			toast(props.t("feedback.relatedQuestions.sendSuccess"));
			props.closeQuestion();
			props.resetMailing();
		}
		if (props.sendError || props.captchaInvalid) {
			toast(props.sendError
				? props.t("feedback.relatedQuestions.sendError")
				: props.t("feedback.captchaInvalid")
			);
			props.resetMailing();
		}
	});

	return <div className="hpm-question">
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={"hpm-close-question"} onClick={() => props.closeQuestion()}/>
			<QuestionProductView product={props.product} t={props.t}/>
			<div>
				<table cellPadding="0" cellSpacing="0">
					<tbody>
						<tr>
							<td colSpan="2" className="hpm-questionHeadline">
								<h3>{props.t("feedback.relatedQuestions.sharingHeadline")}</h3></td>
						</tr>
						<tr>
							<td colSpan="2"><p>{props.t("feedback.relatedQuestions.sharingText")}</p></td>
						</tr>
						<tr>
							<td className="hpm-label">
								<span>{props.t("feedback.relatedQuestions.product")}:</span>
							</td>
							<td>
								<span className="subject">{`"${props.product.name}"`}</span>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className={(errors || {}).message ? "hpm-error" : ""}>
								<span className="hpm-label">{props.t("feedback.message")}</span>
								<textarea name="message" {...register("message", {required: true})}></textarea>
							</td>
						</tr>
						<tr>
							<td colSpan="2">
								<p>{props.t("feedback.mayWeContact")}</p>
								<div className="wayOfContact">
									<input id="contact-02" name="wayToContact" type="radio" value={WAY_TO_CONTACT.viaMail}
										   {...register("wayToContact")} onClick={() => clearErrors("phone")}/>
									<label htmlFor="contact-02">{props.t("feedback.email")}</label>
									<input id="contact-01" name="wayToContact" type="radio" value={WAY_TO_CONTACT.viaPhone}
										   {...register("wayToContact")} onClick={() => clearErrors("email")}/>
									<label htmlFor="contact-01">{props.t("feedback.phone")}</label>
								</div>
								<div className={(errors || {}).name ? "hpm-error" : ""}>
									<input type="text" placeholder={props.t("feedback.name")}
									   name="name" id="name" {...register("name", {required: true})}/>
								</div>
								<div className={(errors || {}).phone ? "hpm-error" : ""}>
									<input type="text" placeholder={props.t("feedback.phone")} name="phone"
										   {...register("phone", {required: watch("wayToContact") === WAY_TO_CONTACT.viaPhone})}/>
								</div>
								<div className={(errors || {}).email ? "hpm-error" : ""}>
									<input type="email" placeholder={props.t("feedback.email")}
									   name="email"
										   {...register("email", {
										   required: watch("wayToContact") === WAY_TO_CONTACT.viaMail || watch("mailMyself"),
										   pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
									   })}/>
								</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2">
								<table className="hpm-conditions">
									<tbody>
										<tr>
											<td>
												<input id="data-copy" name="mailMyself" type="checkbox" {...register("mailMyself")}/>
												<label htmlFor="data-copy">
													{props.t("feedback.copyCheckbox")}
												</label>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
						<tr>
							<td colSpan="2">{props.t("feedback.consent")}</td>
						</tr>
						<tr>
							<td colSpan="2" className="hpm-conditions">
								<div className={(errors || {}).consent ? "hpm-error" : ""}>
									<input id="data-protection" name="consent" type="checkbox"
										   {...register("consent", {required: true})}/>
									<label htmlFor="data-protection">
										{props.t("feedback.consentText")}
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className={"captchaText"}>{props.t("feedback.enterCaptcha")}:</td>
						</tr>
						<tr>
							<td colSpan={"2"}>
								<div>
									<SlCaptcha
										setCaptcha={setCaptcha}
										captcha={captcha}
										error={props.captchaInvalid}
									/>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<button type="submit" className="hpm-contact-send">
					{props.t("feedback.relatedQuestions.send")}
				</button>
			</div>
		</form>
	</div>;
};

export default QuestionForm;
