import {connect} from "react-redux";
import type {ApplicationState} from "../../../reducers";
import {GarageDetail} from "../../../components/productDetail/NSC/GarageDetail";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		productId: state.content.product.id,
		dealerId: state.config.dealerId,
		role: state.config.role,
		theme: state.config.theme,
		hasConsentGoogleMaps: state.consent.googleMaps,
		openDealerShopsInNewTab: state.config.openDealerShopsInNewTab,
		countryCode: state.config.dealerDetails.dealership.distributorCountry,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GarageDetail));
