// @flow

import React from "react";
import Button from "../../layout/Button";
import Spinner from "react-spinkit";
import type {translate} from "../../../i18next";
import type Price from "../../../models/Price";
import PayPalExpressButtonContainer from "../../../container/checkout/dataSummaryBox/PayPalExpressButtonContainer";
import "./ButtonSelector.scss";
import type {PaymentFacility} from "../../../api/PaymentApi";
import {PAYMENT_METHODS, PAYMENT_PROVIDER} from "../../../models/PaymentRequests";
import PayPalButtonContainer from "../../../container/checkout/dataSummaryBox/PayPalButtonContainer";
import P24PayPalButtonContainer from "../../../container/checkout/dataSummaryBox/P24PayPalButtonContainer";

type ButtonSelectorProps = {
	total: Price,
	showPayPalExpressButton: boolean,
	showOrderConfirmButton: boolean,
	disabled: boolean,
	accept: boolean,
	activateSpinner: boolean,
	btnLabel: string,
	t: translate,
	submit: () => void,
	hasPayment: boolean,
	paymentFacility: PaymentFacility,
	paymentReference: string,
	initPayment: string => void,
	isCheckoutStepPayment: boolean,
	isCheckoutStepOverview: boolean,
	initCapturePayment: (string, string) => void,
	finishCapturePayment: string => void,
	renderP24Button: boolean,
	minOrderValue: ?Price,
};
type ButtonSelectorState = {
	showRequired: boolean,
	showSpinner: boolean,
}

export default class ButtonSelector extends React.Component<ButtonSelectorProps, ButtonSelectorState> {
	constructor(props) {
		super(props);
		this.state = {
			showRequired: false,
			showSpinner: false
		};
	}

	onSubmit = e => {
		e.preventDefault();
		this.setState({showSpinner: this.props.activateSpinner});
		this.props.submit();
	};

	initPayment = (paymentReference: string, facility: PaymentFacility) => {
		this.props.initPayment(facility.paymentProvider, paymentReference);
	};

	// https://developer.paypal.com/docs/checkout/standard/customize/standalone-buttons/
	getFundingSourceByPayment = (paymentMethod: string) => {
		switch (paymentMethod) {
			case PAYMENT_METHODS.PAY_LATER:
				return "paylater";
			case PAYMENT_METHODS.PRZELEWY24:
				return "p24";
			case PAYMENT_METHODS.CARD:
				return "card";
			case PAYMENT_METHODS.SEPA:
				return "sepa";
			default:
				return "paypal";
		}
	};

	render() {
		const renderPayPalExpressButton = () => {
			return this.props.showPayPalExpressButton
				? <PayPalExpressButtonContainer/>
				: null;
		};

		if (!this.props.hasPayment) {
			return <Button name="cartButton" disabled>{this.props.btnLabel}</Button>;
		}

		if (this.props.disabled) {
			return <React.Fragment>
				<p className="hpm-error">
					<small>{this.props.t("cart.invalidMsg")}</small>
				</p>
				<Button name="cartButton" disabled>{this.props.btnLabel}</Button>
			</React.Fragment>;
		}

		if (this.props.minOrderValue) {
			return <React.Fragment>
				<Button name="cartButton" disabled>{this.props.btnLabel}</Button>
			</React.Fragment>;
		}

		if (this.props.renderP24Button) {
			return <div key={"paypal"} className={"hpm-paymentFacility hpm-paypal"} style={{display: this.props.accept ? "inherit" : "none"}}>
				<P24PayPalButtonContainer
					onCreateOrder={() => this.props.submit()}
					onPayPalSuccess={(paymentRef: string) => this.props.finishCapturePayment(paymentRef)}
					fundingSource={this.getFundingSourceByPayment(PAYMENT_METHODS.PRZELEWY24)}
				/>
			</div>;
		}

		if (this.props.showOrderConfirmButton) {
			return <React.Fragment>
				{this.state.showRequired
					? <div className="hpm-confirmRequired"><small>* {this.props.t("cart.confirmError")}</small></div>
					: <div/>}
				<Button
					name="cartButton"
					onClick={() => this.setState({showRequired: true})}>
					{this.props.btnLabel}
				</Button>
			</React.Fragment>;
		}

		if (this.state.showSpinner || this.isLoadingPaypalButton) {
			return <Button name="cartButton">
				<Spinner name="ball-beat" className="hpm-spinnerButton"/>
			</Button>;
		}

		if (this.props.paymentFacility) {
			const provider = this.props.paymentFacility.paymentProvider;
			const method = this.props.paymentFacility.paymentMethod;
			switch (provider) {
				case PAYMENT_PROVIDER.PAYPAL:
				case PAYMENT_PROVIDER.PAYPAL_EXPRESS:
				case PAYMENT_PROVIDER.PAYPAL_CAPTURE:
					switch (method.toLowerCase()) {
						case PAYMENT_METHODS.PRZELEWY24.toLowerCase():
							return <div key={"paypal p24"} className={"hpm-paymentFacility hpm-cartButton primary"}
								onClick={() => this.props.initCapturePayment(null, this.props.paymentFacility)}
							>
								<button type={"button"}
									className={"hpm-prepaidButton"}>{this.props.t("cart.paymentMethods.submit")}</button>
							</div>;
						case PAYMENT_METHODS.PAY_LATER.toLocaleLowerCase():
							return <div key={"paypal paylater"} className={"hpm-paymentFacility hpm-paypal"}>
								<PayPalButtonContainer onPayPalSuccess={
									(paymentRef: string) => this.initPayment(paymentRef, this.props.paymentFacility)} fundingSource={"paylater"}
								/>
							</div>;
						case PAYMENT_METHODS.CARD.toLocaleLowerCase():
							return <div key={"paypal card"} className={"hpm-paymentFacility hpm-paypal"}>
								<PayPalButtonContainer onPayPalSuccess={
									(paymentRef: string) => this.initPayment(paymentRef, this.props.paymentFacility)} fundingSource={"card"}
								/>
							</div>;
						case PAYMENT_METHODS.SEPA.toLocaleLowerCase():
							return <div key={"paypal sepa"} className={"hpm-paymentFacility hpm-paypal"}>
								<PayPalButtonContainer onPayPalSuccess={
									(paymentRef: string) => this.initPayment(paymentRef, this.props.paymentFacility)} fundingSource={"sepa"}
								/>
							</div>;
						default:
							return <div key={"paypal default"} className={"hpm-paymentFacility hpm-paypal"}>
								<PayPalButtonContainer onPayPalSuccess={
									(paymentRef: string) => this.initPayment(paymentRef, this.props.paymentFacility)} fundingSource={"paypal"}
								/>
							</div>;
					}
				case PAYMENT_PROVIDER.CASH:
				case PAYMENT_PROVIDER.PREPAID:
					return <div key={"cash_prepaid"} className={"hpm-paymentFacility hpm-cartButton primary"}
						onClick={() => this.initPayment(null, this.props.paymentFacility)}
					>
						<button type={"button"} className={"hpm-prepaidButton"}>
							{this.props.t("cart.paymentMethods.submit")}
						</button>
					</div>;
				case PAYMENT_PROVIDER.NOT_SET:
				default:
					return <div key={"cash_prepaid"} className={"hpm-paymentFacility hpm-cartButton primary"}>
						<button type={"button"} disabled className={"hpm-prepaidButton"}>
							{this.props.t("cart.paymentMethods.unset")}
						</button>
					</div>;
			}
		} else {
			if (this.props.isCheckoutStepPayment) {
				return <div key={"cash_prepaid"} className={"hpm-paymentFacility hpm-fakeButton primary"}>
					<button type={"button"} disabled className={"hpm-prepaidButton"}>
						{this.props.t("cart.paymentMethods.pleaseSelect")}
					</button>
				</div>;
			}
		}

		return <React.Fragment>
			{ this.props.submit
				? <Button name="cartButton" onClick={e => this.onSubmit(e)}>
					{this.props.btnLabel}
				</Button>
				: <p className={"hpm-spacer"}/>
			}
			{renderPayPalExpressButton()}
		</React.Fragment>;
	}
}
