import React from "react";
import {withRouter} from "react-router";
import i18n from "./i18n";

type Props = {
	children: any,
	match: any,
};

const LanguageProvider = (props: Props) => {
	const {language} = props.match.params;
	React.useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	return <div/>;
};

export default withRouter(LanguageProvider);
