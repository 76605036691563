import React from "react";
import Loading from "../layout/Loading";
import CarSelectorContainer from "../../container/carSelector/CarSelectionBarContainer";
import type {translate} from "../../i18next";
import ProductGroup from "../ProductGroup";
import type ProductGroupModel from "../../models/ProductGroup";
import "./StartPage.scss";
import ShowCaseContainer from "../../container/gridPages/ShowCaseContainer";
import type Category from "../../models/Category";
import EventEmitter from "../../helper/eventEmitter";

type StartPageProps = {
	getPopularProducts: () => void,
	t: translate,
	isLoading: boolean,
	setVehicle: (string, string) => void,
	resetVehicle: () => void,
	category: ?Category,
	setCategory: () => void,
	showCategoryProducts: string => void,
	resetSearchPhrase: () => void,
	popularProducts: ProductGroupModel[],
	salesProducts: ProductGroupModel,
};

class StartPage extends React.Component<StartPageProps> {
	componentDidMount() {
		if (this.props.category) {
			this.props.setCategory(this.props.category.name);
		}
		this.props.getPopularProducts();
		new EventEmitter().emitEvent("HPM_PAGE_LOADED");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.category !== this.props.category) this.props.getPopularProducts();
	}

	renderContent() {
		return <React.Fragment>
			<ShowCaseContainer showTileFallback={false}/>
			{this.props.salesProducts && this.props.salesProducts.category.count > 0
				? <ProductGroup
					setCategory={this.props.showCategoryProducts}
					key={this.props.salesProducts.category.key}
					productGroup={this.props.salesProducts}/>
				: null}
			{this.props.popularProducts.map(productGroup => <ProductGroup
				setCategory={this.props.showCategoryProducts}
				key={productGroup.category.key}
				productGroup={productGroup}/>
			)}
		</React.Fragment>;
	}

	render() {
		return <div className="hpm-startPage" data-testid="startPage">
			<div className="hpm-carContainer">
				<CarSelectorContainer resetVehicle={this.props.resetVehicle} setVehicle={this.props.setVehicle}/>
			</div>
			{this.props.isLoading
				? <Loading/>
				: <React.Fragment>
					{this.renderContent()}
				</React.Fragment>
			}
		</div>;
	}
}

export default StartPage;
