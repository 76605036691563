/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import ProductApi from "../../../api/ProductApi";
import Spinner from "react-spinkit";
import type {DealerType} from "./Dealer";
import Map from "./Map";
import Configuration from "../../../Configuration";
import Loading from "../../layout/Loading";
import {Dealer} from "./Dealer";
import "./GarageDetail.scss";
import {Trans} from "react-i18next";
import {usePosition} from "../../../helper/usePosition";
import {sessionEventHandler} from "../../../store";
import {FILTER_TYPE} from "../../../preview/GarageDetail";
import SearchBox from "../../../preview/SearchBox";
import {useState} from "react";
import type {LocationType} from "./Map";

type Props = {
	dealerId: string,
	role: string,
	theme: string,
	productId: string,
	hasConsentGoogleMaps: boolean,
	openDealerShopsInNewTab: boolean,
	t: string => string
};

export const GarageDetail = ({dealerId, hasConsentGoogleMaps, openDealerShopsInNewTab, productId, role, t, theme, countryCode}: Props) => {
	const [garageDetail, setGarageDetail] = useState(undefined);
	const [filteredListDealers, setFilteredListDealers] = useState([]);
	const [otherMapDealers, setOtherMapDealers] = useState([]);
	const [boundedMapDealers, setBoundedMapDealers] = useState([]);
	const [showMap, setShowMap] = useState(true);
	const [dealerSearchType, setDealerSearchType] = useState("");
	const {latitude, longitude, error} = usePosition();
	const [filterString, setFilterString] = useState("");
	const [selectedLocation, setSelectedLocation] = useState<LocationType>(null);

	const [latMemo, setLatMemo] = React.useState(latitude);
	const [longMemo, setLongMemo] = React.useState(longitude);

	React.useEffect(() => {
		if (latitude && !latMemo) {
			setLatMemo(latitude);
		}
		if (longitude && !longMemo) {
			setLongMemo(longitude);
		}
	}, [latMemo, latitude, longMemo, longitude]);

	const handleSelectedLocation = (location: LocationType) => {
		setFilterString("");
		setSelectedLocation(location);
	};

	const handleFilterStringChange = e => {
		setFilterString(e.target.value);
	};

	const dealerList = () => (
		filteredListDealers.map((dealer: DealerType) => (
			<Dealer key={dealer.identifier.dealerNo} dealer={dealer}
					openDealerShopsInNewTab={openDealerShopsInNewTab}/>
		))
	);

	React.useEffect(() => {
		if (!hasConsentGoogleMaps) sessionEventHandler.requestGoogleMapsConsent();
	}, [hasConsentGoogleMaps]);

	React.useEffect(() => {
		const locationOptions = selectedLocation ? {
			latitude: selectedLocation.lat,
			longitude: selectedLocation.lng,
			searchRadius: 1000
		} : (latMemo)
			? {
			    latitude: latMemo,
			    longitude: longMemo,
			    searchRadius: 10000
		    }
            : null;

		const productApi = new ProductApi(dealerId, {configIdType: role, intent: "DEFAULT", locationOptions});
		productApi.getDealersForProduct(productId)
			.then(res => setGarageDetail(res));
	}, [latMemo, longMemo, error, productId, dealerId, role, selectedLocation]);

	React.useEffect(() => {
		if (!garageDetail) {
			return;
		}

		const allDealers = garageDetail.dealersForBrand;

		const filteredDealers = allDealers.filter(dealer => {
			const fuzzy = filterString.toLowerCase();
			const { name, address, dealerConfiguration } = dealer;
			const { shopEnabled, productPricesDisabled } = dealerConfiguration;

			if (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE && (shopEnabled || !productPricesDisabled)) {
				return false;
			}
			if (dealerSearchType === FILTER_TYPE.WITH_PRICE && (shopEnabled || productPricesDisabled)) {
				return false;
			}
			if (dealerSearchType === FILTER_TYPE.WITH_SHOP && (!shopEnabled)) {
				return false;
			}
			if (address && fuzzy) {
				const filterObject = [name, address.town, address.street, address.countryCode, address.streetNumber, address.postalCode];
				return filterObject.some(it => it && it.toLowerCase().includes(fuzzy));
			}
			return true;
		});

		const localDealers = filteredDealers.filter(dealer => {
			const distance = parseFloat(dealer.distance.replace(" km"));
			return distance <= 20;
		});

		let filteredMapDealers = localDealers.length < 1 ? filteredDealers.slice(0, 2) : localDealers;

		setFilteredListDealers(filteredDealers);

		if(selectedLocation || latMemo) {
			setBoundedMapDealers(filteredMapDealers);
			setOtherMapDealers(filteredDealers.filter(dealer => !boundedMapDealers.includes(dealer)));
		}

		else {
			setBoundedMapDealers(filteredDealers);
			setOtherMapDealers([]);
		}


	}, [filterString, dealerSearchType, garageDetail, selectedLocation]);

	if (!garageDetail) {
		return <div className={"GeoLocator info"}>
			<Spinner name="ball-beat" className="hpm-spinnerButton" fadeIn="none"/>
		</div>;
	}
	const toggleSearchDealerType = dealerType => {
		dealerType !== dealerSearchType
			? setDealerSearchType(dealerType)
			: setDealerSearchType("");
	};
	return (
		<React.Fragment>
			<div className="page-content-block">
				<div className="page-middle">
					<div className={"GeoLocator control"}>
						{hasConsentGoogleMaps && showMap ? (
							<span className={"searchBox"}>
								<SearchBox onLocationSelect={handleSelectedLocation} countryCode={countryCode}/>
								<label className="ico search" htmlFor="dealer-class-10"/>
							</span>
						) : (
							<div className={"filter-box-container-close-map"}>
								<div className={"main-container"}>
									<div className={"filterInput-close"}>
										<input type="search" list={"dealers"}
											   value={filterString}
											   placeholder={t("nsc.filter")}
											   onChange={handleFilterStringChange}
											   className="filter-close-input"
										/>
										<span/>
									</div>
								</div>
							</div>
						)}
						<div className="geolocatorLegend">
							<div
								className={"indicator location " + (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE ? " checked" : "")}
								onClick={() => toggleSearchDealerType(FILTER_TYPE.WITHOUT_PRICE)}><span className="text">{t("nsc.offer.withoutPrice")}</span>
							</div>
							<div className={"indicator euro " + (dealerSearchType === FILTER_TYPE.WITH_PRICE ? " checked" : "")}
								 onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_PRICE)}><span className="text">{t("nsc.offer.withPrice")}</span>
							</div>
							{theme === "ford"
								? <div className={"indicator shop " + (dealerSearchType === FILTER_TYPE.WITH_SHOP ? " checked" : "")}
									   onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_SHOP)}><span className="text">{t("nsc.offer.withShop")}</span>
								</div>
								: <div className={"indicator shop " + (dealerSearchType === FILTER_TYPE.WITH_SHOP ? " checked" : "")}
									   onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_SHOP)}><span className="text">{t("nsc.offer.withShop")}</span>
								</div>
							}
						</div>
						{
							hasConsentGoogleMaps && <button type={"button"} onClick={() => setShowMap(!showMap)}>{
								showMap ? <Trans i18nKey="nsc.closeMap"/> : <Trans i18nKey="nsc.openMap"/>
							}</button>
						}
					</div>
					{hasConsentGoogleMaps && showMap ? (
						<div className={"GeoLocator"}>
							<Map
								longitude={longMemo}
								latitude={latMemo}
								error={error}
								otherDealers={otherMapDealers}
								boundedDealers={boundedMapDealers}
								googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Configuration.value("googleMapsApiKey")}&v=3.exp&libraries=places`}
								loadingElement={<Loading/>}
								containerElement={<div className={"GeoLocator Map"}/>}
								mapElement={<div/>}
								openDealerShopsInNewTab={openDealerShopsInNewTab}
								onLocationSelect={handleSelectedLocation}
								selectedLocation={selectedLocation}
							/>
							<div className={"GeoLocator List"}>
								<span>
									<div className={"filter-box-container-nsc"}>
										<input type="search" list={"dealers"}
									  value={filterString}
									  placeholder={t("nsc.filter")}
									  onChange={handleFilterStringChange}
									  className={"openInput-nsc"}
							   />
										<label/>
									</div>
								</span>
								<div className={"listContainer"}>
									{dealerList()}
								</div>
							</div>
						</div>
					) : (
						<div className={"GeoLocator close-map-list"}>
							{dealerList()}
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};