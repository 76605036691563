import React from "react";
import {v4 as uuidv4} from "uuid";
import Menu from "./FilterTypes/Menu";
import {FILTER} from "../../reducers/filterReducer";
import MultiSelect from "./FilterTypes/MultiSelect";
import type {translate} from "../../i18next";

export type MainCategoryFilterPageProps = {
	t: translate,
	categories: [],
	filter: {},
	resetCategory: () => void,
	setCategory: string => void,
	getCategoryFilterStats: {} => void,
	setFilterInstance: (number, {}) => void,
	isFiltered: boolean,
	isCategorySelected: string => boolean,
};

const FilterPageMainCategory = (props: MainCategoryFilterPageProps) => {
	return <React.Fragment>
		<MultiSelect
			infoText="" label={props.t("sort.all")}
			selected={!props.isFiltered} setFilter={props.resetCategory}
			key={uuidv4()}/>
		{props.categories && props.categories.map(category => {
			return category.shouldRenderMenu
				? <Menu
					infoText={category.count} label={category.title}
					setMenu={() => props.setFilterInstance(FILTER.SUB_CATEGORY, category)}
					key={uuidv4()}
				/>
				: <MultiSelect
					infoText={category.count} label={category.title}
					value={category.name} key={uuidv4()}
					setFilter={props.setCategory}
					selected={props.isCategorySelected(category.name)}
				/>;
		})}
	</React.Fragment>;
};

export default FilterPageMainCategory;
