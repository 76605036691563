// @flow
import {ThunkAction} from "@reduxjs/toolkit";
import type {ApplicationState} from "../reducers";
import NotificationApi from "../api/NotificationApi";
import type {ProductEnquiry, ProductEnquiryRequest, ProductEnquiryResponse} from "../models/ProductEnquiry";
import type {ProductShareRequest, ProductSharing, ProductSharingResponse} from "../models/ProductSharing";
import type {WishlistSharing, WishlistSharingRequest} from "../models/WishlistSharing";
import {
	captchaInvalid,
	enquirySaved, errorShared, mailingReset,
	productEnquiryError,
	productEnquirySuccess,
	sharingSaved, successShared, wishlistErrorShared, wishlistSharingSaved, wishlistSuccessShared
} from "../reducers/mailingReducer";

export function submitProductEnquiry(enquiry: ProductEnquiry): ThunkAction {
	const notificationApi = new NotificationApi(enquiry.captcha);

	return async (dispatch, getState: () => ApplicationState) => {
		dispatch(enquirySaved(enquiry));
		const productEnquiryRequest: ProductEnquiryRequest = {
			productId: enquiry.product.id,
			customer: {
				name: enquiry.name,
				phone: enquiry.phone,
				email: enquiry.email,
				wayToContact: enquiry.wayToContact,
				language: getState().config.moduleLanguage || "en",
			},
			mailMyself: enquiry.mailMyself,
			message: enquiry.message,
		};
		notificationApi.createEnquiry(productEnquiryRequest)
			.then((response: ProductEnquiryResponse) => {
				dispatch(productEnquirySuccess());
			}).catch(error => {
				error.response && error.response.status === 412
					? dispatch(captchaInvalid())
					: dispatch(productEnquiryError());
			});
	};
}

export function submitShare(sharing: ProductSharing): ThunkAction {
	const notificationApi = new NotificationApi(sharing.captcha);

	return async (dispatch, getState: () => ApplicationState) => {
		dispatch(sharingSaved(sharing));
		const sharingRequest: ProductShareRequest = {
			email: sharing.email,
			message: sharing.message,
			receiverEmail: sharing.receiverEmail,
			productNo: sharing.product.id,
			bookmarkUrl: sharing.bookmarkUrl,
			language: getState().config.moduleLanguage || "en",
		};
		notificationApi.shareProduct(sharingRequest)
			.then((response: ProductSharingResponse) => {
				dispatch(successShared());
			}).catch(error => {
				error.response && error.response.status === 412
					? dispatch(captchaInvalid())
					: dispatch(errorShared);
			});
	};
}

export function submitWishlist(wishlist: WishlistSharing): ThunkAction {
	const notificationApi = new NotificationApi(wishlist.captcha);

	return async (dispatch, getState: () => ApplicationState) => {
		dispatch(wishlistSharingSaved(wishlist));
		const wishlistSharingRequest: WishlistSharingRequest = {
			mailMyself: wishlist.mailMyself,
			message: wishlist.message,
			customer: {
				name: wishlist.name,
				phone: wishlist.phone,
				email: wishlist.email,
				wayToContact: wishlist.wayToContact,
				language: getState().config.moduleLanguage || "en",
			},
			products: wishlist.products.map(product => {
				return {
					productNo: product.id,
				};
			}),
		};
		notificationApi.shareWishlist(wishlistSharingRequest)
			.then((response: ProductSharingResponse) => {
				dispatch(wishlistSuccessShared());
			}).catch(error => {
				error.response && error.response.status === 412
					? dispatch(captchaInvalid())
					: dispatch(wishlistErrorShared());
			});
	};
}

export const resetMailing = () => {
	return mailingReset();
};
