// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const PeugeotTheme = props => {
	import("./peugeot.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default PeugeotTheme;
