// @flow
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import CarSelectorContainer from "../../container/carSelector/CarSelectionBarContainer";
import type { translate } from "../../i18next";
import type { Product } from "../../models/Product";
import type { Vehicle } from "../../models/Vehicle";
import type { ConfigReducerState } from "../../reducers/configReducer";
import "./Search.scss";
import EventEmitter from "../../helper/eventEmitter";
import GridElementContainer from "../../container/gridPages/GridElementContainer";

type SearchProps = {
	products: Product[],
	newProducts: number,
	getNextProducts: () => void,
	setSearch: string => void,
	config: ConfigReducerState,
	t: translate,
	lastSearch: string,
	totalItems: number,
	currentPage: number,
	totalPages: number,
	isLoading: boolean,
	hasMore: boolean,
	vehicleSeries: Vehicle[],
	resetVehicle: () => void,
	selectedVehicle: string,
	setVehicle: (string, string) => void,
};

const Search = (props: SearchProps) => {
	const {
		getNextProducts,
		products,
		resetVehicle,
		setSearch,
		setVehicle,
		t,
		lastSearch,
		totalItems,
	} = props;
	const [title, setTitle] = useState();
	const allProductsLoaded = props.products.length >= props.totalItems;

	const loadMoreProducts = () => {
		if (props.hasMore) {
			props.getNextProducts();
		}
	};

	useEffect(() => {
		new EventEmitter().emitEvent("HPM_PAGE_LOADED");
		setSearch(lastSearch);
		setTitle(
			t("search.foundProducts", {
				totalItems: totalItems,
				searchPhrase: lastSearch === "undefined" ? "" : lastSearch
			})
		);
	}, [lastSearch, setSearch, t, totalItems]);



	return (
		<div className="hpm-searchPage">
			<div className="hpm-carContainer">
				<CarSelectorContainer
					resetVehicle={resetVehicle}
					setVehicle={setVehicle}
				/>
			</div>
			<div className="hpm-categoryContainer">
				<div
					className="hpm-searchTitle"
					dangerouslySetInnerHTML={{__html: title}}
				/>
				<div className="hpm-gridList">
					<InfiniteScroll
						initialLoad={false}
						dataLength={totalItems}
						pageStart={0}
						loadMore={getNextProducts}
						hasMore={false}
						loader={<React.Fragment/>}
						threshold={250}
					>
						{products.map(product => {
							return (
								<GridElementContainer key={product.id} product={product}
								/>
							);
						})}
					</InfiniteScroll>
					{allProductsLoaded && (
						<div className="total-items">
							{`${props.totalItems} ${props.t("category.allProductsLoaded")}`}
						</div>
					)}
					{!allProductsLoaded && (
						<button className={"show-more-products"} onClick={loadMoreProducts}>
							{props.t("category.showAll")}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Search;
