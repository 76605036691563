import React from "react";
import { v4 as uuidv4 } from "uuid";
import MultiSelect from "./FilterTypes/MultiSelect";
import type {translate} from "../../i18next";

export type SortFilterPageProps = {
	t: translate,
	sortOptions: [],
	setSort: {fieldname: string, direction: string} => void,
	isOptionSelected: {} => boolean,
};

const FilterPageSort = (props: SortFilterPageProps) => {
	return props.sortOptions.map(sort => {
		return <MultiSelect infoText="" label={props.t("sort." + sort.name)} value={sort.value}
					   selected={props.isOptionSelected(sort.value)}
					   setFilter={props.setSort} key={uuidv4()}
		/>;
	});
};

export default FilterPageSort;
