import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {ShowCase} from "../../components/gridPages/ShowCase";

function mapStateToProps(state: ApplicationState, ownProps) {
	if (state.filter.filterStats.categories && state.filter.filterStats.categories.length) {
		if (ownProps.showTileFallback) {
			const filteredCategories = state.filter.filterStats.categories.find(category => category.name === ownProps.selectedCategoryKey);
			return {
				categories: filteredCategories ? filteredCategories.categories : []
			};
		}
		let categories = state.filter.filterStats.categories.filter(category => category.name !== ownProps.selectedCategoryKey && !!category.imageUrl);

		if (state.content.salesProducts && state.content.salesProducts.category.count > 0) {
			const environment = "production";

			categories.unshift({
				classNames: "hpm-saleTile",
				key: state.content.salesProducts.category.key,
				count: state.content.salesProducts.category.count,
				name: state.content.salesProducts.category.name,
				title: ownProps.t("category.titleSpecialOffer"),
				categories: [],
				subTitle: "",
				imageUrl: "https://storage.googleapis.com/sl-images-" + environment + "/hpm_sale_fallback.jpg",

			});
		}

		return {
			categories: categories
		};
	}

	return {
		categories: []
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ShowCase);
