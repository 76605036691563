// @flow
import React from "react";
import type {translate} from "../../../i18next";
import "./OrderFinished.scss";
import {OrderState} from "../../../models/Order";
import Spinner from "react-spinkit";

type OrderFinishedProps = {
	makeCartEmpty: () => void,
	clearOrderDetails: () => void,
	hideOrderFinished: () => void,
	goToStartpage: () => void,
	t: translate,
	orderNo: string,
	orderState: OrderState,
	shouldCheckOrderState: boolean,
	getOrderState: () => void,
	resetOrderState: () => void,
	paymentProvider: string
};

class OrderFinished extends React.Component<OrderFinishedProps> {
	state = {
		intervalId: 0
	};

	componentDidMount() {
		if (this.props.shouldCheckOrderState) {
			this.props.getOrderState(this.props.orderNo);
		}
		if (this.props.shouldCheckOrderState && this.props.orderState === OrderState.PROCESSING) {
			this.setState(prevState => {
				return {
					...prevState,
					intervalId: setInterval(() => {
						this.props.getOrderState(this.props.orderNo);
					}, 3000)
				};
			});
		}
	}

	componentWillUnmount() {
		if (!this.props.shouldCheckOrderState || this.props.orderState === OrderState.AUTHORIZED) {
			this.props.clearOrderDetails();
			this.props.makeCartEmpty();
			this.props.resetOrderState();
			clearInterval(this.state.intervalId);
		}
	}

	renderSpinner() {
		return <div>
			<span>{this.props.t("cart.checkingOrder")}</span>
			<Spinner name="ball-beat" className="hpm-spinnerButton" fadeIn="none"/>
		</div>;
	}

	render() {
		return (
			<React.Fragment>
				{(!this.props.shouldCheckOrderState || this.props.orderState === OrderState.AUTHORIZED) &&
					<div className="hpm-orderFinished hpm-emptyCart">
						<div>
							<span className={"hpm-orderFinish"}>{this.props.t("cart.finished")}</span>
							<button
								type={"button"}
								onClick={() => this.props.goToStartpage()}
								className="hpm-cartButton back">{this.props.t("cart.back")}
							</button>
						</div>
					</div>
				}
				{this.props.shouldCheckOrderState &&
					<div className="hpm-orderFinished hpm-emptyCart">
						{this.props.orderState === OrderState.PROCESSING &&
							this.renderSpinner()
						}
						{ (this.props.orderState === OrderState.FAILED) &&
							<div>
								<span>{this.props.t("cart.orderFailed")}</span>
							</div>
						}
					</div>
				}
			</React.Fragment>
		);
	}
}

export default OrderFinished;
