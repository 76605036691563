
export const makeSpacesNonbreaking = (input: string): string => {
	return input.replace(/ /g, "\u00a0");
};

export const decodeBase64Json = (input: string): string => {
	let decodedString = null;
	try {
		if (input)
			decodedString = JSON.parse(atob(input.replace("==>", "")));
	} catch (e) {
		decodedString = null;
	}
	return decodedString;
};
