import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import "./App.scss";
import i18n from "./i18n/i18n";
import {I18nextProvider} from "react-i18next";
import LanguageProvider from "./i18n/LanguageProvider";
import EventHandler from "./event/EventHandler";
import {getCountry} from "./helper/CountryProvider";

function App() {
	const AbarthPage = React.lazy(() => import("./pages/Abarth"));
	const AlfaRomeoPage = React.lazy(() => import("./pages/AlfaRomeo"));
	const AudiPage = React.lazy(() => import("./pages/Audi"));
	const BentleyPage = React.lazy(() => import("./pages/Bentley"));
	const BmwPage = React.lazy(() => import("./pages/BMW"));
	const BrandSelectPage = React.lazy(() => import("./pages/BrandSelect"));
	const ChryslerPage = React.lazy(() => import("./pages/Chrysler"));
	const CitroenPage = React.lazy(() => import("./pages/Citroen"));
	const CupraPage = React.lazy(() => import("./pages/Cupra"));
	const DaciaPage = React.lazy(() => import("./pages/Dacia"));
	const DodgePage = React.lazy(() => import("./pages/Dodge"));
	const DSPage = React.lazy(() => import("./pages/DS"));
	const FiatPage = React.lazy(() => import("./pages/Fiat"));
	const FiatProPage = React.lazy(() => import("./pages/FiatPro"));
	const FordPage = React.lazy(() => import("./pages/Ford"));
	const HondaPage = React.lazy(() => import("./pages/Honda"));
	const HyundaiPage = React.lazy(() => import("./pages/Hyundai"));
	const JaguarPage = React.lazy(() => import("./pages/Jaguar"));
	const JeepPage = React.lazy(() => import("./pages/Jeep"));
	const KiaPage = React.lazy(() => import("./pages/Kia"));
	const LanciaPage = React.lazy(() => import("./pages/Lancia"));
	const LandRoverPage = React.lazy(() => import("./pages/LandRover"));
	const MaseratiPage = React.lazy(() => import("./pages/Maserati"));
	const MazdaPage = React.lazy(() => import("./pages/Mazda"));
	const MercedesBenzPage = React.lazy(() => import("./pages/MercedesBenz"));
	const MiniPage = React.lazy(() => import("./pages/Mini"));
	const MitsubishiPage = React.lazy(() => import("./pages/Mitsubishi"));
	const OpelPage = React.lazy(() => import("./pages/Opel"));
	const PeugeotPage = React.lazy(() => import("./pages/Peugeot"));
	const PorschePage = React.lazy(() => import("./pages/Porsche"));
	const RenaultPage = React.lazy(() => import("./pages/Renault"));
	const SeatPage = React.lazy(() => import("./pages/Seat"));
	const SkodaPage = React.lazy(() => import("./pages/Skoda"));
	const SmartPage = React.lazy(() => import("./pages/Smart"));
	const SubaruPage = React.lazy(() => import("./pages/Subaru"));
	const SuzukiPage = React.lazy(() => import("./pages/Suzuki"));
	const ToyotaPage = React.lazy(() => import("./pages/Toyota"));
	const VolkswagenPage = React.lazy(() => import("./pages/Volkswagen"));
	const VolvoPage = React.lazy(() => import("./pages/Volvo"));
	const MgPage = React.lazy(() => import("./pages/Mg"));

	const browserLanguage = navigator.language || navigator.userLanguage || "en";

	const renderBrand = () => {
		return <Switch>
			<Route exact path={"/"} component={BrandSelectPage}/>
			<Route path="/:language/abarth" component={AbarthPage}/>
			<Route path="/:language/alfaromeo" component={AlfaRomeoPage}/>
			<Route path="/:language/audi" component={AudiPage}/>
			<Route path="/:language/bentley" component={BentleyPage}/>
			<Route path="/:language/bmw" component={BmwPage}/>
			<Route path="/:language/chrysler" component={ChryslerPage}/>
			<Route path="/:language/citroen" component={CitroenPage}/>
			<Route path="/:language/cupra" component={CupraPage}/>
			<Route path="/:language/dacia" component={DaciaPage}/>
			<Route path="/:language/dodge" component={DodgePage}/>
			<Route path="/:language/ds" component={DSPage}/>
			<Route path="/:language/fiat" component={FiatPage}/>
			<Route path="/:language/fiatpro" component={FiatProPage}/>
			<Route path="/:language/ford" component={FordPage}/>
			<Route path="/:language/honda" component={HondaPage}/>
			<Route path="/:language/hyundai" component={HyundaiPage}/>
			<Route path="/:language/jaguar" component={JaguarPage}/>
			<Route path="/:language/jeep" component={JeepPage}/>
			<Route path="/:language/kia" component={KiaPage}/>
			<Route path="/:language/lancia" component={LanciaPage}/>
			<Route path="/:language/landrover" component={LandRoverPage}/>
			<Route path="/:language/maserati" component={MaseratiPage}/>
			<Route path="/:language/mazda" component={MazdaPage}/>
			<Route path="/:language/mercedesbenz" component={MercedesBenzPage}/>
			<Route path="/:language/mini" component={MiniPage}/>
			<Route path="/:language/mitsubishi" component={MitsubishiPage}/>
			<Route path="/:language/opel" component={OpelPage}/>
			<Route path="/:language/peugeot" component={PeugeotPage}/>
			<Route path="/:language/renault" component={RenaultPage}/>
			<Route path="/:language/seat" component={SeatPage}/>
			<Route path="/:language/skoda" component={SkodaPage}/>
			<Route path="/:language/smart" component={SmartPage}/>
			<Route path="/:language/subaru" component={SubaruPage}/>
			<Route path="/:language/suzuki" component={SuzukiPage}/>
			<Route path="/:language/porsche" component={PorschePage}/>
			<Route path="/:language/toyota" component={ToyotaPage}/>
			<Route path="/:language/volkswagen" component={VolkswagenPage}/>
			<Route path="/:language/volvo" component={VolvoPage}/>
			<Route path="/:language/mg" component={MgPage}/>
			<Redirect from={`/${browserLanguage}`} to={"/"}/>
			<Route path="/" render={({location}) =>
				<Redirect to={`/${browserLanguage}${location.pathname + location.search + location.hash}`}/>
			}/>
		</Switch>;
	};

	return <I18nextProvider i18n={i18n}>
		<BrowserRouter>
			<React.Fragment>
				<Route path={"/:language"} component={LanguageProvider}/>
				<Route path={"/:language/:brand"} component={EventHandler}/>
				<React.Suspense fallback={<div>Loading...</div>}>
					{renderBrand()}
				</React.Suspense>
			</React.Fragment>
		</BrowserRouter>
	</I18nextProvider>;
}

export const BrandContext = React.createContext(null);
export const CountryContext = React.createContext(getCountry());

export default App;
