// @flow

export const renderBrand = (brand: string) => {
	switch (brand) {
		case "alfa":
			return "Alfa Romeo";
		case "citroen":
			return "Citroën";
		case "vw":
			return "Volkswagen";
		case "landrover":
			return "Land Rover";
		case "ds":
			return "DS Automobiles";
		default:
			return brand;
	}
};
