import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import React, {useEffect} from "react";
import { convertToLocale } from "../helper/languageUtils";

const PaypalScriptProvider = (props: any) => {
	const {clientId, currency, isShopEnabled, moduleLanguage} = props;

	useEffect(() => {
		if (clientId && currency && isShopEnabled && moduleLanguage) {
			const existingP24Script = document.getElementById("sl-p24-script");
			if (!existingP24Script) {
				const script = document.createElement("script");
				script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&locale=${moduleLanguage}&intent=capture&components=buttons,payment-fields,marks,funding-eligibility&enable-funding=p24`;
				script.id = "sl-p24-script";
				script.dataset["namespace"] = "paypal_capture";
				document.body.appendChild(script);
			}

			const existingScript = document.getElementById("sl-paypalScript");
			if (!existingScript) {
				const script = document.createElement("script");
				script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&locale=${moduleLanguage}&intent=authorize&commit=false&components=buttons,payment-fields,marks,funding-eligibility`;
				script.id = "sl-paypalScript";
				document.body.appendChild(script);
			}
		}
	}, [clientId, currency, isShopEnabled, moduleLanguage]);

	return <div/>;
};
function mapStateToProps(state: ApplicationState) {
	return {
		clientId: state.cart.paypalClientId,
		currency: (state.order && state.order.totalBillingAmount && state.order.totalBillingAmount.currency)
			? state.order.totalBillingAmount.currency.code
			: false,
		isShopEnabled: state.config.isShopEnabled,
		moduleLanguage: convertToLocale(state.config.moduleLanguage),
	};
}

export default connect(mapStateToProps)(PaypalScriptProvider);
