import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getProduct} from "../../actions/products";
import {addToCart} from "../../actions/cart";
import Detailpage from "../../components/productDetail/Detailpage";
import {addBookmark, removeBookmark, resetWelcomeMessage} from "../../actions/bookmarks";
import type {ApplicationState} from "../../reducers";
import {resetMailing} from "../../actions/mailing";
import type {Product} from "../../models/Product";
import {RoleTypes} from "../../models/Role";
import { productReset, showDealerListSet } from "../../reducers/contentReducer";
import {shareButtonClicked} from "../../reducers/mailingReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		config: state.config,
		product: state.content.product,
		attachments: state.content.attachments,
		relatedProducts: state.content.relatedProducts,
		articleNumber: ownProps.match.params.articleNumber1 || ownProps.match.params.articleNumber2,
		productLoaded: state.content.productLoaded,
		isLeadModuleEnabled: state.config.isLeadModuleEnabled,
		isBookmarked: state.content.productLoaded && !!state.bookmarks.products.filter(product => product.id === state.content.product.id).length,
		isShopEnabled: state.config.isShopEnabled,
		watchlistEnabled: state.config.watchlistEnabled,
		mailing: state.mailing,
		isNSCDealer: state.config.role === RoleTypes.DISTRIBUTOR,
		dealerDetails: state.config.dealerDetails,
		showDealerList: state.content.showDealerList,
		needsPickupLocations: state.location.locationChoices.length > 0 && !state.location.pickupLocation,
		pickupLocation: state.location.pickupLocation,
		locationChoices: state.location.locationChoices,
		fallbackImageURL: state.content.brand.brandImageUri,
		showWelcomeMessage: state.bookmarks.showWelcomeMessage,
		brandName: state.content.brand.name,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getProduct: articleNumber => {
			dispatch(productReset());
			dispatch(getProduct(articleNumber));
		},
		addToCart: (product, shippingMethod) => dispatch(addToCart(product, shippingMethod)),
		addBookmark: product => dispatch(addBookmark(product)),
		removeBookmark: product => dispatch(removeBookmark(product)),
		resetMailing: () => dispatch(resetMailing()),
		setShowDealerList: value => dispatch(showDealerListSet(value)),
		trackShareButtonClick: (product: Product) => dispatch(shareButtonClicked(product)),
		resetWelcomeMessage: () => dispatch(resetWelcomeMessage())
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(Detailpage);
