import Footer from "../../components/layout/Footer";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import RootNodeProvider from "../../helper/RootNodeProvider";
import Configuration from "../../Configuration";

function mapStateToProps(state: ApplicationState, ownProps) {
	const isLandingPage = !!RootNodeProvider.getPreviewRootNode();

	const privacyPolicy = isLandingPage ? state.config.privacyPolicy : null;
	const imprintText = isLandingPage ? state.config.imprintText : null;

	return {
		showFooter: state.config.legalTerms || state.config.revocationTerms || imprintText || privacyPolicy,
		legalTerms: state.config.legalTerms,
		revocationTerms: state.config.revocationTerms,
		paymentAgreement: false,
		dealerId: state.config.dealerId,
		notificationUrl: Configuration.value("apiNotificationUrl"),
		privacyPolicy,
		imprintText,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)));
