import {connect} from "react-redux";
import FilterPageVehicleModel from "../../components/Filter/FilterPageVehicleModel";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {setVehicle} from "../../actions/vehicle";

function mapStateToProps(state: ApplicationState, ownProps) {
	const {filter, subCategory} = state.filter;
	return {
		subCategory: subCategory,

		isModelSelected: modelKey => filter.vehicleModel === modelKey || filter.vehicleKey === modelKey,
		isSeriesSelected: seriesKey => filter.vehicle === seriesKey || filter.vehicleKey === seriesKey,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setVehicleSeries: vehicleSeriesKey => {
			dispatch(setVehicle(vehicleSeriesKey, null));
		},
		setVehicleModel: vehicleModelKey => {
			dispatch(setVehicle(null, vehicleModelKey));
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPageVehicleModel));
