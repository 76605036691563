import React from "react";
import PropTypes from "prop-types";
import "./Menu.scss";

const Menu = props => {
	return (
		<div className={"hpm-filterItem"} id="hpmt-menu"
						  onClick={() => props.setMenu()}>
			<span className="hpm-menuText" id="hpmt-menuLabel">{props.label}</span>
			<span className="hpm-infoText">{props.infoText}</span>
			<span className="hpm-menuArrow"/>
		</div>
	);
};

Menu.propTypes = {
	label: PropTypes.string.isRequired,
	infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setMenu: PropTypes.func.isRequired,
};

export default Menu;
