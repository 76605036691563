// @flow

import BaseStorage from "./BaseStorage";
import type Memorizing from "../../models/Memorizing";

const HPM_TTL: string = "-hpmTTL";

export default class TTLStorage extends BaseStorage implements Memorizing<null, ?string> {
	constructor(dealerId: string) {
		super(dealerId, HPM_TTL);
	}

	isTTLValid(): boolean {
		const currentDate = new Date();
		const previousDate = new Date(this.get() || currentDate.getDate() - 1);
		return currentDate <= previousDate;
	}

	set(value): void {
		const DEFAULT_TTL = 14;
		const currentDate = new Date();
		currentDate.setDate(currentDate.getDate() + DEFAULT_TTL);
		return this.storage.setItem(this.key, currentDate.toString());
	}
}
