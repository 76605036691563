// @flow
const knownCountries = ["DE", "PL", "DK", "AT", "BE", "CH", "CZ", "IT", "PT"];
const fallbackCountry = "DE";

const searchCountryTopLevelDomain = (): ?string => {
	if (window && window.location && window.location.host) {
		const topLevelDomain = window.location.host.replace(/^.*\./, "");
		const maybeCountryCode = topLevelDomain.toUpperCase();
		if (knownCountries.includes(maybeCountryCode)) {
			return maybeCountryCode;
		}
	}
	return null;
};
export const getCountry = (): string => {
	try {
		return searchCountryTopLevelDomain() || fallbackCountry;
	} catch (e) {
		return fallbackCountry;
	}
};
