// @flow
import React from "react";
import BlankLabel from "../../assets/images/tire_label/blank-label.jpg";
import NoiseClass1 from "../../assets/images/tire_label/noise_class_1.png";
import NoiseClass2 from "../../assets/images/tire_label/noise_class_2.png";
import NoiseClass3 from "../../assets/images/tire_label/noise_class_3.png";
import "./tireLabel.scss";
import type {TireLabel} from "../../models/TireLabel";

const NOISE_CLASS = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3
};

export default class Tire extends React.Component<TireLabel> {
	getNoiseIcon() {
		switch (this.props.noiseEmmissionClass) {
			case NOISE_CLASS.LOW:
				return NoiseClass1;
			case NOISE_CLASS.MEDIUM:
				return NoiseClass2;
			default:
				return NoiseClass3;
		}
	}

	render() {
		if (!this.props.fuelEfficiency || !this.props.gripOnWetStreet || !this.props.noiseLevel || !this.props.noiseEmmissionClass) {
			return null;
		}

		return (
			<div className="sl-tirelabel" >
				<img src={BlankLabel} alt="" width="160" height="234"/>
				<div className={"sl-tirelabel-fuel sl-" + (this.props.fuelEfficiency).toLowerCase()}>{this.props.fuelEfficiency.toUpperCase()}<span></span></div>
				<div className={"sl-tirelabel-grip sl-" + (this.props.gripOnWetStreet).toLowerCase()}>{this.props.gripOnWetStreet.toUpperCase()}<span></span></div>
				<div className="sl-tirelabel-ico"><img src={this.getNoiseIcon()} alt="" width="50"/></div>
				<div className="sl-tirelabel-noise">{this.props.noiseLevel}<small> dB</small><span></span></div>
				<div className="sl-tirelabel-desc">2009/.... <strong>- C1</strong></div>
			</div>
		);
	}
}
