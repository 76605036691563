import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {changeModal} from "../../actions/modal";
import Navigation from "../../components/layout/Navigation";
import history from "../../history";
import {setCategory} from "../../actions/category";
import type {ApplicationState} from "../../reducers";
import {configDisabled} from "../../reducers/configReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	let amount = 0;
	state.cart.items.forEach(item => {
		amount += parseInt(item.amount);
	});
	const isOnEntryPoint = history["entry"] === history.length;
	const isOnStartPage = history.location.pathname.endsWith("/shop") || history.location.pathname === ("/");
	const showResetBrandButton = isOnStartPage && (state.config.garageGroupId && state.config.brandInstances.length > 1);
	const hasBrandSwitchPageThatIsNotOpen =  (state.config.garageId != null && ownProps.location.pathname.endsWith("/shop"));

	return {
		activeModal: state.filter.activeModal,
		showBack: ownProps.location.pathname !== "/" || !!state.config.garageId,
		garageId: state.config.garageId,
		items: amount,
		hasMultipleBrands: !!state.config.garageId,
		bookmarksCount: state.bookmarks.products.length,
		isShopEnabled: state.config.isShopEnabled,
		watchlistEnabled: state.config.watchlistEnabled,
		hideNavigation: state.content.showDealerList,
		shouldRenderHomeButton: !isOnStartPage,
		shouldRenderHistoryBack: (!isOnStartPage || hasBrandSwitchPageThatIsNotOpen) && (!isOnEntryPoint || hasBrandSwitchPageThatIsNotOpen),
		showResetBrandButton: showResetBrandButton,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	const pushToBrandSwitchOrElse = (OrElseCallback: () => void) => {
		if (ownProps.location.pathname.endsWith("/shop")) {
			history.push("/");
			return dispatch(configDisabled());
		} else {
			OrElseCallback();
		}

	};
	return {
		changeModal: id => dispatch(changeModal(id)),
		handleBack: () => {
			pushToBrandSwitchOrElse(() => history.goBack());
		},
		handleHome: () => {
			history.pushHome();
		},
		searchProducts: searchPhrase => {
			dispatch(setCategory([]));
			history.pushSearch(searchPhrase);
		},
		openCart: () => {
			if (history.location.pathname.includes("/checkout")) {
				window.history.back();
			} else {
				(ownProps.myHistory ||history).pushCheckout();
			}
		},
		openBookmarks: () => {
			if (history.location.pathname.includes("/bookmarks")) {
				window.history.back();
			} else {
				(ownProps.myHistory || history).pushBookmarks();
			}
		},
		resetBrand: () => {
			history.replace("/");
		}
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation)));
