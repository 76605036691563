import i18next from "i18next";
import resources from "./index.js";

const i18n = i18next.createInstance();

export type translate = string => string;

i18n
	.init({
		debug: false,
		fallbackLng: "en",
		defaultNs: "translation",
	});

i18n.addResourceBundle("de", "translation", resources.de);
i18n.addResourceBundle("en", "translation", resources.en);
i18n.addResourceBundle("el", "translation", resources.el);
i18n.addResourceBundle("es", "translation", resources.es);
i18n.addResourceBundle("it", "translation", resources.it);
i18n.addResourceBundle("hu", "translation", resources.hu);
i18n.addResourceBundle("nl", "translation", resources.nl);
i18n.addResourceBundle("no", "translation", resources.no);
i18n.addResourceBundle("pl", "translation", resources.pl);
i18n.addResourceBundle("ru", "translation", resources.ru);
i18n.addResourceBundle("da", "translation", resources.da);
i18n.addResourceBundle("cs", "translation", resources.cs);
i18n.addResourceBundle("fr", "translation", resources.fr);
i18n.addResourceBundle("pt", "translation", resources.pt);

export default i18n;
