// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const OpelTheme = props => {
	import("./opel.lazy.scss");
	return <>
		{props.children}
		<ContrastColorUpdater/>
	</>;
};

export default OpelTheme;
