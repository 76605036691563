// @flow
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {setPickupLocation} from "../../actions/location";
import type {GroupMember} from "../../models/GroupMember";
import type {ApplicationState} from "../../reducers";
import {PickupLocationInfo} from "../../components/pickupLocation/PickupLocationInfo";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		location: state.location.pickupLocation,
		moduleLanguage: state.config.moduleLanguage,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setLocation: (location: GroupMember) => dispatch(setPickupLocation(location)),
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PickupLocationInfo));
