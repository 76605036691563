// @flow
import React from "react";
import "./BrowserNotSupported.scss";
import {withTranslation} from "react-i18next";
import type {translate} from "../../i18next";
import i18n from "../../i18next";

export type BrowserNotSupportedProps = {
	t: translate
};

function BrowserNotSupported(props: BrowserNotSupportedProps) {
	i18n.changeLanguage(navigator.language || navigator.userLanguage || "en");

	return <div className="hpm-browserNotSupported">
		<div className="hpm-notSupportedHeadline">
			{props.t("browserNotSupported.title")}
		</div>

		<div className="hpm-notSupportedText">
			{props.t("browserNotSupported.text")}
		</div>

		<div className="hpm-supportedBrowsers">
			<a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer"><div className="hpm-chrome"/></a>
			<a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener noreferrer"><div className="hpm-firefox"/></a>
			<a href="https://www.microsoft.com/en-us/edge/" target="_blank" rel="noopener noreferrer"><div className="hpm-edge"/></a>
			<a href="https://www.apple.com/de/safari/" target="_blank" rel="noopener noreferrer"><div className="hpm-safari"/></a>
		</div>
	</div>;
}

export default withTranslation()(BrowserNotSupported);
