// @flow
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {setSearchPhrase} from "../../actions/filter";
import SearchBar from "../../components/layout/SearchBar";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		value: state.filter.searchPhrase,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleChange: value => dispatch(setSearchPhrase(value)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchBar));
