import {connect} from "react-redux";
import {setFilter} from "../../actions/filter";
import FilterPageSort from "../../components/Filter/FilterPageSort";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	const {filter, sortOptions} = state.filter;
	return {
		sortOptions: sortOptions,

		isOptionSelected: value => filter.sort.fieldname === value.fieldname && filter.sort.direction === value.direction,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setSort: (value: { fieldname: string, direction: string }) => {
			dispatch(setFilter("sort", value));
		},
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterPageSort));
