// @flow

import React from "react";
import type { Product } from "../../models/Product";
import type { translate } from "../../i18next";
import { v4 as uuidv4 } from "uuid";
import "./WishlistPage.scss";
import WishlistItem from "./WishlistItem";
import WishlistShareFormContainer from "../../container/wishlist/WishlistShareFormContainer";
import EmptyWishlist from "./EmptyWishlist";
import ReactToPrint from "react-to-print";
import wishlistIcon from "../../assets/svg/outline-star_border-24px.svg";
import RootNodeProvider from "../../helper/RootNodeProvider";
import WelcomeMessageModal from "../layout/WelcomeMessageModal";

export type WishlistPageProps = {
	t: translate,
	products: Product[],
	hasProducts: boolean,
	removeProduct: Product => void,
	wishlistSuccess: boolean,
	wishlistError: boolean,
	resetMailing: () => void,
	isLeadModuleEnabled: boolean,
	showWelcomeMessage: boolean,
	resetWelcomeMessage: () => void,
	dealerName: string,
	dealerImageURL: string,
	fallbackImageURL: string,
	dealerAddress: string,
	dealerPhoneNumber: string,
	dealerEmail: string,
	dealerShopBaseUrl: string,
	dealerLogoUrl: string,
}

export type WishlistPageState = {
	shareModalOpen: boolean
}

class WishlistPage extends React.Component<WishlistPageProps, WishlistPageState> {
	constructor(props: WishlistPageProps) {
		super(props);
		this.state = {shareModalOpen: false};
	}

	toggleShareModal = () => {
		this.setState({shareModalOpen: !this.state.shareModalOpen});
	};

	render() {
		return (
			<div className="hpm-wishlistPage">
				<WelcomeMessageModal
					dealerName={this.props.dealerName}
					dealerImageURL={this.props.dealerImageURL}
					fallbackImageURL={this.props.fallbackImageURL}
					showWelcomeMessage={this.props.showWelcomeMessage}
					resetWelcomeMessage={this.props.resetWelcomeMessage}
					t={this.props.t}
				/>
				{this.props.hasProducts ? (
					<div>
						{this.state.shareModalOpen ? (
							<WishlistShareFormContainer closeForm={this.toggleShareModal}/>
						) : (
							<>
								<div className="hpm-wishlistContentBox">
									<div className="hpm-wishlistContent">
										<div className="hpm-wishlistsTitle">
											<span className={"hpm-wishlistLogo"}>
												<img src={this.props.dealerLogoUrl} alt={"dealer logo"}/>
											</span>
											<span className={"hpm-wishlistSymbol"}>
												{/* eslint-disable */}
												<span className={"hpm-beforeWishlist"}>
													<img src={wishlistIcon} alt={""}/>
	                      </span>
												{this.props.t("bookmarks.bookmarksLabel")}
											</span>
											<h2 className={"hpm-wishlistName"}>
												{this.props.dealerName}
											</h2>
										</div>
										<div className="hpm-wishlistItems">
											<h2 className={"hpm-wishlistLabel"}>{this.props.t("bookmarks.bookmarksLabel")}</h2>
											{this.props.products.map(product => (
												<WishlistItem
													product={product}
													key={uuidv4()}
													removeProduct={this.props.removeProduct}
												/>
											))}
										</div>
										<div className={"hpm-buttonWrapper hpm-noPrint"}>
											{this.props.isLeadModuleEnabled && (
												<div className="hpm-sendButton">
													<button
														type={"button"}
														onClick={() => this.toggleShareModal()}
													>
														{this.props.t("bookmarks.sendBookmarks")}
													</button>
												</div>
											)}
											<div className="hpm-printButton">
												<ReactToPrint
													content={() => RootNodeProvider.getRootNode()}
													documentTitle={this.props.t(
														"bookmarks.bookmarksLabel"
													)}
													trigger={() => (
														<button
															type="button"
															onClick={() => this.toggleShareModal()}
														>
															{this.props.isLeadModuleEnabled ? (
																<div className={"hpm-printerIconWrapper"}>
																	<div className={"hpm-printerIcon"}/>
																	<div className={"hpm-printerText"}>
																		{this.props.t("bookmarks.print")}
																	</div>
																</div>
															) : (
																<div className={"leadModuleDisable"}>
																	{this.props.t("bookmarks.printBookmarks")}
																</div>
															)}
														</button>
													)}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className={"hpm-wishlistFooter"}>
									<div className={"left"}>
										<div>{this.props.dealerPhoneNumber}</div>
										<div>{this.props.dealerEmail}</div>
										{this.props.dealerShopBaseUrl && (
											<div>{this.props.dealerShopBaseUrl}</div>
										)}
									</div>
									<div className={"right"}>
										<div>{this.props.dealerName}</div>
										<div>{`${this.props.dealerAddress.postalCode} ${this.props.dealerAddress.town}, ${this.props.dealerAddress.street} ${this.props.dealerAddress.streetNumber}`}</div>
									</div>
								</div>
							</>
						)}
					</div>
				) : (
					<EmptyWishlist t={this.props.t}/>
				)}
			</div>
		);
	}
}

export default WishlistPage;
