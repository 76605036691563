import {connect} from "react-redux";
import Filter from "../../components/Filter/Filter";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {FILTER} from "../../reducers/filterReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		filterInstance: state.filter.filterInstance === FILTER.SUB_CATEGORY ? FILTER.SUB_CATEGORY : FILTER.CATEGORIES
	};
}

export default withTranslation()(connect(mapStateToProps, null)(Filter));
