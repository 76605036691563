// @flow

import RootNodeProvider from "./RootNodeProvider";

export type eventType = "PAGE_LOADED" | "HPM_PAGE_LOADED";

export default class EventEmitter {
	emitEvent = (type: eventType, detail: any) => {
		const event = new CustomEvent(type, {detail});
		window.dispatchEvent(event);
		const rootNode = RootNodeProvider.getRootNode();
		if (rootNode) rootNode.dispatchEvent(event);
	};
}
