import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CheckoutPage from "../../components/checkout/CheckoutPage";
import {checkCart, getPaymentMethods, setPage} from "../../actions/cart";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		isCartEmpty: !state.cart.items.length,
		showOrderFinishPage: state.order.showOrderFinishPage,
		isPaymentPending: !!state.order.order,
		page: state.order.currentPage,
		maxPage: state.order.maxPage,
		minPage: state.order.minPage,
		isLoading: state.order.isLoading,
		paypalClientId: state.cart.paypalClientId,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setPage: page => dispatch(setPage(page)),
		validateCart: () => dispatch(checkCart()),
		getPaymentMethods: () => dispatch(getPaymentMethods()),
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CheckoutPage));
