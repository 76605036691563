import i18n from "../i18next";
import React from "react";

type Props = {
	children: any,
	language: any,
};

const LanguageProvider = (props: Props) => {
	const {language} = props;
	React.useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	return <div/>;
};

export default LanguageProvider;
