// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {PaymentProviderTypes} from "../models/PaymentRequests";
import {NO_PAYPAL_CONFIGURED} from "../actions/cart";
import {PAYMENT_PROVIDER} from "../models/PaymentRequests";

export type PayPalAccount = {
	clientId: string,
	payee?: string,
};

export type CashAccount = {};

export type PrepaidAccount = {
	dealerNo: string,
	payee: string,
	bank: string,
	iban: string,
	bic: string,
	payableAtDays: number,
};

export type PaymentFacility = {
	account: PayPalAccount | PrepaidAccount | CashAccount,
	paymentProvider: PaymentProviderTypes,
	paymentMethod: ?string,
};

export type PaymentMethod = {
	paymentMethod: string,
	paymentProvider: string,
	account: ?string,
};

export type PaymentMethodResponse = {
	paymentMethods: PaymentMethod[],
};

export type PaymentFacilitySessionResponse = {
	session: {
		paymentProvider: { code: PaymentProviderTypes },
		account: PayPalAccount | PrepaidAccount | CashAccount,
	},
};

export type PayPalClientIdResponse = {
	clientId: string,
}

export default class PaymentApi {
	api: Axios;

	constructor() {
		this.api = axios.create({
			baseURL: Configuration.value("apiPaymentUrl"),
			headers: {
				"Content-Type": "application/json",
				"X-ModuleType": "HPM"
			},
		});
	}

	getPaypalClientId = async (dealerId: string): Promise<PayPalClientIdResponse> => {
		return this.api.get(`payment/paypal-clientId/${dealerId}`)
			.then(r => r.data)
			.catch(e => {
				return {clientId: NO_PAYPAL_CONFIGURED};
			});
	};

	getPaymentMethods = async (dealerId: string): Promise<PaymentMethodResponse> => {
		return this.api.get(`payment/methods/dealer/${dealerId}`)
			.then(response => response.data)
			.catch(err => {
				console.error("Error on loading paymentMethods", err);
				throw err;
			});
	};

	assignPaymentForOrder = async (orderNo: string, method: PaymentMethod): Promise<PaymentFacilitySessionResponse> => {
		const body = {
			paymentProvider: method.paymentProvider.toUpperCase().replace("-", "_"),
			paymentMethod: method.paymentMethod.toUpperCase().replace("-", "_"),
		};
		return this.api.post(`payment/provider/assign/${orderNo}`, body)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				/* eslint-disable-next-line */
				console.log(err);
				throw err;
			});
	};

	initiatePayment = async (orderId: string, paymentRequest: PaymentRequest) => {
		const request = {
			paymentProvider: paymentRequest.paymentProvider,
			paymentReference: paymentRequest.paymentReference ? paymentRequest.paymentReference.id : paymentRequest.paymentProvider
		};
		return this.api.put(`/payment/initiate/${orderId}`, request)
			.then(response => response.data);
	};

	finishCapturePayment = async (orderId: string, paymentReference: string) => {
		const request = {
			paymentProvider: PAYMENT_PROVIDER.PAYPAL_CAPTURE.toUpperCase().replace("-", "_"),
			paymentReference: paymentReference,
		};
		return this.api.post(`/payment/reference/assign/${orderId}`, request)
			.then(response => response.data);
	};
}
