import {connect} from "react-redux";
import {I18nextProvider} from "react-i18next";
import i18n from "../i18next";
import type {ApplicationState} from "../reducers";

function mapStateToProps(state: ApplicationState) {
	if (state.config.moduleLanguage && (i18n.language !== state.config.moduleLanguage)) {
		i18n.changeLanguage(state.config.moduleLanguage);
	}
	return {
		i18n: i18n
	};
}

export default connect(mapStateToProps)(I18nextProvider);
