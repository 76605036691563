import {connect} from "react-redux";
import {setFilterInstance} from "../../actions/filter";
import FilterStartPage from "../../components/Filter/FilterStartPage";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../reducers";
import {RoleTypes} from "../../models/Role";
import {resetMarker, setMarker} from "../../actions/marker";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		mainOptions: state.filter.mainOptions,
		filter: state.filter.filter,
		vehicleModels: state.filter.vehicleModels,
		vehicleSeries: state.filter.filterStats.vehicleSeries,
		filterCategories: state.filter.filterStats.categories,
		showSort: true,
		showPriceRange: state.config.role !== RoleTypes.DISTRIBUTOR,
		showCarline: true,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		setFilterInstance: (instance, subCat) => {
			dispatch(setFilterInstance(instance, subCat));
		},
		setMarker: marker => {
			dispatch(setMarker(marker));
		},
		resetMarker: () => {
			dispatch(resetMarker());
		}
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FilterStartPage));
