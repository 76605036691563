import OrderFinished from "../../../components/checkout/mainContentBox/OrderFinished";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {makeCartEmpty} from "../../../actions/cart";
import {
	clearOrderDetails,
	getOrderState,
	hideOrderFinished, resetOrderState
} from "../../../actions/order";
import history from "../../../history";
import type {ApplicationState} from "../../../reducers";
import {OrderState} from "../../../models/Order";
import {PAYMENT_PROVIDER} from "../../../models/PaymentRequests";

const mapStateToProps = state => {
	return {
		paymentProvider: state.order.payment.paymentProvider,
		orderNo: state.order.order.orderNo,
		orderState: !state.order.orderState ? OrderState.UNDEFINE : state.order.orderState,
		shouldCheckOrderState: state.order.payment.paymentProvider === PAYMENT_PROVIDER.PAYPAL_EXPRESS
	};
};

function mapDispatchToProps(dispatch, state: ApplicationState) {
	return {
		getOrderState: (orderNo: string) => {
			dispatch(getOrderState(orderNo));
		},
		clearOrderDetails: () => dispatch(clearOrderDetails()),
		makeCartEmpty: () => dispatch(makeCartEmpty()),
		goToStartpage: () => {
			dispatch(hideOrderFinished());
			history.pushHome();
		},
		resetOrderState: () => dispatch(
			resetOrderState()
		)
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderFinished));
