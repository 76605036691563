import React from "react";
import type {Item} from "../../../models/Item";
import {getPrice, getRRP} from "../../../models/Item";
import {currencyConverter} from "../../../helper/currency";
import {Link} from "react-router-dom";
import type {translate} from "../../../i18next";
import "./OrderSummaryCartItem.scss";

export type CartItemOverviewProps = {
	cartItem: Item,
	t: translate
};

const OrderSummaryCartItem = (props: CartItemOverviewProps) => {
	const detailPageURI = `/product/${props.cartItem.product.id}/${encodeURIComponent(props.cartItem.product.name)}`;
	const unitPrice = props.cartItem.product.unitPrice;

	return (
		<div className="hpm-orderSummaryCartItem">
			<div className="hpm-cartImage">
				<Link to={detailPageURI}>
					<img alt="" src={props.cartItem.product.galleryImage.previewImage.attachmentUrl}/>
				</Link>
			</div>
			<div className="hpm-tables">
				<table className={"hpm-productName"}>
					<tbody>
						<tr>
							<td><Link to={detailPageURI}>{props.cartItem.product.name}</Link></td>
						</tr>
					</tbody>
				</table>
				<div className="hpm-left">
					<table>
						<tbody>
							<tr>
								<td>{props.t("cart.productInformation.articleNumberLabel")}:</td>
								<td>{props.cartItem.product.articleNumber}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="hpm-right">
					<table>
						<tbody>
							<tr>
								<td>{props.t("cart.productInformation.amountLabel")}:</td>
								<td>{props.cartItem.amount}</td>
							</tr>
							<tr>
								<td>{props.t("cart.productInformation.productPriceLabel")}:</td>
								{props.cartItem.product.recommendedRetailPrice ? (
									<td>
										<span className={"hpm-discount summary-price"}>{currencyConverter(getPrice(props.cartItem))}</span>
										<span className={"hpm-price"}>{currencyConverter(getRRP(props.cartItem))}</span>
										{unitPrice && (
											<div>
												<small className="unit-price">({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²"
													: unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
												</small>
											</div>
										)}
									</td>
								) : (
									<td>
										<span className={"hpm-discount  summary-price"}>{currencyConverter(getPrice(props.cartItem))}</span>
										{unitPrice && (
											<div>
												<small className="unit-price">({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²"
													: unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
												</small>
											</div>
										)}
									</td>
								)}
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default OrderSummaryCartItem;
