import React from "react";
import type { Product } from "../../models/Product";
import { currencyConverter } from "../../helper/currency";
import type { translate } from "../../i18next";
import "./GridElement.scss";
import history from "../../history";
import { Link } from "react-router-dom";
import PickupLocationContainer from "../../container/pickupLocation/PickupLocationContainer";

export type GridElementProps = {
	t: translate,
	product: Product,
	showPrice: boolean,
	needsPickupLocation: boolean,
}

const GridElement = (props: GridElementProps) => {
	const galleryImage = props.product.galleryImage || {};
	const TeaserImageStyle = {
		backgroundImage: "url(" + (galleryImage.previewImage || {}).attachmentUrl + ")"
	};
	const {carlines, price, distributionChannels, unitPrice} = props.product;
	const isMountingOnly = distributionChannels.mounting && !distributionChannels.shipping && !distributionChannels.pickup;

	const renderFitments = () => {
		const FITMENTS_MAX_LENGTH = 34;
		let fitmentsString = carlines[0] || props.t("productDetail.all");

		for (let i = 1; i < carlines.length; i++) {
			if (fitmentsString.length + carlines[i].length > FITMENTS_MAX_LENGTH) {
				return (`${fitmentsString}, ...`);
			}
			fitmentsString += (`, ${carlines[i]}`);
		}
		return fitmentsString;
	};

	let className = "hpm-gridElement";
	if (props.showPrice && props.product.recommendedRetailPrice) {
		className = `${className} discounted`;
	}
	if (props.product.distributionChannels.mounting) {
		className = `${className} mounting`;
	}

	return (
		<Link to={history.getProductUrl(props.product.id, props.product.name)} className={className}>
			<div>
				<div className="hpm-teaserImageWrapper">
					<div className="hpm-teaserImage" style={TeaserImageStyle}/>
				</div>
				<div className="hpm-textWrapper">
					<div className="hpm-title" title={props.product.name}>{props.product.name}</div>
					<div className="hpm-description">{renderFitments()}</div>
				</div>
				<div className="hpm-gridPriceBox">
					{props.showPrice && props.product.recommendedRetailPrice && (
						<div className="hpm-retailPrice">
							{currencyConverter(props.product.recommendedRetailPrice)}
						</div>
					)}
					{(props.showPrice && props.needsPickupLocation && isMountingOnly) ? (
						<div className="hpm-price">
							<PickupLocationContainer linkLabel={props.t("location.headline")} classNames={"gridLocationSelect"}
									linkStyle={{
										display: "flex",
										width: "100%",
										justifyContent: "flex-end",
										fontSize: "0.85rem",
										whiteSpace: "nowrap"
									}}
									redirectToOverview={true}
							/>
						</div>
					) : (
						props.showPrice && (
							<div className="hpm-price">
								{price ? currencyConverter(price) : props.t("productDetail.priceOnRequest")}
							</div>
						)
					)}
					{price && unitPrice && (
						<div className="unit-price">
							<small >
								({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
							</small>
						</div>
					)}
				</div>
			</div>
		</Link>
	);
};

export default GridElement;
