import React from "react";
import Modal from "react-modal";
import "./Footer.scss";
import type {translate} from "../../i18next";
import {Trans} from "react-i18next";

const modalStyles = {
	overlay: {
		zIndex: "100000"
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "1px solid grey",
		width: "calc(90%)",
		maxWidth: "1000px",
		minHeight: "200px",
		maxHeight: "90vh",
		overflowY: "auto",
	}
};

const downloadButtonStyles = {
	display: "flex",
	justifyContent: "end",
	marginRight: "3em"
};

export type FooterProps = {
	t: translate,
	showFooter: boolean,
	legalTerms: string,
	revocationTerms: string,
	paymentTerms: string,
	privacyPolicy: string,
	imprintText: string,
	dealerId: string,
	notificationUrl: string
}
export type FooterState = {
	showPrivacyPolicy: boolean,
	showLegalTerms: boolean,
	showRevocationTerms: boolean,
	showPaymentTerms: boolean,
	showImprintText: boolean,
}
export const initialState = {
	showLegalTerms: false,
	showRevocationTerms: false,
	showPaymentTerms: false,
	showPrivacyPolicy: false,
	showImprintText: false,
};

class Footer extends React.Component<FooterProps, FooterState> {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	toggleAGB = () => {
		this.setState({...initialState, showLegalTerms: !this.state.showLegalTerms});
	};
	togglePrivacyPolicy = () => {
		this.setState({...initialState, showPrivacyPolicy: !this.state.showPrivacyPolicy});
	};
	toggleReturnAgreement = () => {
		this.setState({...initialState, showRevocationTerms: !this.state.showRevocationTerms});
	};
	togglePayment = () => {
		this.setState({...initialState, showPaymentTerms: !this.state.showPaymentTerms});
	};
	toggleImprintText = () => {
		this.setState({...initialState, showImprintText: !this.state.showImprintText});
	};

	render() {
		if (!this.props.showFooter) return null;
		return <div className="hpm-footer_navigation hpm-noPrint">
			{this.props.legalTerms &&
			<React.Fragment>
				<button type={"button"} onClick={this.toggleAGB}>{this.props.t("footer.legalterms")}</button>
				<Modal isOpen={this.state.showLegalTerms}
					   style={modalStyles}
					   shouldCloseOnOverlayClick={true}
					   onRequestClose={this.toggleAGB}
				>
					<button type={"button"} onClick={this.toggleAGB} className="hpm-modalClose"/>
					<div style={downloadButtonStyles}>
						<a className={"file-download-button"} id="file-download-button"
						   download={this.props.t("cart.calculation.legalTerms.fileName")} href={`${this.props.notificationUrl}/download/legalTerms/${this.props.dealerId}`}>{this.props.t("cart.calculation.legalTerms.button")}</a></div>
					<div dangerouslySetInnerHTML={{__html: this.props.legalTerms}}
						 className="hpm-modalContent" style={{fontFamily: "var(--font100)"}}/>
				</Modal>
			</React.Fragment>
			}
			{this.props.revocationTerms && <React.Fragment>
				<button type={"button"} onClick={this.toggleReturnAgreement}>{this.props.t("footer.return")}</button>
				<Modal isOpen={this.state.showRevocationTerms}
					   style={modalStyles}
					   shouldCloseOnOverlayClick={true}
					   onRequestClose={this.toggleReturnAgreement}
				>
					<button type={"button"} onClick={this.toggleReturnAgreement} className="hpm-modalClose"/>
					<div dangerouslySetInnerHTML={{__html: this.props.revocationTerms}}
						 className="hpm-modalContent"
						 style={{fontFamily: "var(--font100)"}}/>
				</Modal>
			</React.Fragment>}
			{this.props.paymentTerms && <React.Fragment>
				<button
					type={"button"}
					onClick={this.togglePayment}>{this.props.t("footer.paymentAndShipping")}</button>
				<Modal isOpen={this.state.showPaymentTerms}
					   style={modalStyles}
					   shouldCloseOnOverlayClick={true}
					   onRequestClose={this.togglePayment}
				>
					<button type={"button"} onClick={this.togglePayment} className="hpm-modalClose"/>
					<div dangerouslySetInnerHTML={{__html: this.props.paymentTerms}}
						 className="hpm-modalContent"
						 style={{fontFamily: "var(--font100)"}}/>
				</Modal>
			</React.Fragment>}
			{(this.props.privacyPolicy) && <React.Fragment>
				<button
					type={"button"}
					onClick={this.togglePrivacyPolicy}>{this.props.t("footer.privacyPolicy")}</button>
				<Modal isOpen={this.state.showPrivacyPolicy}
					   style={modalStyles}
					   shouldCloseOnOverlayClick={true}
					   onRequestClose={this.togglePrivacyPolicy}
				>
					<button type={"button"} onClick={this.togglePrivacyPolicy} className="hpm-modalClose"/>
					<div dangerouslySetInnerHTML={{__html: this.props.privacyPolicy}}
						 className="hpm-modalContent"
						 style={{fontFamily: "var(--font100)"}}/>
				</Modal>
			</React.Fragment>}
			{(this.props.imprintText) && <React.Fragment>
				<button
					type={"button"}
					onClick={this.toggleImprintText}>{this.props.t("footer.imprint")}</button>
				<Modal isOpen={this.state.showImprintText}
					   style={modalStyles}
					   shouldCloseOnOverlayClick={true}
					   onRequestClose={this.toggleImprintText}
				>
					<button type={"button"} onClick={this.toggleImprintText} className="hpm-modalClose"/>
					<div dangerouslySetInnerHTML={{__html: this.props.imprintText}}
						 className="hpm-modalContent"
						 style={{fontFamily: "var(--font100)"}}/>
					<br/><br/>
					<hr/>
					<br/>
					<div className="hpm-modalContent" style={{fontFamily: "var(--font100)"}}>
						<Trans i18nKey="footer.slImprint">
							<b>Betreiber des Zubehör-Navigators:</b><br/>
							Dr. Schnell & Dr. Hensel GmbH<br/>
							Stangenland 2<br/>
							18146 Rostock<br/>
							Deutschland<br/><br/>
							Telefon: +49 (0)381 666 989-100<br/>
							E-Mail: <a href="mailto:kontakt@it-tecture.de">kontakt@it-tecture.de</a><br/>
							Geschäftsführung: Dr. Arne Schnell, Dr. Arne Hensel<br/>
							Amtsgericht Rostock: HRB 9884<br/>
							USt.-IdNr.: DE 814114196<br/><br/>
							Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:<br/>
							Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> finden.
							Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und grundsätzlich nicht bereit.
						</Trans>
					</div>
				</Modal>
			</React.Fragment>}
		</div>;
	}
}

export default Footer;
