// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const SubaruTheme = props => {
	import("./subaru.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default SubaruTheme;
