// @flow

import React from "react";
import GalleryThumbnail from "./GalleryThumbnail";
import type {Image, Video} from "../../models/Attachments";
import {Controlled as ControlledZoom} from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./Gallery.scss";

type GalleryProps = {
	images: Image[],
	videos: Video[],
	galleryImage?: Image,
}

type GalleryState = {
	currentAttachment: Video | Image;
	isZoomed: boolean,
}

class Gallery extends React.Component<GalleryProps, GalleryState> {
	constructor(props) {
		super(props);
		this.imageRef = React.createRef();
		this.state = {
			isZoomed: false,
			currentAttachment: this.props.galleryImage || this.props.images[0] || {mimeType: "image/"},
			imageHeight: null,
			imageWidth: null,
		};
	}

	handleZoomChange = (shouldZoom: boolean) => {
		this.setState({
			isZoomed: shouldZoom,
			imageHeight: !this.state.imageHeight ? this.imageRef.current.clientHeight : this.state.imageHeight,
			imageWidth: !this.state.imageHeight ? this.imageRef.current.clientWidth : this.state.imageWidth
		});
	};

	selectAttachment(media) {
		this.setState({currentAttachment: media});
	}

	renderPreview() {
		if (this.props.images.length + this.props.videos.length > 1) {
			return <React.Fragment>
				{this.props.images.map((image: Image, index) => {
					return <GalleryThumbnail
						key={`IMG${index}`}
						type={"Image"}
						media={image}
						selectAttachment={() => this.selectAttachment(image)}
					/>;
				})}
				{this.props.videos.map((video, index) => {
					return <GalleryThumbnail
						key={`VID${index}`}
						type={"Video"}
						media={video}
						selectAttachment={() => this.selectAttachment(video)}
					/>;
				})}
			</React.Fragment>;
		}
	}

	renderMedia() {
		const imageStyle = {
			width: "auto",
			margin: "auto",
			maxHeight: this.state.imageHeight || "100%",
			maxWidth: this.state.imageWidth || "100%",
			display: "block",
			height: "auto"
		};

		return this.state.currentAttachment.mimeType.startsWith("image/")
			? <ControlledZoom
				zoomMargin={16}
				wrapElement="figure"
				onZoomChange={(shouldZoom: boolean) => this.handleZoomChange(shouldZoom)}
				isZoomed={this.state.isZoomed}>
				<img ref={this.imageRef} src={this.state.currentAttachment.attachmentUrl} style={imageStyle}
					 alt={this.state.currentAttachment.name}/>
			</ControlledZoom>
			: <div className="hpm-galleryVideo">
				<video controls autoPlay>
					<source
						src={this.state.currentAttachment.attachmentUrl}
					/>
					Your browser does not support the video tag.
				</video>
			</div>;
	}

	render() {
		return <div className="hpm-gallery">
			<div className="hpm-image">
				{this.renderMedia()}
			</div>
			<div className="hpm-previewImages">
				{this.renderPreview()}
			</div>
		</div>;
	}
};

export default Gallery;
