// @flow
import * as React from "react";
import {Trans} from "react-i18next";
import {BrandContext} from "./App";

export type Address = {
	street: string,
	postalCode: string,
	streetNumber: string,
	town: string,
}

export type DealerConfiguration = {
	leadModuleEnabled: boolean,
	productPricesDisabled: boolean,
	shopEnabled: boolean,
}

export type DealerType = {
	name: string,
	identifier: {
		dealerNo: string,
		marketplace: any,
	},
	dealerConfiguration: DealerConfiguration,
	distance: string,
	address: Address,
	productUrl: string,
	shopBaseUrl: string,
}

type Props = { dealer: DealerType };
export const Dealer = (props: Props) => {
	const brand = React.useContext(BrandContext);
	const selectDealer = (dealerId: string) => {
		const url = new URL(window.location.href);
		url.searchParams.append("dealer", dealerId);
		return url.href;
	};
	const selectBaseUrl = (baseUrl: string, dealerId: ?string, brand: ?string) => {
		const url = new URL(window.location.href);
		let target = "";
		baseUrl = baseUrl + url.search + url.hash;
		if (!url.hash) {
			baseUrl = baseUrl + `#/brand/${brand}/shop`.toLowerCase();
		}
		else {
			baseUrl = baseUrl + `brand/${brand}/shop`.toLowerCase();
		}
		if (!baseUrl.includes("http")) {
			baseUrl = "https://" + baseUrl;
		}
		try {
			target = new URL(baseUrl);
			return target.href;
		} catch (e) {
			return selectDealer(dealerId);
		}
	};

	const renderDealerAddress = () => {
		const {address} = props.dealer;
		return address
			? <React.Fragment>
				{address.street + " " + address.streetNumber} <br/>
				{address.postalCode + " " + address.town} <br/>
				{!!props.dealer.distance &&
				<small>{props.dealer.distance} {!!props.dealer.distance}<Trans i18nKey="nsc.distance"/></small>}
			</React.Fragment>
			: <React.Fragment>&nbsp;</React.Fragment>;
	};

	const dealerIcon = props.dealer.dealerConfiguration.shopEnabled
		? "dealerIcon shop"
		: props.dealer.dealerConfiguration.productPricesDisabled
			? "dealerIcon location"
			: "dealerIcon euro";

	return <div className={"dealer"} key={props.dealer.identifier.dealerNo}>
		<span>{props.dealer.name}</span>
		<span>{renderDealerAddress()}</span>
		<span className={dealerIcon}/>
		{
			props.dealer.shopBaseUrl
				? <a href={selectBaseUrl(props.dealer.shopBaseUrl, props.dealer.identifier.dealerNo, brand)}><Trans i18nKey="nsc.visitGarage"/> </a>
				: <a href={selectDealer(props.dealer.identifier.dealerNo)}><Trans i18nKey="nsc.visitGarage"/> </a>
		}
	</div>;
};
