import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import WishlistPage from "../../components/wishlist/WishlistPage";
import {removeBookmark, resetWelcomeMessage} from "../../actions/bookmarks";
import type {Product} from "../../models/Product";
import type {ApplicationState} from "../../reducers";

function mapStateToProps(state: ApplicationState) {
	return {
		isLeadModuleEnabled: state.config.isLeadModuleEnabled,
		products: state.bookmarks.products,
		hasProducts: state.bookmarks.products.length,
		showWelcomeMessage: state.bookmarks.showWelcomeMessage,
		dealerName: state.config.dealerDetails.name,
		dealerImageURL: state.config.dealerDetails.garagePictureUrl,
		fallbackImageURL: state.content.brand.brandImageUri,
		dealerAddress: state.config.dealerDetails.address,
		dealerPhoneNumber: state.config.dealerDetails.phone,
		dealerEmail: state.config.dealerDetails.email,
		dealerShopBaseUrl: state.config.dealerDetails.shopBaseUrl,
		dealerLogoUrl: state.config.dealerDetails.dealership.manufacturerLogoUrl,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		removeProduct: (product: Product) => dispatch(removeBookmark(product)),
		resetWelcomeMessage: () => dispatch(resetWelcomeMessage())
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WishlistPage));
