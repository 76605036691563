// @flow
export const OrderState = {
	UNDEFINE: "undefine",
	PROCESSING: "processing",
	AUTHORIZED: "authorized",
	FAILED: "failed"
};

export type PlaceOrderResponse = {
	orderNo: {index: string},
	dealerNo: {index: string},
}

export type OrderItemShippingMethod = {
	code: string
}

export type OrderItemUnit = {
	code: string
}

export type OrderItemAmount = {
	amount: number,
	unit: OrderItemUnit
}

export type OrderItemProduct = {
	dealerId: string,
	productNo: string,
	purchaseOrderNo: string,
	productName: ?string,
	unitPrice: {pricePerUnit: {cost: number, currency: {code: string}}},
}

export type OrderItem = {
	product: OrderItemProduct,
	amount: OrderItemAmount,
	shippingMethod: OrderItemShippingMethod,
}

export type OrderPaymentProvider = {
	code: string
}

export type OrderAddress = {
	firstName: string,
	name: string,
	street: string,
	city: string,
	postalCode: string,
	countryCode: string
}

export type OrderCustomerMail = {
	mailTo: string
}

export type Order = {
	dealerNo: {
		index: string
	},
	customerMail: OrderCustomerMail,
	customerPhone: string,
	comment: ?string,
	vin: ?string,
	shippingAddress: OrderAddress,
	billingAddress: OrderAddress,
	items: OrderItem[],
	assembler: ?{
		index: string,
	},
	orderState: ?OrderState
}

export type OrderItemsResponse = {
	orderNo: string,
	referenceNumber: string,
	dealerId: string,
	orderItems: OrderItem[],
}

export type CartValidationRequest = {
	dealerId: string,
	items: OrderItem[],
	paymentProvider: OrderPaymentProvider,
	assembler: ?{
		index: string,
	}
}
