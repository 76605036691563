// @flow
import type {ApplicationState} from "../reducers";
import type Category from "../models/Category";
import {categoryReset, categorySet} from "../reducers/filterReducer";

export const setCategory = (categoryName: string = "") => {
	if (!categoryName) {
		return resetCategory();
	}
	return (dispatch, getState: () => ApplicationState) => {
		const mainCategories: Category[] = getState().filter.filterStats.categories;
		const subCategories: Category[] = [].concat.apply([], mainCategories.map(cat => cat.categories));
		const selectedCategories: Category[] = [...mainCategories, ...subCategories]
			.filter(cat => categoryName === cat.name || categoryName === cat.key || categoryName === cat.title);

		let selectedCategory = selectedCategories.find(cat => cat.name === categoryName || cat.key === categoryName || cat.title === categoryName);
		if (!selectedCategory) {
			selectedCategory = selectedCategories.find(cat => cat.key.includes(categoryName));
		}

		dispatch(categorySet({
			categories: selectedCategories,
			selectedCategory: selectedCategory ? selectedCategory.title : "",
			categoryKey: selectedCategory ? selectedCategory.key : null
		}));
	};
};

export function resetCategory() {
	return categoryReset();
}
