import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import FilterModal from "../../components/Filter/FilterModal";
import {changeModal} from "../../actions/modal";
import {resetFilter, setFilterInstance} from "../../actions/filter";
import {getPopularProducts, getProducts} from "../../actions/products";
import history from "../../history";
import {FILTER} from "../../reducers/filterReducer";
import type {ApplicationState} from "../../reducers";
import {productsReset} from "../../reducers/contentReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		isDirty: state.filter.isDirty,
		activeModal: state.filter.activeModal,
		filterInstance: state.filter.filterInstance === FILTER.SUB_CATEGORY ? FILTER.SUB_CATEGORY : FILTER.CATEGORIES,
		topFilterInstance: FILTER.CATEGORIES,
		category: state.filter.filter.categories.length ? state.filter.filter.categories[0] : null,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		changeModal: id => dispatch(changeModal(id)),
		setFilterInstance: instance => dispatch(setFilterInstance(instance)),
		resetFilter: () => {
			dispatch(resetFilter());
			dispatch(setFilterInstance(FILTER.CATEGORIES));
			dispatch(productsReset());
			dispatch(getPopularProducts());
			history.pushHome();
		},
		getProducts: category => {
			dispatch(productsReset());
			category ? history.pushCategory(category) : history.pushSearch();
			dispatch(getProducts());
		}
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterModal)));
