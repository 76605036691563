// @flow

import type {ShippingTypes} from "./ShippingTypes";
import {SHIPPING_TYPES} from "./ShippingTypes";
import type {Product} from "./Product";
import type {Price} from "./Price";

export type Item = {
	shippingMethod: ShippingTypes,
	product: Product,
	amount: number
}

export const emptyPrice = {
	value: 0,
	currency: ""
};

export const getPrice = (item: Item = {}): Price => {
	switch (item.shippingMethod) {
		case SHIPPING_TYPES.SHIPPING:
			return item.product.distributionChannels.shipping.price;
		case SHIPPING_TYPES.PICKUP:
			return item.product.distributionChannels.pickup.price;
		case SHIPPING_TYPES.MOUNT:
			return item.product.distributionChannels.mounting.price;
		default:
			return emptyPrice;
	}
};

export const getDiscount = (item: Item = {}): number => {
	switch (item.shippingMethod) {
		case SHIPPING_TYPES.SHIPPING:
			return item.product.distributionChannels.shipping.discountRate;
		case SHIPPING_TYPES.PICKUP:
			return item.product.distributionChannels.pickup.discountRate;
		case SHIPPING_TYPES.MOUNT:
			return item.product.distributionChannels.mounting.discountRate;
		default:
			return emptyPrice;
	}
};

export const getRRP = (item: Item): number => {
	switch (item.shippingMethod) {
		case SHIPPING_TYPES.SHIPPING:
			return item.product.distributionChannels.shipping.recommendedRetailPrice;
		case SHIPPING_TYPES.PICKUP:
			return item.product.distributionChannels.pickup.recommendedRetailPrice;
		case SHIPPING_TYPES.MOUNT:
			return item.product.distributionChannels.mounting.recommendedRetailPrice;
		default:
			return null;
	}
};
