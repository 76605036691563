// @flow

import React from "react";
import ReactDOM from "react-dom";
import type { PayPalPaymentResponse } from "../../../models/PayPalPaymentResponse";
import "./PayPalButton.scss";
import type { translate } from "../../../i18next";
import { Adress } from "../../../models/Adress";

export type PayPalButtonProps = {
	orderReference: {
		dealerNo: {
			index: string
		},
		orderNo: {
			index: string
		}
	},
	clientId: string,
	payee?: string,
	currencyCode: string,
	totalBillingAmount: number,
	onPayPalSuccess: (string) => void,
	onCreateOrder: () => void,
	fundingSource: string,
	t: translate,
	orderNo: string,
	dealerNo: string,
	setPaymentError: () => void,
	buyerName: string,
	buyerEmail: string,
	buyerAdress: Adress,
	moduleLanguage?: string,
}

const loadPayPalScript = (clientId, currency, callback, moduleLanguage) => {
	const existingScript = document.getElementById("sl-p24-script");

	if (!existingScript) {
		const script = document.createElement("script");
		script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&locale=${moduleLanguage}&intent=capture&components=buttons,payment-fields,marks,funding-eligibility&enable-funding=p24`;
		script.id = "sl-p24-script";
		script.dataset["namespace"] = "paypal_capture";
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	} else {
		if (existingScript && callback) {
			if (!window.paypal_capture) {
				existingScript.onload = () => {
					callback();
				};
			} else {
				callback();
			}
		}
	}
};

class P24PayPalButton extends React.Component<PayPalButtonProps> {
	constructor(props) {
		super(props);
		this.state = {
			scriptLoaded: false
		};
	}

	componentDidMount() {
		loadPayPalScript(
			this.props.clientId,
			this.props.currencyCode,
			() => this.setState({scriptLoaded: true}),
			this.props.moduleLanguage
		);
	}
	createOrder(data, actions) {
		function round(x) {
			return Number(Math.round(x + "e2") + "e-2");
		}

		const payPalOrder = this.props.payee
			? {
				purchase_units: [{
					amount: {
						value: round(this.props.totalBillingAmount)
					},
					reference_id: this.props.orderNo,
					shipping: {
						name: {
							full_name: `${this.props.buyerAdress.name} ${this.props.buyerAdress.familyname}`
						},
						address: {
							address_line_1: this.props.buyerAdress.street,
							// address_line_2: this.props.buyerAdress.street,
							// admin_area_1: this.props.buyerAdress.city,
							admin_area_2: this.props.buyerAdress.city,
							postal_code: this.props.buyerAdress.postalCode,
							country_code: this.props.buyerAdress.countryCode,
						}
					},
				}],
				payee: this.props.payee,
				payer: {
					email_address: this.props.buyerAdress.email
				},
			}
			: {
				payer: {
					email_address: this.props.buyerAdress.email
				},
				purchase_units: [{
					amount: {
						value: round(this.props.totalBillingAmount)
					},
					reference_id: this.props.orderNo,
					shipping: {
						name: {
							full_name: `${this.props.buyerAdress.name} ${this.props.buyerAdress.familyname}`
						},
						address: {
							address_line_1: this.props.buyerAdress.street,
							// address_line_2: this.props.buyerAdress.street,
							// admin_area_1: this.props.buyerAdress.city,
							admin_area_2: this.props.buyerAdress.city,
							postal_code: this.props.buyerAdress.postalCode,
							country_code: this.props.buyerAdress.countryCode,
						}
					},
				}],
			};

		return actions.order.create(payPalOrder);
	}

	onApprove(data: any, actions: any) {
		actions.order.capture().then((details: PayPalPaymentResponse) => {
			this.props.onPayPalSuccess(details.id);
		});
	}

	onError(err) {
		/* eslint-disable-next-line */
		console.log("paypal error: ", err);
		this.props.setPaymentError();
	}

	onCancel = (data, actions) => {
		/* eslint-disable-next-line */
		console.log(this.props.t("cart.paymentMethods.paymentWindowClosed"));
		alert(this.props.t("cart.paymentMethods.paymentWindowClosed"));
	};

	onClose = () => {
		/* eslint-disable-next-line */
		console.log("system: p24 payment send.");
	};

	render() {
		if (this.state.scriptLoaded) {
			let Button = window.paypal_capture.Buttons.driver("react", {React, ReactDOM});
			let Fields = window.paypal_capture.PaymentFields.driver("react", {React, ReactDOM});
			return <React.Fragment>
				<div className={"p24-paymentFields"}>
					<Fields
						fundingSource={"p24"}
						fields={{
							name: {value: this.props.buyerName, disabled: true},
							email: {value: this.props.buyerEmail},
						}}
						onClose={() => this.onClose()}
					/>
				</div>
				<Button
					fundingSource={"p24"}
					createOrder={(data, actions) => this.createOrder(data, actions)}
					onApprove={(data, actions) => this.onApprove(data, actions)}
					onCancel={(data, actions) => this.onCancel(data)}
					onError={err => this.onError(err)}
					style={{
						layout: "horizontal",
						label: "pay"
					}}
				/>
			</React.Fragment>;
		}
		return null;
	}
}

export default P24PayPalButton;
