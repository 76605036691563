// @flow

import React from "react";
import Button from "./Button";
import "./SearchBar.scss";
import {scrollToTop} from "../../helper/anchor";

type SearchBarProps = {
	searchProducts: string => void,
	handleChange: string => void,
	value: string,
	searchPlaceholder: string,
}

const SearchBar = (props: SearchBarProps) => {
	const onSubmit = e => {
		e.preventDefault();
		if (props.value) {
			props.searchProducts(props.value);
		}
		scrollToTop();
	};
	return <form onSubmit={e => onSubmit(e)} className="hpm-searchBar">
		<input type="text" placeholder={props.searchPlaceholder} value={props.value}
			   onChange={event => props.handleChange(event.target.value)}/>
		<Button name="search" type={"submit"}/>
	</form>;
};

export default SearchBar;
