// @flow

import React from "react";
import type {translate} from "../../i18next";
import "./EmptyWishlist.scss";

export type EmptyWishlistProps = {
	t: translate,
}
const EmptyWishlist = (props: EmptyWishlistProps) => {
	return <div className="hpm-emptyWishlist">
		<div>
			<div className="hpm-wishlistTitle">
				<span className={"hpm-beforeWishlist"}/>
				{props.t("bookmarks.bookmarksLabel")}
			</div>
			<span>{props.t("bookmarks.empty")}</span>
			<button
				type={"button"}
				onClick={() => window.history.back()}
				className="hpm-backButton">{props.t("cart.back")}</button>
		</div>
	</div>;
};

export default EmptyWishlist;
