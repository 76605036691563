// @flow

import {WAY_TO_CONTACT} from "../models/ProductEnquiry";
import type {ContactData} from "../models/ContactData";
import {createSlice} from "@reduxjs/toolkit";

export type MailingReducerState = {
	sharingSuccess: boolean,
	sharingError: boolean,
	enquirySuccess: boolean,
	enquiryError: boolean,
	wishlistSuccess: boolean,
	wishlistError: boolean,
	invalidCaptcha: boolean,
	contactData: ContactData
};

const initialState = {
	sharingSuccess: false,
	sharingError: false,
	enquirySuccess: false,
	enquiryError: false,
	savedEnquiry: false,
	invalidCaptcha: false,
	contactData: {
		name: "",
		email: "",
		phone: "",
		wayToContact: WAY_TO_CONTACT.viaMail,
		mailMyself: false,
		message: ""
	}
};

const mailingSlice = createSlice({
	name: "mailing",
	initialState,
	reducers: {
		successShared(state) {
			state.sharingSuccess = true;
		},
		errorShared(state) {
			state.sharingError = true;
		},
		wishlistSuccessShared(state) {
			state.wishlistSuccess = true;
		},
		wishlistErrorShared(state) {
			state.wishlistError = true;
		},
		productEnquirySuccess(state) {
			state.enquirySuccess = true;
		},
		productEnquiryError(state) {
			state.enquiryError = true;
		},
		captchaInvalid(state) {
			state.invalidCaptcha = true;
		},
		mailingReset(state) {
			state.sharingSuccess = false;
			state.sharingError = false;
			state.enquirySuccess = false;
			state.enquiryError = false;
			state.wishlistError = false;
			state.wishlistSuccess = false;
			state.invalidCaptcha = false;
		},
		sharingSaved(state, action) {
			state.contactData.email = action.payload.email;
			state.contactData.message = action.payload.message;
		},
		enquirySaved(state, action) {
			state.contactData.name = action.payload.name;
			state.contactData.email = action.payload.email;
			state.contactData.phone = action.payload.phone;
			state.contactData.wayToContact = action.payload.wayToContact;
			state.contactData.mailMyself = action.payload.mailMyself;
			state.contactData.message = action.payload.message;
		},
		wishlistSharingSaved(state, action) {
			state.contactData.name = action.payload.name;
			state.contactData.email = action.payload.email;
			state.contactData.phone = action.payload.phone;
			state.contactData.wayToContact = action.payload.wayToContact;
			state.contactData.mailMyself = action.payload.mailMyself;
			state.contactData.message = action.payload.message;
		},
		shareButtonClicked(action) {
		}
	}
});

export const {
	mailingReset,
	wishlistErrorShared,
	wishlistSharingSaved,
	successShared,
	sharingSaved,
	enquirySaved,
	wishlistSuccessShared,
	captchaInvalid,
	errorShared,
	productEnquirySuccess,
	productEnquiryError,
	shareButtonClicked
} = mailingSlice.actions;

export default mailingSlice.reducer;
