// @flow
import TTLStorage from "../helper/storage/TTLStorage";
import CartStorage from "../helper/storage/CartStorage";
import BookmarkStorage from "../helper/storage/BookmarkStorage";
import {trackers} from "../helper/tracker";
import GoogleTracker from "../helper/tracker/GoogleTracker";
import BrandInstanceStorage from "../helper/storage/BrandInstanceStorage";
import type {ApplicationState} from "../reducers";
import type Brand from "../models/Brand";
import type {FilterStats} from "../models/FilterStats";
import type TrackingProperties from "../models/TrackingProperties";
import type {DealerDetails} from "../models/DealerDetails";
import type Tracker from "../models/Tracker";
import PiwikTracker from "../helper/tracker/PiwikTracker";
import {sessionEventHandler} from "../store";
import ProductApi, {INTENT} from "../api/ProductApi";
import type {GarageDealership} from "../models/GarageDealership";
import {RoleTypes} from "../models/Role";
import LocationStorage from "../helper/storage/LocationStorage";
import {mergeToUniqueObjArray} from "../helper/arrayUtils";
import ETracker from "../helper/tracker/ETracker";
import StatisticApi from "../api/StatisticApi";
import {checkLocation} from "../helper/LocationUtils";
import CouponStorage from "../helper/storage/CouponStorage";
import {
    configErrorFetched,
    configSet,
    trackerSuccessLoaded,
    trackerWithoutConsentSuccessLoaded
} from "../reducers/configReducer";
import {vehicleSet} from "../reducers/filterReducer";

export const loadConfig = (dealerId: string, role = RoleTypes.DEALER, garageGroupId: string) => {
    return async (dispatch, getState: () => ApplicationState) => {
        const environment = getState().config.environment;
        const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;
        const productApi = new ProductApi(dealerId, {configIdType: role, intent: intent});

        return productApi.getConfig()
            .then(
                res => dispatch(fetchConfigSuccess(res)),
                error => dispatch(configErrorFetched(error))
            );
    };
};

export type LoadConfigResponseType = {
    cfgKey: string,
    dealerId: string,
    name: string,
    filters: {
        vehicleSeries: {}[],
        filterStats: FilterStats,
    },
    dealerDetails: DealerDetails,
    garageDealerships: GarageDealership,
    configurationDetails: {
        languageCode: string,
        configuredCountries: {}[],
        trackingProperties: TrackingProperties,
        theme: string,
    },
    texts: {
        disclaimer: string,
        legalTerms: any,
        mountingNotice: string,
        privacyPolicy: string,
        imprintText: string,
        revocationTerms: string,
    },
    brands: Brand[],
    availableLanguages: string[],
};

export function fetchConfigSuccess(loadConfigResponse: LoadConfigResponseType) {
    const emptyCart = {items: []};
    const emptyBookmarks = {
        products: [],
        hasQueryParamProducts: false,
        quickCallProduct: {}
    };

    let cart = emptyCart;
    let bookmarks = emptyBookmarks;
    let pickupLocation = null;
    let coupon = null;

    const ttlStorage = new TTLStorage(loadConfigResponse.dealerId);
    const cartStorage = new CartStorage(loadConfigResponse.dealerId);
    const bookmarkStorage = new BookmarkStorage(loadConfigResponse.dealerId);
    const locationStorage = new LocationStorage(loadConfigResponse.dealerId);
    const couponStorage = new CouponStorage(loadConfigResponse.dealerId);

    const initStorage = () => {
        if (!ttlStorage.get() || !ttlStorage.isTTLValid()) {
            ttlStorage.clear();
            cartStorage.clear();
            bookmarkStorage.clear();
            couponStorage.clear();
        }
        ttlStorage.set();
    };

    const trackModuleLoad = () => {
        const statisticApi = new StatisticApi(loadConfigResponse.dealerId, {
            configIdType: RoleTypes.DEALER,
            language: navigator.language || "en",
        });
        statisticApi.trackModuleLoad();
    };

    const getBookmarks = async () => {
        const productApi = new ProductApi(loadConfigResponse.dealerId, {
            configIdType: pickupLocation ? RoleTypes.GROUP_DEALER : RoleTypes.DEALER,
            language: navigator.language || "en",
            pickupLocation,
        });

        const rawBookmarks = JSON.parse(bookmarkStorage.get()) || [];
        let productsFromStorage = [];
        if (rawBookmarks && rawBookmarks.length) {
            productsFromStorage = await productApi.validateBookmarks(rawBookmarks) || [];
        }

        const params = new URLSearchParams(window.location.search);
        const an = params.getAll("an");
        let productFromQueryParams = [];
        if (an) {
            const articleNos = Array.isArray(an) ? an : [an];
            productFromQueryParams = await productApi.getProductByArticleNo(articleNos) || [];
        }
        const mergedBookmarks = mergeToUniqueObjArray(productsFromStorage, productFromQueryParams, "id");

        let hasQueryParamProducts = productFromQueryParams.length > 1;
        let hasBookmarks = hasQueryParamProducts || productsFromStorage.length > 0;
        let quickCallProduct = productFromQueryParams.length === 1 ? productFromQueryParams[0] : null;

        bookmarkStorage.set(JSON.stringify(mergedBookmarks));
        bookmarks = {
            products: hasBookmarks ? mergedBookmarks : [],
            hasQueryParamProducts: hasQueryParamProducts,
            quickCallProduct: quickCallProduct
        };
    };

    const getCart = async () => {
        const rawCart = JSON.parse(cartStorage.get()) || emptyCart;
        if (rawCart.items.length === 0) {
            return emptyCart;
        }
        const productApi = new ProductApi(loadConfigResponse.dealerId, {
            configIdType: pickupLocation ? RoleTypes.GROUP_DEALER : RoleTypes.DEALER,
            language: navigator.language || "en",
            pickupLocation,
        });
        const validatedProductInfo = await productApi.validateCart(rawCart.items);
        cart = {
            items: rawCart.items.map(item => {
                if (validatedProductInfo.filter(validatedProductInfo => validatedProductInfo.id === item.product.id).length) {
                    return {
                        shippingMethod: item.shippingMethod,
                        amount: item.amount,
                        product: validatedProductInfo.filter(validatedProductInfo => validatedProductInfo.id === item.product.id)[0]
                    };
                }
                return null;
            })
        };
        cart = {items: cart.items.filter(item => item)};
    };

    const getPickupLocation = async () => {
        pickupLocation = JSON.parse(locationStorage.get()) || null;
    };

    const getCouponFromStorage = async () => {
        coupon = JSON.parse(couponStorage.get()) || null;
    };

    return async (dispatch, getState: () => ApplicationState) => {
        if (hasTrackersWithoutConsent()) {
            dispatch(loadTrackersWithoutConsent());
        }

        if (getState().consent.googleAnalytics) {
            dispatch(loadTrackers(
                loadConfigResponse.configurationDetails.trackingProperties,
            ));
        } else {
            sessionEventHandler.requestGoogleAnalyticsConsent();
        }

        initStorage();
        trackModuleLoad();

        const loadStorage = async () => {
            await getPickupLocation();
            await getCart();
            await getBookmarks();
            await getCouponFromStorage();
        };

        const getVehicleModels = (vehicleSeries: []): [] => {
            let vehicleModels = [];
            for (let series of vehicleSeries) {
                for (let category of series.categories) {
                    vehicleModels.push(category);
                }
            }
            return vehicleModels;
        };

        const vehicleSeries = loadConfigResponse.filters.filterStats.vehicleSeries;
        const vehicleModels = getVehicleModels(vehicleSeries);
        loadStorage().then(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlParams);
            const vehicle = vehicleSeries.find(series => series.title === params.vehicle || series.key === params.vehicle || series.name === params.vehicle) || null;
            const vehicleModel = vehicleModels.find(model => model.name === params.model || model.title === params.model || model.key === params.model) || null;
            const vehicleKey = vehicle ? vehicle.key : (vehicleModel ? vehicleModel.key : null);
            if (params.vehicle || params.model) {
                dispatch(vehicleSet({
                    vehicle,
                    vehicleModel,
                    vehicleKey,
                    trackerLanguage: (loadConfigResponse.configurationDetails.trackingProperties || {}).trackerLanguage || null,
                }));
            }

            let trackingProperties = loadConfigResponse.configurationDetails.trackingProperties;

            if (trackingProperties) {
                trackingProperties = {
                    ...trackingProperties,
                    consentlessTrackers: trackingProperties.hasOwnProperty("consentlessTrackers") ? trackingProperties.consentlessTrackers : []
                };
            }
            dispatch(configSet({
                ...loadConfigResponse,
                dealerLanguage: loadConfigResponse.configurationDetails.languageCode,
                theme: !params.theme ? loadConfigResponse.configurationDetails.theme : params.theme,
                trackingProperties: trackingProperties,
                configuredCountries: loadConfigResponse.configurationDetails.configuredCountries,
                locationChoices: loadConfigResponse.dealerDetails.dealership.groupMembers,
                disclaimer: loadConfigResponse.texts.disclaimer,
                legalTerms: loadConfigResponse.texts.legalTerms,
                privacyPolicy: loadConfigResponse.texts.privacyPolicy,
                imprintText: loadConfigResponse.texts.imprintText,
                revocationTerms: loadConfigResponse.texts.revocationTerms,
                categories: loadConfigResponse.filters.filterStats.categories,
                filterStats: loadConfigResponse.filters.filterStats,
                vehicleModels,
                isShopEnabled: loadConfigResponse.dealerDetails.dealerConfiguration.shopEnabled,
                isLeadModuleEnabled: loadConfigResponse.dealerDetails.dealerConfiguration.leadModuleEnabled,
                productPricesDisabled: loadConfigResponse.dealerDetails.dealerConfiguration.productPricesDisabled,
                watchlistEnabled: loadConfigResponse.dealerDetails.dealerConfiguration.watchlistEnabled,
                brand: {
                    name: loadConfigResponse.dealerDetails.dealership.manufacturerTitle,
                    vehicleSeries: loadConfigResponse.filters.filterStats.vehicleSeries,
                    brandImageUri: loadConfigResponse.dealerDetails.dealership.manufacturerLogoUrl
                },
                cart,
                bookmarks,
                pickupLocation: checkLocation(loadConfigResponse.dealerDetails.dealership.groupMembers, pickupLocation),
                coupon,
            }));
        });
    };
};

export const checkConfig = (configLoaded, dealerID, garageGroupId) => {
    let dealerId = dealerID;

    if (!dealerID) {
        const Storage = new BrandInstanceStorage("garageID");
        dealerId = Storage.get("garageID");
        return true;
    }
    if (dealerId && !configLoaded) {
        return dispatch => {
            dispatch(loadConfig(dealerId, RoleTypes.DEALER, garageGroupId));
            return true;
        };
    } else {
        return false;
    }
};

export const loadTrackers = (trackingProperties: TrackingProperties) => {
    return (dispatch, getState: () => ApplicationState) => {
        trackingProperties = trackingProperties || getState().config.trackingProperties;

        if (trackingProperties.analyticsTrackers.length === 0 || getState().config.trackerLoaded) {
            return;
        }

        const googleTrackers = trackingProperties.analyticsTrackers
            .filter((tracker: Tracker) => tracker.name === "google");
        if (googleTrackers && googleTrackers.length > 0) {
            trackers.push(
                new GoogleTracker(googleTrackers.map(it => it.trackingKey), true, trackingProperties.dealerNo, trackingProperties.dealerName, trackingProperties.garageNo)
            );
        }

        const piwikTrackers = trackingProperties.analyticsTrackers
            .filter((tracker: Tracker) => tracker.name === "piwik");
        if (piwikTrackers && piwikTrackers.length > 0) {
            const tracker = piwikTrackers[0];
            trackers.push(new PiwikTracker(tracker.trackingKey, tracker.trackingHostUrl, tracker.withEcommerce));
        }

        return dispatch(trackerSuccessLoaded());
    };
};

export const loadTrackersWithoutConsent = () => {
    return (dispatch, getState: () => ApplicationState) => {
        if (getState().config.consentlessTrackerLoaded) {
            return;
        }
        const trackingProperties = getState().config.trackingProperties;

        if (window.etCommerce && !trackingProperties.consentlessTrackers.find(tracker => tracker.name === "eTracker")) {
            trackers.push(new ETracker(undefined, true));
        }

        trackingProperties.consentlessTrackers.forEach((tracker: Tracker) => {
            switch (tracker.name) {
                case "eTracker":
                    trackers.push(new ETracker(tracker.trackingKey, tracker.withEcommerce));
                    break;
                default:
                    break;
            }
        });

        return dispatch(trackerWithoutConsentSuccessLoaded());
    };
};

export const hasTrackersWithoutConsent = () => {
    return window.etCommerce;
};
