// @flow
import {searchReset, searchSet} from "../reducers/filterReducer";

export function setSearch(searchPhrase) {
	return searchSet({
		searchPhrase: decodeURIComponent(searchPhrase)
	});
}

export function resetSearch() {
	return searchReset;
}
