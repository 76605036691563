// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const LanciaTheme = props => {
	import("./lancia.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default LanciaTheme;
