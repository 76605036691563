// @flow
import type {GroupMember} from "../models/GroupMember";
import type {ApplicationState} from "../reducers";
import {checkLocation} from "../helper/LocationUtils";
import {pickupLocationSet} from "../reducers/locationReducer";

export const setPickupLocation = (location: GroupMember) => {
	return (dispatch, getState: () => ApplicationState) => {
		return dispatch(pickupLocationSet(checkLocation(getState().config.dealerDetails.dealership.groupMembers, location)));
	};
};
