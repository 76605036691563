// @flow

class RootNodeProvider {
	getRootNode = () => {
		const root = document.getElementById("itt-sl-hpm")
			? document.getElementById("itt-sl-hpm")
			: document.getElementById("app-root");

		if (!root) {
			if (process.env.NODE_ENV === "test") {
				const testRoot = document.querySelector("body");
				testRoot.dataset["dealerId"] = "skoda_99999";
				testRoot.id = "itt-sl-hpm";
				return testRoot;
			}
			return null;
		}

		return root;
	};


	getPreviewRootNode = () => document.getElementById("itt-sl-hpm-preview");

	getDealerFinderRootNode = () => document.getElementById("itt-dealer-finder");
}

const rootNodeProviderInstance = new RootNodeProvider();

export default rootNodeProviderInstance;
