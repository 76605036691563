// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const MiniTheme = props => {
	import("./mini.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default MiniTheme;
