/* eslint-disable no-unreachable */
// @flow
import * as React from "react";
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import {Dealer} from "./Dealer";

export type MapProps = {
	longitude: number,
	latitude: number,
	error: string,
	dealers: [],
	googleMapURL: string,
	loadingElement: React.Node,
	containerElement: React.Node,
	mapElement: React.Node,
	openDealerShopsInNewTab: boolean,
}

const Map = ({dealers, error, latitude, longitude, openDealerShopsInNewTab}: MapProps) => {
	const [position, setPosition] = React.useState({});
	const [activeInfoWindow, setActiveInfoWindow] = React.useState(null);
	const [initialBounds, setInitialBounds] = React.useState(null);

	const mapRef = React.useRef();

	React.useEffect(() => {
		if (latitude !== position.latitude && longitude !== position.longitude) {
			setPosition({latitude: latitude, longitude: longitude});
		}
	}, [latitude, longitude, position.latitude, position.longitude]);

	React.useEffect(() => {
		if (!initialBounds && mapRef.current && dealers.length && (position.latitude || error)) {
			const bounds = getBounds();
			setInitialBounds(bounds);
			mapRef.current.fitBounds(bounds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealers.length, error, initialBounds, position]);

	const getBounds = () => {
		// eslint-disable-next-line no-undef
		const bounds = new google.maps.LatLngBounds();
		if (latitude) {
			// eslint-disable-next-line no-undef
			bounds.extend(new google.maps.LatLng(
				latitude,
				longitude
			));
			dealers.forEach((place: any, index) => {
				if (index <= 1) {
					// eslint-disable-next-line no-undef
					bounds.extend(new google.maps.LatLng(
						place.location.latitude,
						place.location.longitude
					));
				}
			});
		} else {
			dealers.forEach((place: any) => {
				// eslint-disable-next-line no-undef
				bounds.extend(new google.maps.LatLng(
					place.location.latitude,
					place.location.longitude
				));
			});
		}
		return bounds;
	};

	if (latitude || error) {
		const refButton = document.querySelector(".hpm-App .ico.search");

		return <GoogleMap
			ref={mapRef}
			defaultZoom={(!latitude || !longitude) ? 6 : 10}
			defaultCenter={{lat: latitude || 0, lng: longitude || 0}}
			defaultOptions={{
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			}}
		>
			{(!!latitude) && <Marker
				key={"currentLocation"}
				icon={{
					path: "M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z",
					fillColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryColor")) || "tomato",
					fillOpacity: 1,
					strokeColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryTextColor")) || "black",
					strokeWeight: 1,
					scale: 1.5,
					// eslint-disable-next-line no-undef
					anchor: new google.maps.Point(8, 10),
					rotation: 0
				}}
				position={{
					lat: latitude,
					lng: longitude
				}}
			/>}
			{dealers.map((dealer, index) => <Marker
				key={dealer.identifier.dealerNo}
				position={{
					lat: dealer.location.latitude,
					lng: dealer.location.longitude
				}}
				icon={{
					path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
					fillColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryColor")) || "tomato",
					fillOpacity: 1,
					strokeColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryTextColor")) || "black",
					strokeWeight: 1,
					scale: 2,
					// eslint-disable-next-line no-undef
					anchor: new google.maps.Point(14, 24)
				}}
				onClick={() => {
					setActiveInfoWindow(null);
					setActiveInfoWindow(dealer.identifier.dealerNo);
				}} label={""}
			>
				{activeInfoWindow === dealer.identifier.dealerNo
					? <InfoWindow>
						<Dealer dealer={dealer} openDealerShopsInNewTab={openDealerShopsInNewTab}/>
					</InfoWindow>
					: null}
			</Marker>)}
		</GoogleMap>;
	} else {
		return null;
	}
};

export default withScriptjs(withGoogleMap(Map));
