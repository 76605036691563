import React, {useRef, useCallback, useEffect} from "react";
import { withTranslation } from "react-i18next";
import "./searchBox.scss";

type Props = {
    t: string => string;
    onLocationSelect: (location: { lat: number, lng: number }) => void;
    countryCode: string;
};

const SearchBox = ({ t, onLocationSelect, countryCode }: Props) => {
    const autocompleteRef = useRef(null);

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            const inputValue = event.target.value;
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input: inputValue,
                    componentRestrictions: { country: countryCode }
                },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                        const firstPrediction = predictions[0];
                        if (firstPrediction.place_id) {
                            event.target.value = firstPrediction.description;
                            const service = new window.google.maps.places.PlacesService(document.createElement("div"));
                            service.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
                                if (status === window.google.maps.places.PlacesServiceStatus.OK && place.geometry && place.geometry.location) {
                                    const { lat, lng } = place.geometry.location.toJSON();
                                    onLocationSelect({ lat, lng });
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onPlaceChanged = useCallback(() => {
        const place = autocompleteRef.current.getPlace();

        if (place && place.geometry && place.geometry.location) {
            const { lat, lng } = place.geometry.location.toJSON();
            if (typeof lat === "number" && typeof lng === "number") {
                onLocationSelect({ lat, lng });
            }
        }
    }, [onLocationSelect]);

    useEffect(() => {
        const loadAutocompletionScript = () => {
            if (window.google && window.google.maps) {
                autocompleteRef.current = new window.google.maps.places.Autocomplete(
                    document.getElementById("autocomplete-input"),
                    {
                        types: ["geocode"],
                        componentRestrictions: { country: countryCode }
                    }
                );
                autocompleteRef.current.addListener("place_changed", onPlaceChanged);
            } else {
                setTimeout(() => loadAutocompletionScript(), 200);
            }
        };
        loadAutocompletionScript();
    }, [onPlaceChanged, countryCode]);

    return (
	<div className={"flex-input"}>
		<input
            ref={autocompleteRef}
            id="autocomplete-input"
            type="text"
            placeholder={t("nsc.search")}
            list={"dealers"}
            onKeyDown={handleKeyDown}
        />
	</div>
    );
};

export default withTranslation()(SearchBox);