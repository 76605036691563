import Adress from "../../../components/checkout/mainContentBox/Adress";
import {connect} from "react-redux";
import {createOrder, saveAdress} from "../../../actions/order";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import {SHIPPING_TYPES} from "../../../models/ShippingTypes";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	const items = state.cart.items;
	const {SHIPPING} = SHIPPING_TYPES;

	const configuredCountries = state.config.configuredCountries;
	let adress = {...state.order.adress};
	if (adress.countryCode === "") {
		adress.countryCode = configuredCountries[0].countryCode;
		adress.shippingCountryCode = configuredCountries[0].countryCode;
	}

	return {
		adress,
		initialValues: {...adress},
		shippableCountries: configuredCountries,
		withShipping: items.some(item => item.shippingMethod === SHIPPING),
		t: ownProps.t
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		saveAdress: formValues => {
			dispatch(saveAdress(formValues));
			dispatch(createOrder());
		}
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Adress));
