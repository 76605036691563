// @flow
import React from "react";
import phImage from "../../assets/images/ph.png";
import type Category from "../../models/Category";
import {Link} from "react-router-dom";
import type {translate} from "../../i18next";
import {scrollToTop} from "../../helper/anchor";
import history from "../../history";

type ShowCaseCategoryProps = {
	category: Category,
	showImage: boolean,
	t: translate
};

export const ShowCaseCategory = (props: ShowCaseCategoryProps) => {
	const {key, name, count, imageUrl, title, subTitle = String.fromCharCode(160), classNames} = props.category;
	const categoryImageStyle = {};
	if (!classNames) {
		categoryImageStyle.backgroundImage = `url(${imageUrl})`;
	} else {
		categoryImageStyle.backgroundImage = `var(--carImage, url(${imageUrl}))`;
	}
	const redirectUrl = history.getCategoryUrl(name);
	const saleImageClassNames= classNames ? ` ${classNames}` : "";

	return props.showImage
		? <Link key={key} to={redirectUrl} onClick={() => scrollToTop()} className={`hpm-category${saleImageClassNames}`}>
			<span style={categoryImageStyle}>
				<img className={"hpm-format"} src={phImage} alt="ph"/>
			</span>
			<span>
				<h2>{title}</h2>
				{!!subTitle && <span>{subTitle}</span>}
			</span>
			<span className="hpm-semibtn">{props.t("category.plural", {count})}</span>
		</Link>
		: <Link key={key} to={redirectUrl} onClick={() => scrollToTop()} className="hpm-category small">
			<span className={"hpm-showCaseElement"}>{title} ({count})</span>
		</Link>;
};
