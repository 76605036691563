// @flow

import React from "react";
import {FILTER} from "../../reducers/filterReducer";
import type {translate} from "../../i18next";
import "./FilterModal.scss";

export type ModalProps = {
	id: number,
	isDirty: boolean,
	activeModal: ?number,
	children: ?any,
	closeText: ?string,
	changeModal: any,
	setFilterInstance: number => void,
	topFilterInstance: number,
	filterInstance: number,
	t: translate,
	getProducts: string => void,
	setCategory: string => void,
	resetFilter: () => void,
	category: string,
};

class FilterModal extends React.Component<ModalProps> {
	constructor(props) {
		super(props);
		this.handleResize = this.handleResize.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.isOpen = this.isOpen.bind(this);
		this.getOffset = this.getOffset.bind(this);
		this.handleFilterBack = this.handleFilterBack.bind(this);
		this.handleFilterExit = this.handleFilterExit.bind(this);
	}

	isOpen() {
		return this.props.activeModal === this.props.id;
	}

	getOffset() {
		if (this.isOpen()) {
			return this.getTop();
		}
		return 0;
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		window.addEventListener("scroll", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
		window.removeEventListener("scroll", this.handleResize);
	}

	toggleModal() {
		if (this.isOpen()) {
			this.props.changeModal(null);
			this.props.setFilterInstance(FILTER.MAIN);
		} else {
			this.props.changeModal(this.props.id);
		}
	}

	getTop() {
		const header = document.getElementById("hpm-header");

		if (header) {
			return header.getBoundingClientRect().top + header.clientHeight + 2;
		}
	}

	handleResize() {
		this.setState({top: this.getTop()}, this.handleScroll);
	}

	handleFilterExit() {
		this.toggleModal();
		if (this.props.isDirty) {
			this.props.getProducts(this.props.category);
		}
	}

	handleFilterBack() {
		switch (this.props.filterInstance) {
			case this.props.topFilterInstance:
				this.handleFilterExit();
				break;
			case FILTER.SUB_CATEGORY:
				this.props.setFilterInstance(FILTER.CATEGORIES);
				break;
			case FILTER.VEHICLE_MODEL:
				this.props.setFilterInstance(FILTER.CARLINE);
				break;
			default:
				this.props.setFilterInstance(this.props.topFilterInstance);
		}
	}

	render() {
		let StyleFilter = {};
		let StyleBackground = {};
		if (this.isOpen()) {
			StyleFilter = {
				position: "fixed",
				top: `calc(${this.getOffset() - 1}px`,
				borderTop: "1px solid rgba(0, 0, 0, 0.15)",
				maxHeight: "570px"
			};
			StyleBackground = {
				position: "absolute",
				top: "-38px",
				maxHeight: "10000px"
			};
		}
		return (
			<div className={"hpm-modalContainer"}>
				<div className="hpm-modal hpm-transparentModal" style={StyleBackground}
					 onClick={this.handleFilterExit}
				/>
				<div className="hpm-modal hpm-small" style={StyleFilter}>
					<div className="hpm-filterContainer">
						<div className="hpm-modalNavigation">
							<div className="hpm-modalBack" onClick={this.handleFilterBack}/>
							<div className="hpm-modalCloseText" onClick={this.handleFilterExit}>
								{this.props.closeText}
							</div>
						</div>
						<div className="hpm-scrollContainer">
							<div className={"hpm-filterElements"}>
								{this.props.children}
							</div>
						</div>
						<div className={"hpm-resetFilter"} onClick={this.props.resetFilter}>
							{this.props.t("navigation.resetFilter")}
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default FilterModal;
