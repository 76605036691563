/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./SwimLane.scss";
import useEmblaCarousel from "embla-carousel-react";

type SwimLaneProps = {
	children: any;
	id: ?string,
	setAllowsClick: boolean => void
};

const SwimLane = (props: SwimLaneProps) => {
	const [viewportRef, embla] = useEmblaCarousel({
		slidesToScroll: 2,
		dragFree: true,
		loop: false,
		align: "start",
		containScroll: "trimSnaps",
	}, []);
	const deviceSupportsTouch = "ontouchstart" in (document.documentElement || {});

	const scrollPrev = React.useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = React.useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = React.useCallback(() => {
		if (!embla) return;
		if (props.setAllowsClick) props.setAllowsClick(embla.clickAllowed());
	}, [embla]);

	const onScroll = React.useCallback(() => {
		if (props.setAllowsClick) props.setAllowsClick(false);
	}, [embla, props.setAllowsClick]);

	const onSettle = React.useCallback(() => {
		if (props.setAllowsClick) props.setAllowsClick(true);
	}, [embla, props.setAllowsClick]);

	React.useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		embla.on("scroll", onScroll);
		embla.on("settle", onSettle);
		onSelect();
		window.dispatchEvent(new UIEvent("resize")); // https://tickets.it-tecture.de/issues/42330
	}, [embla, onSelect]);

	return <div id={props.id} className="swimLane">
		<div ref={viewportRef} className="embla">
			<div className="embla__container">
				{props.children}
			</div>
			{!deviceSupportsTouch && <React.Fragment>
				<button className={"carouselBtn"} type={"button"} onClick={scrollPrev}/>
				<button className={"carouselBtn"} type={"button"} onClick={scrollNext}/>
			</React.Fragment>}
		</div>
	</div>;
};

export default SwimLane;
