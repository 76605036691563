// @flow

import React from "react";
import CartContainer from "../../container/checkout/mainContentBox/CartContainer";
import AdressContainer from "../../container/checkout/mainContentBox/AdressContainer";
import OrderOverviewContainer from "../../container/checkout/mainContentBox/OrderSummaryContainer";
import PaymentContainer from "../../container/checkout/mainContentBox/PaymentContainer";
import "./CheckoutPage.scss";
import OrderFinishedContainer from "../../container/checkout/mainContentBox/OrderFinishedContainer";
import type {translate} from "../../i18next";
import Loading from "../layout/Loading";

export type CheckoutPageProps = {
	t: translate,
	isCartEmpty: boolean,
	showOrderFinishPage: boolean,
	setPage: number => void,
	page: number,
	maxPage: number,
	minPage: number,
	isPaymentPending: boolean,
	validateCart: () => void,
	isLoading: boolean,
	getPaymentMethods: () => void,
}

export const CHECKOUT_PAGE_STATUS = {
	CART: 0,
	ADRESS: 1,
	PAYMENT: 2,
	OVERVIEW: 3,
	FINISH: 4
};

class CheckoutPage extends React.Component<CheckoutPageProps> {
	constructor(props) {
		super(props);
		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.setPage = this.setPage.bind(this);
		this.renderPage = this.renderPage.bind(this);
	}

	componentDidMount() {
		this.props.getPaymentMethods();
		if (!this.props.isCartEmpty) {
			this.props.validateCart();
		}
	}

	nextPage() {
		this.setPage(this.props.page + 1);
	}

	prevPage() {
		if (this.props.page) {
			this.setPage(this.props.page - 1);
		}
	}

	setPage(page: number) {
		this.props.setPage(page);
	}

	renderPage() {
		if (this.props.isLoading) {
			return <Loading/>;
		}

		switch (this.props.page) {
			case CHECKOUT_PAGE_STATUS.CART:
				return <CartContainer nextPage={this.nextPage}/>;
			case CHECKOUT_PAGE_STATUS.ADRESS:
				return <AdressContainer nextPage={this.nextPage}/>;
			case CHECKOUT_PAGE_STATUS.PAYMENT:
				return <PaymentContainer nextPage={this.nextPage}/>;
			case CHECKOUT_PAGE_STATUS.OVERVIEW:
				return <OrderOverviewContainer nextPage={this.nextPage} setPage={this.setPage}/>;
			case CHECKOUT_PAGE_STATUS.FINISH:
				return <OrderFinishedContainer/>;
			default:
				return <CartContainer nextPage={this.nextPage}/>;
		}
	}

	renderSlider() {
		const handleClick = page => {
			if (page <= this.props.maxPage && page >= this.props.minPage) {
				return this.setPage(page);
			}
		};

		return (
			<div>
				<div className="hpm-CheckoutNavigation">
					<button type={"button"} onClick={() => this.prevPage()}>
						{this.props.t("cart.back")}
					</button>
					<div className="hpm-Step">
						{this.props.t("cart.mobileNav", {
							current: this.props.page + 1,
							total: CHECKOUT_PAGE_STATUS.FINISH + 1
						})}
					</div>
				</div>
				<div className="hpm-CheckoutProgressBar">
					<div className="hpm-bar">
						<div style={{transform: `translate(${-100 + 25 * this.props.page}%)`}} className="hpm-value"/>
					</div>
					<button
						type="button"
						className={this.props.page >= CHECKOUT_PAGE_STATUS.CART ? "hpm-active" : ""}
						onClick={() => handleClick(CHECKOUT_PAGE_STATUS.CART)}
					>
						{this.props.t("cart.checkoutSteps.step1")}
					</button>
					<button
						type="button"
						className={this.props.page >= CHECKOUT_PAGE_STATUS.ADRESS ? "hpm-active" : ""}
						onClick={() => handleClick(CHECKOUT_PAGE_STATUS.ADRESS)}
					>
						{this.props.t("cart.checkoutSteps.step2")}
					</button>
					<button
						type="button" className={this.props.page >= CHECKOUT_PAGE_STATUS.PAYMENT ? "hpm-active" : ""}
						onClick={() => handleClick(CHECKOUT_PAGE_STATUS.PAYMENT)}
					>
						{this.props.t("cart.checkoutSteps.step3")}
					</button>
					<button
						type="button" className={this.props.page >= CHECKOUT_PAGE_STATUS.OVERVIEW ? "hpm-active" : ""}
						onClick={() => handleClick(CHECKOUT_PAGE_STATUS.OVERVIEW)}
					>
						{this.props.t("cart.checkoutSteps.step4")}
					</button>
					<button
						type="button" className={this.props.page >= CHECKOUT_PAGE_STATUS.FINISH ? "hpm-active" : ""}
						onClick={() => handleClick(CHECKOUT_PAGE_STATUS.FINISH)}
					>
						{this.props.t("cart.checkoutSteps.step5")}
					</button>
				</div>
			</div>
		);
	}

	render() {
		return this.props.showOrderFinishPage
			? <OrderFinishedContainer/>
			: this.props.isCartEmpty && !this.props.isPaymentPending
				? <div className="hpm-CheckoutSlider hpm-emptyCart">
					<div>
						<span className={"hpm-emptyCartText"}>{this.props.t("cart.empty")}</span>
						<button
							type={"button"}
							onClick={() => window.history.back()}
							className="hpm-emptyCheckoutButton back">{this.props.t("cart.back")}</button>
					</div>
				</div>
				: <div className="hpm-CheckoutSlider">
					<div className="hpm-cartClose"
						 onClick={() => {
							 window.history.back();
						 }}/>
					{this.renderSlider()}
					{this.renderPage()}
				</div>;
	}
}

export default CheckoutPage;
