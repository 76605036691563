// @flow

export const PAYMENT_METHODS = {
	CASH: "cash",
	PREPAID: "prepaid",
	PAYPAL: "paypal",
	PAYPAL_EXPRESS: "paypal-express",
	PAY_LATER: "pay-later",
	CARD: "card",
	SEPA: "sepa",
	PRZELEWY24: "przelewy24",
};

export const PAYMENT_PROVIDER = {
	PAYPAL_EXPRESS: "paypal-express",
	PAYPAL_CAPTURE: "paypal-capture",
	PAYPAL: "paypal",
	CASH: "cash",
	PREPAID: "prepaid",
	NOT_SET: null,
	STRIPE: "stripe",
	FREE: "gratis"
};

export type PaymentProviderTypes = PAYMENT_PROVIDER.PAYPAL_EXPRESS | PAYMENT_PROVIDER.PAYPAL | PAYMENT_PROVIDER.CASH | PAYMENT_PROVIDER.PREPAID;

export type PaymentRequest = {
	paymentProvider: PaymentProviderTypes,
	paymentReference: ?string
}
