// @flow
import React, {useEffect, useState} from "react";
import type {translate} from "../../../i18next";
import "./CouponCode.scss";
import {toast} from "react-toastify";
import type {Coupon} from "../../../reducers/couponReducer";
import type {HpmPrice} from "../../../helper/currency";
import type Price from "../../../models/Price";

type CouponCodeProps = {
	t: translate,
	coupon: ?Coupon,
	couponErrorCode: ?string,
	couponSuccessCode: ?string,
	resetCoupon: () => void,
	setCoupon: (string, Price) => void,
	resetCouponToast: () => void,
	orderItemsPriceSumWithShipping: HpmPrice,
};

export const CouponCode = ({
							   coupon,
							   couponErrorCode,
							   couponSuccessCode,
							   resetCoupon,
							   resetCouponToast,
							   setCoupon,
							   orderItemsPriceSumWithShipping,
							   t
						   }: CouponCodeProps) => {
	const [userInput, setUserInput] = useState((coupon && coupon.coupon) ? coupon.coupon.code : "");

	useEffect(() => {
		if (couponErrorCode) {
			toast.error(t("cart.coupon." + couponErrorCode)); // minOrderValue, invalid, expired, error
			resetCouponToast();
		}
		if (couponSuccessCode) {
			toast.success(t("cart.coupon." + couponSuccessCode));
			resetCouponToast();
		}
	}, [coupon, couponErrorCode, resetCoupon, couponSuccessCode, resetCouponToast, t]);

	const handleKeyPress = e => {
		if (e && e.which === 13) {
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		if (userInput.length > 3 && !coupon) {
			setCoupon(userInput, orderItemsPriceSumWithShipping);
		}
	};

	return (
		<div className={"hpm-couponCodeBox"}>
			<span className="hpm-title">{t("cart.coupon.coupon")}</span>
			<div className={"hpm_couponWrapper"}>
				<input type="text" name="coupon" className={"hpm-coupon-code"} placeholder={t("cart.code")}
					   value={`${userInput}${coupon ? " ✔" : ""}`} disabled={!!coupon}
					   onKeyUp={e => handleKeyPress(e)}
					   onChange={e => setUserInput(e.target.value)}
				/>
				{ (userInput && userInput.length > 3 && !coupon) &&
					<input type="button" className={"hpm-couponButton"} value={t("cart.coupon.redeem")} onClick={() => handleSubmit()}/>
				}
				{ (coupon) &&
					<input type="button" className={"hpm-couponButton reset"} onClick={() => resetCoupon()} title={t("cart.coupon.reset")} value={"x"}/>
				}
			</div>
		</div>
	);
};
