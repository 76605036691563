// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const JaguarTheme = props => {
	import("./jaguar.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default JaguarTheme;
