// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const SuzukiTheme = props => {
	import("./suzuki.lazy.scss");
	return <>
		<ContrastColorUpdater/>
		{props.children}
	</>;
};

export default SuzukiTheme;
