// @flow
import React from "react";
import type {Product} from "../../../models/Product";
import "./ShareFormular.scss";
import ShareFormContainer from "../../../container/productDetail/Sharing/ShareFormContainer";

export type SharePageProps = {
	product: Product,
	closeSharePage: () => void,
}

const Share = (props: SharePageProps) => {
	return (
		<div className="hpm-sharePage">
			<ShareFormContainer product={props.product} closeSharePage={props.closeSharePage}/>
		</div>
	);
};

export default Share;
