// @flow

import React from "react";
import type {Product} from "../../models/Product";
import {currencyConverter} from "../../helper/currency";
import {canHandleDeeplinks} from "../../history";
import type {translate} from "../../i18next";
import "./WishlistPage.scss";
import {withTranslation} from "react-i18next";

export type WishlistItemProps = {
	t: translate,
	product: Product,
	removeProduct: () => void
}

const WishlistItem = (props: WishlistItemProps) => {
	const urlPrefix = canHandleDeeplinks() ? "" : "#";
	const { product } = props;

	return (
		<div className="hpm-wishlistItem">
			<div className="hpm-productItem">
				<a className="hpm-productItemImagebox"
					   href={`${urlPrefix}/product/${product.id}/${encodeURIComponent(product.name)}`}>
					<img src={product.galleryImage.previewImage ? product.galleryImage.previewImage.attachmentUrl : product.galleryImage.attachmentUrl} alt="IMG"/>
				</a>

				<div className="hpm-productItemTextbox">
					<a href={`${urlPrefix}/product/${product.id}/${encodeURIComponent(product.name)}`}>
						{product.name}
					</a>
					<div>
						{product.price && (
							<div className="price-container">
								<div className="price">
									<span className="bookmark-price">
										{currencyConverter(product.price)}
									</span>
								</div>
								{product.unitPrice && (
									<div className="unit-price">
										<small>({currencyConverter(product.unitPrice.pricePerUnit)} / {product.unitPrice.measuringUnit === "sqm" ? "m²" : product.unitPrice.measuringUnit === "cbm" ? "m³"
											: product.unitPrice.measuringUnit})
										</small>
									</div>
								)}
								<small>
									<span className="hpm-productItemPriceNotice">
										{product.price && " " + props.t("productDetail.inclVAT")}
									</span>
								</small>
							</div>
						)}
					</div>
					<div className={"hpm-sideBarArticleNumber"}>
						<span className={"hpm-ArticleNumberLabel"}>
							{props.t("productDetail.articleNumber") + ": "}
						</span>
						<span className={"hpm-ArticleNumberValue"}>
							{product.articleNumber}
						</span>
					</div>
				</div>
			</div>
			<div className="hpm-itemButtonbox hpm-noPrint">
				<button type={"button"} onClick={() => props.removeProduct(product)} className="hpm-delete"/>
			</div>
		</div>
	);
};

export default withTranslation()(WishlistItem);
