// @flow

import type {Price} from "./Price";
import {SHIPPING_TYPES} from "./ShippingTypes";

export const DISTRIBUTION = {
	MOUNTING: SHIPPING_TYPES.MOUNT,
	SHIPPING: SHIPPING_TYPES.SHIPPING,
	PICKUP: SHIPPING_TYPES.PICKUP,
	NONE: null
};

export type DistributionChannel = {
	price: Price,
	recommendedRetailPrice: ?Price,
	minSupplyDays: Number,
	maxSupplyDays: Number,
	discountRate: Number,
	shippingCost: ?Price
}

export type DistributionChannels = {
	pickup: ?DistributionChannel,
	shipping: ?DistributionChannel,
	mounting: ?DistributionChannel,
}
