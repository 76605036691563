import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import Menu from "./FilterTypes/Menu";
import {currencyConverter} from "../../helper/currency";
import type PriceRange from "../../models/PriceRange";
import {FILTER} from "../../reducers/filterReducer";
import type {translate} from "../../i18next";
import type {Vehicle} from "../../models/Vehicle";
import MultiSelect from "./FilterTypes/MultiSelect";

export type StartFilterPageProps = {
	t: translate,
	showSort: boolean,
	showCarline: boolean,
	showPriceRange: boolean,
	setFilterInstance: number => void,
	filterCategories: any[],
	vehicleModels: Vehicle[],
	vehicleSeries: Vehicle[],
	setMarker: () => void,
	resetMarker: () => void,
	filter: {
		searchPhrase: string,
		categories: [],
		categoryKey: string,
		vehicle: string,
		vehicleKey: string,
		priceRange: PriceRange,
		sort: {
			fieldname: string,
			direction: string
		},
		vehicleModel: string,
		markers: any
	},
	mainOptions: [{
		type: string,
		name: string,
		target: number,
	}],
};

const SortFilterPage = (props: StartFilterPageProps) => {
	const [isOnSale, setIsOnSale] = useState(false);

	useEffect(() => {
		setIsOnSale(props.filter.markers[0] === "SALE");
	}, [props.filter.markers]);

	const createInfoText = (destiny: number) => {
		switch (destiny) {
			case FILTER.CATEGORIES:
				return props.filterCategories.length ? props.filterCategories[0].title : props.t("sort.all");
			case FILTER.SORT:
				return props.t("sort." + (props.filter.sort.fieldname + "-" + props.filter.sort.direction).toLowerCase());
			case FILTER.CARLINE:
				if (!props.vehicleModels) return props.t("sort.all");
				const vehicleSeries = (props.vehicleSeries || [])
					.find(vehicle => vehicle.key === props.filter.vehicle || vehicle.key === props.filter.vehicleKey);
				const vehicleModel = (props.vehicleModels || [])
					.find(vehicle => vehicle.key === props.filter.vehicleModel || vehicle.key === props.filter.vehicleKey);
				return vehicleSeries
					? vehicleSeries.title
					: (vehicleModel ? vehicleModel.title : props.t("sort.all"));
			case FILTER.PRICE_RANGE:
				return props.filter.priceRange
					? currencyConverter({
						value: props.filter.priceRange.from,
						currency: props.filter.priceRange.unit
					}, "noGrouping") + " - " +
					currencyConverter({
						value: props.filter.priceRange.to,
						currency: props.filter.priceRange.unit
					}, "noGrouping")
					: props.t("sort.all");
			default:
				return "";
		}
	};
	const handleMarkerSelection = () => {
		isOnSale ? props.resetMarker() : props.setMarker(["SALE"]);
		setIsOnSale(!isOnSale);
	};

	return <React.Fragment>
		<MultiSelect label={props.t("sort.sale")} value={isOnSale}
					 setFilter={() => handleMarkerSelection(isOnSale)} selected={isOnSale}
					 key={uuidv4()}
		/>
		{props.mainOptions.map(menuEntry => {
			if ((props.showSort || menuEntry.target !== FILTER.SORT) &&
			(props.showCarline || menuEntry.target !== FILTER.CARLINE) &&
			(props.showPriceRange || menuEntry.target !== FILTER.PRICE_RANGE)) {
				return <Menu infoText={createInfoText(menuEntry.target)}
						 label={props.t("sort." + menuEntry.name)}
						 setMenu={() => props.setFilterInstance(menuEntry.target, {})}
						 key={uuidv4()}/>;
			}
			return null;
		})}
	</React.Fragment>;
};

export default SortFilterPage;
