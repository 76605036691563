import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import PayPalExpressButton from "../../../components/checkout/dataSummaryBox/PayPalExpressButton";
import {createExpressOrder, onPayPalExpressSuccess, setPaymentError} from "../../../actions/order";
import type {PayPalPaymentResponse} from "../../../models/PayPalPaymentResponse";
import type {OrderItemsResponse} from "../../../models/Order";
import { convertToLocale } from "../../../helper/languageUtils";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	const paymentOptions = {
		intent: "authorize",
		commit: false,
	};

	const PAYPAL_ADDRESS = {
		OPTIONAL: 0,
		IGNORED: 1,
		REQUIRED: 2
	};

	return {
		paypalAdress: PAYPAL_ADDRESS.REQUIRED,
		paymentOptions,
		clientId: state.cart.paypalClientId,
		payee: state.cart.paymentFacility.account ? state.cart.paymentFacility.account.payee : null,
		total: {
			value: state.order.totalBillingAmount ? state.order.totalBillingAmount.cost : null,
			currency: state.order.totalBillingAmount ? state.order.totalBillingAmount.currency.code : null,
		},
		orderNo: state.order.order ? state.order.order.orderNo : "unknown",
		dealerNo: state.order.order ? state.order.order.dealerId : "unknown",
		moduleLanguage: convertToLocale(state.config.moduleLanguage),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSuccess: (response: PayPalPaymentResponse) => {
			dispatch(onPayPalExpressSuccess(response));
		},
		setPaymentError: () => {
			dispatch(setPaymentError());
		},
		createExpressOrder: (order: OrderItemsResponse): void => {
			dispatch(createExpressOrder(order));
		},
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayPalExpressButton));
