// @flow
import React from "react";
import type {Product} from "../../../models/Product";
import "./NscProductView.scss";

export type NscProductViewProps = {
	product: Product,
	close: () => void,
}

const NscProductView = (props: NscProductViewProps) => {
	return <div className="selectedProduct">
		<img src={props.product.galleryImage.attachmentUrl} alt={""}/>
		<span className={"hpm-productDescription"}>
			<span>{props.product.name}</span>
			<div className={"hpm-close-dealerList"} onClick={() => props.close()}/>
		</span>
	</div>;
};

export default NscProductView;
