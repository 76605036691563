// @flow
import React from "react";
import type {PriceRange} from "../../models/PriceRange";
import {FILTER} from "../../reducers/filterReducer";
import MultiSelect from "./FilterTypes/MultiSelect";
import type {translate} from "../../i18next";
import RangeContainer from "../../container/Filter/FilterTypes/RangeContainer";

export type FilterPagePriceRangeProps = {
	t: translate,
	filter: { priceRange: PriceRange },
	priceBorders: PriceRange,
	setPriceRange: PriceRange | null => void,
	getPriceFilterStats: (any) => void,
	setFilterInstance: (number) => void,
	showPrices: boolean,
};

class FilterPagePriceRange extends React.Component <FilterPagePriceRangeProps> {
	constructor(props) {
		super(props);
		this.props.getPriceFilterStats(this.props.filter);
	}

	render() {
		return <React.Fragment>
			<RangeContainer
				border={this.props.priceBorders} defaultValue={this.props.filter.priceRange || this.props.priceBorders}
				setFilterInstance={() => this.props.setFilterInstance(FILTER.MAIN)}
				setFilter={this.props.setPriceRange}
			/>
			<MultiSelect infoText="" label={this.props.t("sort.all")}
						 selected={!this.props.filter.priceRange}
						 setFilter={() => this.props.setPriceRange(null)}
			/>
		</React.Fragment>;
	}
}

export default FilterPagePriceRange;
