// @flow
import React from "react";
import "./PickupLocation.scss";
import type {GroupMember} from "../../models/GroupMember";
import type {translate} from "../../i18next";

type PickupLocationInfoProps = {
	location: GroupMember,
	t: translate,
	moduleLanguage: string,
	setLocation: GroupMember => void,
};

export const PickupLocationInfo = (props: PickupLocationInfoProps) => {
	const countryDisplay = new Intl.DisplayNames([props.moduleLanguage], {type: "region"});

	return props.location
		? <React.Fragment>
			<div>{props.location.name}</div>
			<div>{props.location.address.street} {props.location.address.streetNumber}</div>
			<div>{props.location.address.postalCode} {props.location.address.town}</div>
			<div>{countryDisplay.of(props.location.address.countryCode)}</div>
		</React.Fragment>
		: null;
};
