import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../../../reducers";
import P24PayPalButton from "../../../components/checkout/dataSummaryBox/P24PayPalButton";
import {setPaymentError} from "../../../actions/order";
import { convertToLocale } from "../../../helper/languageUtils";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	return {
		clientId: state.cart.paypalClientId,
		payee: state.cart.paymentFacility ? state.cart.paymentFacility.account.payee : null,
		currencyCode: state.order.totalBillingAmount.currency.code,
		totalBillingAmount: state.order.totalBillingAmount.cost,
		orderNo: state.order.order
			? state.order.order.orderNo
			: "unknown",
		dealerNo: state.order.order
			? state.order.order.dealerId
			: "unknown",
		buyerAdress: state.order.adress,
		buyerName: `${state.order.adress.name} ${state.order.adress.familyname}`,
		buyerEmail: state.order.adress.email,
		moduleLanguage: convertToLocale(state.config.moduleLanguage),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setPaymentError: () => {
			dispatch(setPaymentError());
		}
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(P24PayPalButton));
