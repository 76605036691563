import {connect} from "react-redux";
import {checkCart, removeFromCart, setQuantity} from "../../../actions/cart";
import CartItem from "../../../components/checkout/mainContentBox/CartItem";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";

const mapStateToProps = (state, ownProps) => {
	return {
		t: ownProps.t
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setQuantity: (id, amount) => {
			dispatch(setQuantity(id, amount));
			dispatch(checkCart());
		},
		removeFromCart: product => {
			dispatch(removeFromCart(product));
			dispatch(checkCart());
		}
	};
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(CartItem)));
