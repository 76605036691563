import React from "react";
import MultiSelect from "./FilterTypes/MultiSelect";
import { v4 as uuidv4 } from "uuid";
import Menu from "./FilterTypes/Menu";
import {FILTER} from "../../reducers/filterReducer";
import type {translate} from "../../i18next";

export type CarlineFilterPageProps = {
	t: translate,
	filter: {},
	vehicleSeries: [],
	setVehicle: (string, string) => void,
	getCarlineFilterStats: {} => void,
	setFilterInstance: (number, {}) => void,
	isVehicleSelected: string => boolean,
	isFiltered: boolean,
};

class FilterPageCarline extends React.Component<CarlineFilterPageProps> {
	constructor(props) {
		super(props);
		this.props.getCarlineFilterStats(this.props.filter);
	}

	render() {
		return <React.Fragment>
			<MultiSelect label={this.props.t("sort.all")} value={null}
						 setFilter={this.props.setVehicle} selected={!this.props.isFiltered}
						 key={uuidv4()}
			/>
			{this.props.vehicleSeries && this.props.vehicleSeries.map(vehicle => {
				const label = vehicle.categories.length > 0 ? vehicle.categories[0].title : vehicle.title;
				const value = vehicle.categories.length > 0 ? vehicle.categories[0].key : vehicle.key;
				return vehicle.shouldRenderMenu
					? <Menu infoText={vehicle.count} label={vehicle.title}
						setMenu={() => this.props.setFilterInstance(FILTER.VEHICLE_MODEL, vehicle)}
						key={uuidv4()}/>
					: <MultiSelect infoText={vehicle.count} label={label}
						   value={value} key={uuidv4()}
						   setFilter={this.props.setVehicle} selected={this.props.isVehicleSelected(value)}
					/>;
			})}
		</React.Fragment>;
	}
}

export default FilterPageCarline;
