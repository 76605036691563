import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import FilterModal from "../../components/Filter/FilterModal";
import {changeModal} from "../../actions/modal";
import {resetFilter, setFilterInstance} from "../../actions/filter";
import {getPopularProducts, getProducts} from "../../actions/products";
import {FILTER} from "../../reducers/filterReducer";
import type {ApplicationState} from "../../reducers";
import history from "../../history";
import {setCategory} from "../../actions/category";
import {productsReset} from "../../reducers/contentReducer";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		activeModal: state.filter.activeModal,
		filterInstance: state.filter.filterInstance,
		topFilterInstance: FILTER.MAIN,
		category: (state.filter.filter.categories || []).length > 0
			? state.filter.filter.categories[0]
			: state.filter.filter.categoryKey,
		isDirty: state.filter.isDirty
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		changeModal: id => dispatch(changeModal(id)),
		setFilterInstance: instance => dispatch(setFilterInstance(instance)),
		resetFilter: () => {
			dispatch(resetFilter());
			dispatch(setFilterInstance(FILTER.MAIN));
			dispatch(productsReset());
			dispatch(getPopularProducts());
			dispatch(setCategory());
			history.pushHome();
		},
		getProducts: categoryName => {
			dispatch(productsReset());
			dispatch(getProducts());
			dispatch(setCategory(categoryName));
		}
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterModal)));
