import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import "./MultiSelect.scss";

const MultiSelect = props => {
	const uniqueIdentifier = uuidv4();
	return (
		<div onClick={() => props.setFilter(props.value)} id="hpmt-multiselect">
			<label className={`hpm-filterItem ${props.useLargeLabel ? "useLargeLabel" : ""}`}
				   htmlFor={uniqueIdentifier}
				   onClick={e => e.stopPropagation()}
			>
				<span className="hpm-selectText" id="hpmt-selectLabel">
					{props.label}
				</span>
				<span className="hpm-infoText">
					{props.infoText}
				</span>
				<span className={props.selected ? "hpm-checkedSelect hpm-selectDot" : "hpm-selectDot"}
					  id="hpmt-selectDot">
					<span/>
				</span>
			</label>
			<input type="checkbox" id={uniqueIdentifier}/>
		</div>
	);
};

MultiSelect.propTypes = {
	label: PropTypes.string.isRequired,
	useLargeLabel: PropTypes.bool,
	infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	selected: PropTypes.bool,
	setFilter: PropTypes.func.isRequired
};

export default MultiSelect;
