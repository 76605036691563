import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import type { ApplicationState } from "../../../reducers";
import { resetCoupon, resetCouponToast, setSpsCoupon } from "../../../actions/coupon";
import SpsCouponCode from "../../../components/checkout/dataSummaryBox/SpsCouponCode";

const mapStateToProps = (state: ApplicationState, ownProps) => {
	return {
		coupon: state.coupon.coupon,
		couponErrorCode: state.coupon.couponErrorCode,
		couponSuccessCode: state.coupon.couponSuccessCode,
		spsCustomer: state.coupon.sps
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetCoupon: () => dispatch(resetCoupon()),
		setSpsCoupon: () => dispatch(setSpsCoupon()),
		resetCouponToast: () => dispatch(resetCouponToast())
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SpsCouponCode));
